// Layer.js
import { Grid } from './Grid.js';

export class Layer {
    constructor(id, name, gridSize, cellSize, complexity, app) {
        this.id = id;
        this.name = name;
        this.app = app;
        this.complexity = complexity || 1; // Ensure a default value
        this.grid = new Grid(app.gridContainer, gridSize, cellSize, this.complexity, this);
        this.visible = true;
    }

    create() {
        this.grid.create();
    }

    handleCellClick(point) {
        if (this.app.isShiftPressed || this.app.isErasing) {
            this.eraseShape(point);
        } else {
            const existingShape = this.grid.shapes.find(shape => 
                shape.point.x === point.x && shape.point.y === point.y);
            if (existingShape) {
                this.grid.rotateShape(existingShape);
            } else {
                this.placeShape(point, this.app.selectedShape);
            }
        }
    }

    placeShape(point, shapeName) {
        this.grid.placeShape(point, shapeName);
    }

    eraseShape(point) {
        const shapeToErase = this.grid.shapes.find(shape => 
            shape.point.x === point.x && shape.point.y === point.y);
        if (shapeToErase) {
            this.grid.eraseShape(shapeToErase);
        }
    }

    toggleVisibility() {
        this.visible = !this.visible;
        this.grid.element.style.display = this.visible ? 'block' : 'none';
    }

    setComplexity(newComplexity) {
        if (this.complexity !== newComplexity) {
            this.complexity = newComplexity;
            this.grid.setComplexity(newComplexity);
        }
    }

    isLayerEmpty() {
        return this.grid.shapes.length === 0;
    }

    clearAllShapes() {
        this.grid.clearAllShapes();
    }

    updatePosition(offsetX, offsetY) {
        this.grid.offsetX = offsetX;
        this.grid.offsetY = offsetY;
        if (this.grid.two && this.grid.two.scene) {
            this.grid.two.scene.translation.set(offsetX * this.grid.cellSize, offsetY * this.grid.cellSize);
        }
    }

    deselectAllShapes() {
        //this.grid.deselectAllShapes();
    }

    cleanup() {
        // Stop all animations
        if (this.grid.animator) {
            this.grid.animator.stopAllAnimations();
        }

        // Remove all shapes
        this.grid.clearAllShapes();

        // Remove the canvas element from the DOM
        if (this.grid.two && this.grid.two.renderer.domElement) {
            this.grid.two.renderer.domElement.remove();
        }

        // Destroy the Two.js instance
        if (this.grid.two) {
            this.grid.two.unbind('update');
            this.grid.two.pause();
        }
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            complexity: this.complexity,
            order: this.order,
            shapes: this.grid.shapes.map(shape => {
                const shapeData = {
                    name: shape.name,
                    rotation: shape.rotation,
                    point: shape.translation ? { x: shape.translation.x, y: shape.translation.y } : null,
                    scale: shape.scale ? { x: shape.scale.x, y: shape.scale.y } : null,
                    originalDimensions: shape.originalDimensions,
                    placedAtComplexity: shape.placedAtComplexity
                };

                // Only include size if container exists and has style
                if (shape.container && shape.container.style) {
                    shapeData.size = {
                        width: shape.container.style.width,
                        height: shape.container.style.height
                    };
                }

                // Include animation data if it exists
                if (shape.animation && shape.animation.code) {
                    shapeData.animation = {
                        code: shape.animation.code
                    };
                }

                return shapeData;
            }),
            visible: this.visible,
            offsetX: this.grid.offsetX,
            offsetY: this.grid.offsetY,
        };
    }

    static fromJSON(data, gridSize, cellSize, app) {
        const complexity = data.complexity !== undefined ? data.complexity : app.globalComplexity;
        const layer = new Layer(data.id, data.name, gridSize, cellSize, complexity, app);

        layer.create();
        layer.visible = data.visible;
        layer.order = data.order;
        layer.setComplexity(complexity);
        
        if (data.offsetX !== undefined && data.offsetY !== undefined) {
            layer.updatePosition(data.offsetX, data.offsetY);
        }

        data.shapes.forEach((shapeData) => {
            const x = shapeData.point ? shapeData.point.x : 0;
            const y = shapeData.point ? shapeData.point.y : 0;
            const shape = layer.grid.placeShape(x, y, shapeData.name);

            if (shape) {
                if (shapeData.scale) {
                    shape.scale.set(shapeData.scale.x, shapeData.scale.y);
                }
                
                if (shapeData.size) {
                    shape.width = parseFloat(shapeData.size.width);
                    shape.height = parseFloat(shapeData.size.height);
                }
                
                shape.originalDimensions = shapeData.originalDimensions;
                shape.placedAtComplexity = shapeData.placedAtComplexity;
                
                if (shapeData.rotation !== undefined) {
                    shape.rotation = shapeData.rotation;
                }

                if (shapeData.animation && shapeData.animation.code) {
                    layer.grid.applyAnimation(shape, shapeData.animation.code);
                }
            }
        });
        
        // Set the visibility of the canvas
        if (layer.grid.two && layer.grid.two.renderer.domElement) {
            layer.grid.two.renderer.domElement.style.display = layer.visible ? 'block' : 'none';
        }

        layer.grid.two.update();

        return layer;
    }
}