export const animationPresets = {
  rotate: `
gsap.timeline({repeat: 0, yoyo: true})
  .to(shape, {
    rotation: 360,
    duration: 1,
    ease: "power2.inOut"
});
    `, 
  flipX: `
gsap.timeline({repeat: 0, yoyo: true})
  .to(shape, {
    scaleX: -1,
    duration: 0.5,
    ease: "power2.inOut"
  })
    `,
  flipY: `
gsap.timeline({repeat: 0, yoyo: true})
  .to(shape, {
    scaleY: -1,
    duration: 0.5,
    ease: "power2.inOut"
  });
    `,
  fadeInOut: `
gsap.to(shape, {
  opacity: 0,
  duration: 1,
  repeat: 0,
  yoyo: true,
  ease: "power1.inOut"
});
    `,
  scaleDownUp: `
gsap.timeline({repeat: 0})
  .to(shape, {
    scale: 0,
    duration: 0.5,
    ease: "power2.out"
  })
  .to(shape, {
    scale: 1,
    duration: 0.5,
    ease: "power2.out"
  });
    `,
  jumpX: `
let flipped = false;
const flip = () => {
  gsap.set(shape, { scaleX: flipped ? 1 : -1 });
  flipped = !flipped;
};
const interval = setInterval(flip, 500); // Flip every 0.5 seconds
flip(); // Initial flip
return {
  kill: () => clearInterval(interval)
};
  `,
  jumpY: `
let flipped = false;
const flip = () => {
  gsap.set(shape, { scaleY: flipped ? 1 : -1 });
  flipped = !flipped;
};
const interval = setInterval(flip, 500); // Flip every 0.5 seconds
flip(); // Initial flip
return {
  kill: () => clearInterval(interval)
};
    `
};