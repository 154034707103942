// PatternGenerator.js
import { animationPresets } from './AnimationPresets.js';

export class PatternGenerator {
  constructor(grid, complexity) {
    this.grid = grid;
    this.complexity = this.grid.complexity;
  }

 
  generatePattern(algorithm, params, getShapeFunc) {
    this.grid.clearAllShapes();
    switch (algorithm) {
      case 'randomFill':
        return this.randomFill(params, getShapeFunc);
      case 'cellularAutomata':
        return this.cellularAutomata(params, getShapeFunc);
      case 'recursiveDivision':
        return this.recursiveDivision(params, getShapeFunc);
      case 'concentricShapes':
        return this.concentricShapes(params, getShapeFunc);
      case 'diagonalStripes':
        return this.diagonalStripes(params, getShapeFunc);
      case 'checkerboard':
        return this.checkerboard(params, getShapeFunc);
      case 'spiral':
        return this.spiral(params, getShapeFunc);
      case 'radialBurst':
        return this.radialBurst(params, getShapeFunc);
      case 'wave':
        return this.wave(params, getShapeFunc);
      case 'fractalTree':
        return this.fractalTree(params, getShapeFunc);
    }
  }

  getSnapPoint(x, y) {
    const step = this.grid.cellSize / this.grid.complexity;
    return this.grid.findNearestSnapPoint(x * step, y * step);
  }

  randomFill(params, getShapeFunc) {
    console.log(this.grid)
    const { fillProbability = 0.5 } = params;
    const totalPoints = (this.grid.size * this.grid.complexity);
    for (let x = 0; x < totalPoints; x++) {
      for (let y = 0; y < totalPoints; y++) {
        if (Math.random() < fillProbability) {
          const point = this.getSnapPoint(x, y);
          this.grid.placeShape(point, getShapeFunc());
        }
      }
    }
  }

  cellularAutomata(params, getShapeFunc) {
    const { iterations = 5, initialFillProbability = 0.4 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    
    let cells = Array(totalPoints).fill().map(() => Array(totalPoints).fill(false));
    for (let x = 0; x < totalPoints; x++) {
      for (let y = 0; y < totalPoints; y++) {
        cells[x][y] = Math.random() < initialFillProbability;
      }
    }

    for (let i = 0; i < iterations; i++) {
      cells = this.runCellularAutomataIteration(cells);
    }

    for (let x = 0; x < totalPoints; x++) {
      for (let y = 0; y < totalPoints; y++) {
        if (cells[x][y]) {
          const point = this.getSnapPoint(x, y);
          this.grid.placeShape(point, getShapeFunc());
        }
      }
    }
  }

  recursiveDivision(params, getShapeFunc) {
    const { minRoomSize = 3 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    this.recursiveDivisionHelper(0, 0, totalPoints, totalPoints, minRoomSize * this.grid.complexity, getShapeFunc);
  }

  runCellularAutomataIteration(cells) {
    const newCells = JSON.parse(JSON.stringify(cells));
    const totalPoints = cells.length;
    for (let x = 0; x < totalPoints; x++) {
      for (let y = 0; y < totalPoints; y++) {
        const neighbors = this.countNeighbors(cells, x, y);
        if (cells[x][y]) {
          newCells[x][y] = neighbors >= 2 && neighbors <= 3;
        } else {
          newCells[x][y] = neighbors === 3;
        }
      }
    }
    return newCells;
  }

  countNeighbors(cells, x, y) {
    let count = 0;
    const totalPoints = cells.length;
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        if (i === 0 && j === 0) continue;
        const nx = (x + i + totalPoints) % totalPoints;
        const ny = (y + j + totalPoints) % totalPoints;
        count += cells[nx][ny] ? 1 : 0;
      }
    }
    return count;
  }

  recursiveDivisionHelper(x, y, width, height, minRoomSize, shape) {
    if (width <= minRoomSize || height <= minRoomSize) {
      return;
    }

    let horizontal = Math.random() > 0.5;
    if (width > height && width / height >= 1.25) {
      horizontal = false;
    } else if (height > width && height / width >= 1.25) {
      horizontal = true;
    }

    const wx = x + (horizontal ? 0 : Math.floor(Math.random() * (width - 2)) + 1);
    const wy = y + (horizontal ? Math.floor(Math.random() * (height - 2)) + 1 : 0);

    this.createWall(wx, wy, horizontal, width, height, shape);

    const [nx, ny, nw, nh] = horizontal 
      ? [x, y, width, wy - y]
      : [x, y, wx - x, height];
    this.recursiveDivisionHelper(nx, ny, nw, nh, minRoomSize, shape);

    const [nx2, ny2, nw2, nh2] = horizontal
      ? [x, wy + 1, width, y + height - wy - 1]
      : [wx + 1, y, x + width - wx - 1, height];
    this.recursiveDivisionHelper(nx2, ny2, nw2, nh2, minRoomSize, shape);
  }

  createWall(x, y, horizontal, width, height, getShapeFunc) {
    if (horizontal) {
      for (let i = 0; i < width; i++) {
        if (Math.random() < 0.8) {
          const point = this.getSnapPoint(x + i, y);
          this.grid.placeShape(point, getShapeFunc());
        }
      }
    } else {
      for (let i = 0; i < height; i++) {
        if (Math.random() < 0.8) {
          const point = this.getSnapPoint(x, y + i);
          this.grid.placeShape(point, getShapeFunc());
        }
      }
    }
  }

  concentricShapes(params, getShapeFunc) {
    const { rings = 5 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    const center = Math.floor(totalPoints / 2);
    
    for (let ring = 1; ring <= rings; ring++) {
      const radius = ring * (totalPoints / (2 * rings));
      for (let x = 0; x < totalPoints; x++) {
        for (let y = 0; y < totalPoints; y++) {
          const distance = Math.sqrt((x - center) ** 2 + (y - center) ** 2);
          if (Math.abs(distance - radius) < 1) {
            if (this.isWithinBounds(x, y)) {
              const point = this.getSnapPoint(x, y);
              this.grid.placeShape(point, getShapeFunc());
            }
          }
        }
      }
    }
  }

  diagonalStripes(params, getShapeFunc) {
    const { stripeWidth = 2, spacing = 4 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    
    for (let x = 0; x < totalPoints; x++) {
      for (let y = 0; y < totalPoints; y++) {
        if ((x + y) % (stripeWidth + spacing) < stripeWidth) {
          if (this.isWithinBounds(x, y)) {
            const point = this.getSnapPoint(x, y);
            this.grid.placeShape(point, getShapeFunc());
          }
        }
      }
    }
  }

  checkerboard(params, getShapeFunc) {
    const { cellSize = 2 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    
    for (let x = 0; x < totalPoints; x += cellSize) {
      for (let y = 0; y < totalPoints; y += cellSize) {
        if ((Math.floor(x / cellSize) + Math.floor(y / cellSize)) % 2 === 0) {
          for (let i = 0; i < cellSize; i++) {
            for (let j = 0; j < cellSize; j++) {
              if (this.isWithinBounds(x + i, y + j)) {
                const point = this.getSnapPoint(x + i, y + j);
                this.grid.placeShape(point, getShapeFunc());
              }
            }
          }
        }
      }
    }
  }

  spiral(params, getShapeFunc) {
    const { turns = 5 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    const center = Math.floor(totalPoints / 2);
    
    const a = 0.1; // controls how tightly the spiral is wound
    const b = 1; // controls the space between spiral arms

    for (let theta = 0; theta < turns * 2 * Math.PI; theta += 0.1) {
      const r = a * theta;
      const x = Math.round(center + r * Math.cos(theta) * b);
      const y = Math.round(center + r * Math.sin(theta) * b);
      
      if (x >= 0 && x < totalPoints && y >= 0 && y < totalPoints) {
        const point = this.getSnapPoint(x, y);
        this.grid.placeShape(point, getShapeFunc());
      }
    }
  }

  radialBurst(params, getShapeFunc) {
    const { rays = 12 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    const center = Math.floor(totalPoints / 2);
    
    for (let ray = 0; ray < rays; ray++) {
      const angle = (ray / rays) * 2 * Math.PI;
      for (let r = 0; r < totalPoints / 2; r++) {
        const x = center + Math.cos(angle) * r;
        const y = center + Math.sin(angle) * r;
        if (x >= 0 && x < totalPoints && y >= 0 && y < totalPoints) {
          if (this.isWithinBounds(Math.round(x), Math.round(y))) {
            const point = this.getSnapPoint(Math.round(x), Math.round(y));
            this.grid.placeShape(point, getShapeFunc());
          }
        }
      }
    }
  }

  wave(params, getShapeFunc) {
    const { amplitude = 10, frequency = 0.1 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    
    for (let x = 0; x < totalPoints; x++) {
      const y = Math.sin(x * frequency) * amplitude + totalPoints / 2;
      if (this.isWithinBounds(x, Math.round(y))) {
        const point = this.getSnapPoint(x, Math.round(y-1));
        this.grid.placeShape(point, getShapeFunc());
      }
    }
  }

  fractalTree(params, getShapeFunc) {
    const { depth = 7, angle = Math.PI / 4 } = params;
    const totalPoints = this.grid.size * this.grid.complexity;
    const startX = Math.floor(totalPoints / 2);
    const startY = totalPoints - 1;
    
    const drawBranch = (x, y, len, angle, depth) => {
      if (depth === 0) return;
      
      const endX = x + Math.sin(angle) * len;
      const endY = y - Math.cos(angle) * len;
      
      const steps = Math.max(Math.abs(endX - x), Math.abs(endY - y));
      for (let i = 0; i <= steps; i++) {
        const px = Math.round(x + (endX - x) * (i / steps));
        const py = Math.round(y + (endY - y) * (i / steps));
        if (px >= 0 && px < totalPoints && py >= 0 && py < totalPoints) {
          if (this.isWithinBounds(px, py)) {
            const point = this.getSnapPoint(px, py);
            this.grid.placeShape(point, getShapeFunc());
          }
        }
      }
      
      drawBranch(endX, endY, len * 0.7, angle - this.grid.complexity * 0.1, depth - 1);
      drawBranch(endX, endY, len * 0.7, angle + this.grid.complexity * 0.1, depth - 1);
    };
    
    drawBranch(startX, startY, totalPoints / 4, -Math.PI / 2, depth);
  }

  isWithinBounds(x, y) {
    const totalPoints = this.grid.size * this.grid.complexity;
    return x >= 0 && x < totalPoints && y >= 0 && y < totalPoints;
  }
}

// ---------

export class PatternManipulator {
  constructor(grid) {
    this.grid = grid;
  }

  manipulatePattern(algorithm, params) {
    switch (algorithm) {
      case 'rotateAllRowByRow':
        return this.rotateAllRowByRow(params);
      case 'rotateAllColumnByColumn':
        return this.rotateAllColumnByColumn(params);
      case 'rotateShuffle':
        return this.rotateShuffle(params);
      case 'rotateDiagonally':
        return this.rotateDiagonally(params);
      case 'rotateSequenceFromCorner':
        return this.rotateSequenceFromCorner(params);
      case 'rotateSequenceToCorner':
        return this.rotateSequenceToCorner(params);
      case 'rotateSequenceDiagonally':
        return this.rotateSequenceDiagonally(params);
      case 'rotateSequenceHorizontal':
        return this.rotateSequenceHorizontal(params);
      case 'rotateSequenceVertical':
        return this.rotateSequenceVertical(params);
      case 'rotateSequenceSpiralInward':
        return this.rotateSequenceSpiralInward(params);
      case 'rotateSequenceSpiralOutward':
        return this.rotateSequenceSpiralOutward(params);
      case 'rotateAll':
        return this.rotateAll(params);
      case 'swirl':
        return this.swirl(params);
      case 'wave':
        return this.wave(params);
      case 'explode':
        return this.explode(params);
      case 'implode':
        return this.implode(params);
      case 'shuffle':
        return this.shuffle(params);
      case 'mirror':
        return this.mirror(params);
    }
  }

  rotateAll(params) {
    const { angle = 90, step = 1 } = params;
    this.grid.shapes.forEach(shape => {
      const newAngle = Math.round(angle / step) * step;
      this.grid.rotateShape(shape, newAngle);
    });
  }

  rotateAllRowByRow(params) {
    const { angle = 90, step = 1 } = params;
    const rows = this.groupShapesByRow();
    rows.forEach((row, index) => {
      if (index % 2 === 0) {
        row.forEach(shape => {
          const newAngle = Math.round(angle / step) * step;
          this.grid.rotateShape(shape, newAngle);
        });
      }
    });
  }

  rotateAllColumnByColumn(params) {
    const { angle = 90, step = 1 } = params;
    const columns = this.groupShapesByColumn();
    columns.forEach((column, index) => {
      if (index % 2 === 0) {
        column.forEach(shape => {
          const newAngle = Math.round(angle / step) * step;
          this.grid.rotateShape(shape, newAngle);
        });
      }
    });
  }

  rotateShuffle(params) {
    const { minAngle = 0, maxAngle = 360, step = 1 } = params;
    this.grid.shapes.forEach(shape => {
      const randomAngle = Math.round((Math.random() * (maxAngle - minAngle) + minAngle) / step) * step;
      this.grid.rotateShape(shape, randomAngle);
    });
  }

  rotateDiagonally(params) {
    const { angle = 45, step = 1 } = params;
    const diagonals = this.groupShapesByDiagonal();
    diagonals.forEach((diagonal, index) => {
      if (index % 2 === 0) {
        diagonal.forEach(shape => {
          const newAngle = Math.round(angle / step) * step;
          this.grid.rotateShape(shape, newAngle);
        });
      }
    });
  }

  rotateSequenceFromCorner(params) {
    const { startAngle = 0, endAngle = 360, step = 1, angleStep = 10 } = params;
    const sortedShapes = this.sortShapesFromCorner();
    this.rotateShapesInSequence(sortedShapes, startAngle, endAngle, step, angleStep);
  }

  rotateSequenceToCorner(params) {
    const { startAngle = 0, endAngle = 360, step = 1, angleStep = 10 } = params;
    const sortedShapes = this.sortShapesFromCorner().reverse();
    this.rotateShapesInSequence(sortedShapes, startAngle, endAngle, step, angleStep);
  }

  rotateSequenceDiagonally(params) {
    const { startAngle = 0, endAngle = 360, step = 1, angleStep = 10 } = params;
    const sortedShapes = this.grid.shapes.sort((a, b) => {
      if (a.point.y === b.point.y) {
        return a.point.x - b.point.x;
      }
      return a.point.y - b.point.y;
    });
    this.rotateShapesInSequence(sortedShapes, startAngle, endAngle, step, angleStep);
  }

  rotateSequenceHorizontal(params) {
    const { startAngle = 0, endAngle = 360, step = 1 } = params;
    const rows = this.groupShapesByRow();
    rows.forEach((row, rowIndex) => {
      const rowAngle = startAngle + (endAngle - startAngle) * (rowIndex / (rows.length - 1));
      const newAngle = Math.round(rowAngle / step) * step;
      row.forEach(shape => this.grid.rotateShape(shape, newAngle));
    });
  }

  rotateSequenceVertical(params) {
    const { startAngle = 0, endAngle = 360, step = 1 } = params;
    const columns = this.groupShapesByColumn();
    columns.forEach((column, columnIndex) => {
      const columnAngle = startAngle + (endAngle - startAngle) * (columnIndex / (columns.length - 1));
      const newAngle = Math.round(columnAngle / step) * step;
      column.forEach(shape => this.grid.rotateShape(shape, newAngle));
    });
  }

  rotateSequenceSpiralInward(params) {
    const { startAngle = 0, endAngle = 360, step = 1 } = params;
    const spiral = this.createSpiral();
    spiral.forEach((ring, index) => {
      const progress = 1 - (index / spiral.length);
      const angle = startAngle + (endAngle - startAngle) * progress;
      ring.forEach(shape => {
        this.grid.rotateShape(shape, Math.round(angle / step) * step);
      });
    });
  }

  rotateSequenceSpiralOutward(params) {
    const { startAngle = 0, endAngle = 360, step = 1 } = params;
    const spiral = this.createSpiral();
    spiral.forEach((ring, index) => {
      const progress = index / (spiral.length - 1);
      const angle = startAngle + (endAngle - startAngle) * progress;
      ring.forEach(shape => {
        this.grid.rotateShape(shape, Math.round(angle / step) * step);
      });
    });
  }

  swirl(params) {
    const { intensity = 0.1 } = params;
    const centerX = this.grid.size / 2;
    const centerY = this.grid.size / 2;
    const newPositions = [];

    this.grid.shapes.forEach(shape => {
      const dx = shape.point.x - centerX;
      const dy = shape.point.y - centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx) + intensity * distance;
      const newX = centerX + distance * Math.cos(angle);
      const newY = centerY + distance * Math.sin(angle);
      newPositions.push({ shape, newPoint: { x: newX, y: newY } });
    });

    this.updateShapePositions(newPositions);
  }

  wave(params) {
    const { amplitude = 20, frequency = 0.1 } = params;
    const newPositions = [];

    this.grid.shapes.forEach(shape => {
      const newY = shape.point.y + Math.sin(shape.point.x * frequency) * amplitude;
      newPositions.push({ shape, newPoint: { x: shape.point.x, y: newY } });
    });

    this.updateShapePositions(newPositions);
  }

  explode(params) {
    const { intensity = 2 } = params;
    const centerX = this.grid.size / 2;
    const centerY = this.grid.size / 2;
    const newPositions = [];

    this.grid.shapes.forEach(shape => {
      const dx = shape.point.x - centerX;
      const dy = shape.point.y - centerY;
      const newX = shape.point.x + dx * intensity;
      const newY = shape.point.y + dy * intensity;
      newPositions.push({ shape, newPoint: { x: newX, y: newY } });
    });

    this.updateShapePositions(newPositions);
  }

  implode(params) {
    const { intensity = 0.5 } = params;
    const centerX = this.grid.size / 2;
    const centerY = this.grid.size / 2;
    const newPositions = [];

    this.grid.shapes.forEach(shape => {
      const dx = centerX - shape.point.x;
      const dy = centerY - shape.point.y;
      const newX = shape.point.x + dx * intensity;
      const newY = shape.point.y + dy * intensity;
      newPositions.push({ shape, newPoint: { x: newX, y: newY } });
    });

    this.updateShapePositions(newPositions);
  }

  shuffle(params) {
    const { iterations = 1 } = params;
    const newPositions = [];

    for (let i = 0; i < iterations; i++) {
      const positions = this.grid.shapes.map(shape => ({ x: shape.point.x, y: shape.point.y }));
      positions.sort(() => Math.random() - 0.5);
      this.grid.shapes.forEach((shape, index) => {
        newPositions.push({ shape, newPoint: positions[index] });
      });
    }

    this.updateShapePositions(newPositions);
  }

  mirror(params) {
    const { axis = 'x' } = params;
    const gridSize = this.grid.size;
    const cellSize = this.grid.cellSize;
    const newPositions = [];

    this.grid.shapes.forEach(shape => {
        let newX = shape.point.x;
        let newY = shape.point.y;

        if (axis === 'x') {
            // Mirror horizontally
            newX = gridSize * cellSize - shape.point.x - cellSize;
        } else if (axis === 'y') {
            // Mirror vertically
            newY = gridSize * cellSize - shape.point.y - cellSize;
        }

        // Ensure the new position is within the grid boundaries
        newX = Math.max(0, Math.min(newX, (gridSize - 1) * cellSize));
        newY = Math.max(0, Math.min(newY, (gridSize - 1) * cellSize));

        newPositions.push({ shape, newPoint: { x: newX, y: newY } });
    });

    this.updateShapePositions(newPositions);
  }

  // Helper methods

  createSpiral() {
    const centerX = Math.floor(this.grid.size / 2);
    const centerY = Math.floor(this.grid.size / 2);
    const spiral = [];
    const visited = new Set();

    const directions = [[0, -1], [1, 0], [0, 1], [-1, 0]]; // Up, Right, Down, Left
    let x = centerX, y = centerY;
    let dx = 0, dy = -1;
    let steps = 0;
    let turnCounter = 0;

    while (spiral.length < this.grid.size * this.grid.size) {
      const ring = [];
      for (let i = 0; i < steps || (turnCounter < 2 && spiral.length === 0); i++) {
        const shape = this.grid.shapes.find(s => s.point.x === x && s.point.y === y);
        if (shape && !visited.has(shape)) {
          ring.push(shape);
          visited.add(shape);
        }
        x += dx;
        y += dy;
      }
      if (ring.length > 0) {
        spiral.push(ring);
      }
      [dx, dy] = directions[(directions.indexOf([dx, dy]) + 1) % 4];
      turnCounter++;
      if (turnCounter === 2) {
        steps++;
        turnCounter = 0;
      }
    }

    return spiral;
  }

  replaceShapes(newShapes) {
    this.grid.clearAllShapes();
    newShapes.forEach(shape => {
      if (this.isWithinBounds(shape.point.x, shape.point.y)) {
        this.grid.placeShape(shape.point, shape.name);
      }
    });
  }

  getShapesInRing(centerX, centerY, radius) {
    return this.grid.shapes.filter(shape => {
      const dx = Math.abs(shape.point.x - centerX);
      const dy = Math.abs(shape.point.y - centerY);
      return Math.max(dx, dy) === radius;
    });
  }

  groupShapesByRow() {
    const rows = {};
    this.grid.shapes.forEach(shape => {
      const rowIndex = Math.floor(shape.point.y / this.grid.cellSize);
      if (!rows[rowIndex]) rows[rowIndex] = [];
      rows[rowIndex].push(shape);
    });
    return Object.values(rows);
  }

  groupShapesByColumn() {
    const columns = {};
    this.grid.shapes.forEach(shape => {
      const columnIndex = Math.floor(shape.point.x / this.grid.cellSize);
      if (!columns[columnIndex]) columns[columnIndex] = [];
      columns[columnIndex].push(shape);
    });
    return Object.values(columns);
  }

  groupShapesByDiagonal() {
    const diagonals = {};
    this.grid.shapes.forEach(shape => {
      const diagonalIndex = shape.point.x - shape.point.y;
      if (!diagonals[diagonalIndex]) diagonals[diagonalIndex] = [];
      diagonals[diagonalIndex].push(shape);
    });
    return Object.values(diagonals);
  }

  sortShapesFromCorner() {
    return this.grid.shapes.sort((a, b) => {
      const distA = a.point.x + a.point.y;
      const distB = b.point.x + b.point.y;
      return distA - distB;
    });
  }

  sortShapesSpiral() {
    const centerX = this.grid.size / 2;
    const centerY = this.grid.size / 2;
    return this.grid.shapes.sort((a, b) => {
        const angleA = Math.atan2(a.point.y - centerY, a.point.x - centerX);
        const angleB = Math.atan2(b.point.y - centerY, b.point.x - centerX);
        const distA = Math.sqrt((a.point.x - centerX) ** 2 + (a.point.y - centerY) ** 2);
        const distB = Math.sqrt((b.point.x - centerX) ** 2 + (b.point.y - centerY) ** 2);
        
        // Calculate the number of full rotations
        const rotationsA = Math.floor(distA / (this.grid.cellSize * 2));
        const rotationsB = Math.floor(distB / (this.grid.cellSize * 2));

        if (rotationsA !== rotationsB) {
            return rotationsA - rotationsB;
        }

        return angleA - angleB;
    });
  }

  rotateShapesInSequence(shapes, startAngle, endAngle, step, angleStep) {
    shapes.forEach((shape, index) => {
      const progress = index / (shapes.length - 1);
      const baseAngle = startAngle + (endAngle - startAngle) * progress;
      const roundedAngle = Math.round(baseAngle / angleStep) * angleStep;
      const finalAngle = Math.round(roundedAngle / step) * step;
      this.grid.rotateShape(shape, finalAngle);
    });
  }

  updateShapePositions(newPositions) {
    // Remove all shapes from the grid
    this.grid.shapes.forEach(shape => {
      this.grid.eraseShape(shape);
    });

    // Add shapes back with new positions
    newPositions.forEach(({ shape, newPoint }) => {
      //if (this.isWithinBounds(newPoint.x, newPoint.y)) {
        this.grid.placeShape(newPoint, shape.name);
      //}
    });
  }

  isWithinBounds(x, y) {
    return x >= 0 && x < this.grid.size && y >= 0 && y < this.grid.size;
  }
}


// Animations

export class PatternAnimator {
    constructor(grid, layer) {
        this.grid = grid;
        this.layer = layer;
        this.currentAnimation = null;
    }

    resetAllAnimations() {
      // Kill all GSAP animations
      //gsap.killAll();
     
      //gsap.globalTimeline.pause();

      // Clear the current animation
      if (this.currentAnimation) {
          this.currentAnimation.kill();
          this.currentAnimation = null;
      }

      // Reset all shapes to their original state
      // this.grid.shapes.forEach(shape => {
      //     if (shape.elementWrapper) {
      //         gsap.set(shape.elementWrapper, {clearProps: "all"});
      //     }
      // });

      // this.layer.app.shapes.forEach(shape => {
      //     if (shape.elementWrapper) {
      //         gsap.set(shape.elementWrapper, {clearProps: "all"});
      //         gsap.killTweensOf("*");
      //     }
      // });

      this.grid.shapes.forEach(shape => {
        if (shape.layer === this.layer) {
            if (shape.animation && typeof shape.animation.kill === 'function') {
                shape.animation.kill();
            }
            if (shape.animationInterval) {
                clearInterval(shape.animationInterval);
            }
            // Reset the shape to its original state
            gsap.set(shape.elementWrapper, {
                clearProps: "all"
            });

            gsap.killTweensOf("*");
        }
      });
    }


    animatePattern(animationName, params, code) {
        const { triggerType, interval, order, loop } = params;
        const shapes = this.getShapesInOrder(order);
        this.resetAllAnimations();

        if (!animationPresets[animationName]) {
            console.error('Invalid animation:', animationName);
            return;
        }

        const animationCode = code; // animationPresets[animationName];

        // Clear any existing animation
        if (this.currentAnimation) {
            this.currentAnimation.kill();
        }

        if (triggerType === 'simultaneous') {
            this.animateSimultaneously(shapes, animationCode, loop);
        } else if (triggerType === 'sequential') {
            this.animateSequentially(shapes, animationCode, interval, loop);
        }
    }

    animateSimultaneously(shapes, animationCode, loop) {
        shapes.forEach(shape => {
            const animation = this.grid.resetAndApplyAnimation(shape, animationCode);
            if (loop && animation) {
                this.makeAnimationLoop(animation);
            }
        });
    }

    animateSequentially(shapes, animationCode, interval, loop) {
        let currentIndex = 0;

        const animateNext = () => {
            const shape = shapes[currentIndex];
            
            // Reset and apply the animation
            const animation = this.grid.resetAndApplyAnimation(shape, animationCode);

            currentIndex++;

            // Schedule the next animation based on the specified interval
            if (currentIndex < shapes.length) {
                this.currentAnimation.timeout = setTimeout(animateNext, interval);
            } else if (loop) {
                // If we've reached the end of the sequence and loop is true, start over
                currentIndex = 0;
                this.currentAnimation.timeout = setTimeout(animateNext, interval);
            }
        };

        // Start the sequence
        this.currentAnimation = { 
            kill: () => {
                clearTimeout(this.currentAnimation.timeout);
                shapes.forEach(shape => {
                    if (shape.animation && typeof shape.animation.kill === 'function') {
                        shape.animation.kill();
                    }
                    if (shape.animationInterval) {
                        clearInterval(shape.animationInterval);
                    }
                });
            }
        };
        this.currentAnimation.timeout = setTimeout(animateNext, 0);
    }

    makeAnimationLoop(animation) {
        if (animation.timeline) {
            animation.timeline.repeat(-1).yoyo(true);
        } else if (animation.repeat) {
            animation.repeat(-1).yoyo(true);
        }
    }

    getShapesInOrder(order) {
        switch (order) {
            case 'default':
                return this.grid.shapes;
            case 'random':
                return this.shuffleArray([...this.grid.shapes]);
            case 'spiral':
                return this.sortShapesSpiral();
            case 'fromCorner':
                return this.sortShapesFromCorner();
            default:
                return this.grid.shapes;
        }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    sortShapesSpiral() {
        const centerX = this.grid.size / 2;
        const centerY = this.grid.size / 2;
        return this.grid.shapes.sort((a, b) => {
            const angleA = Math.atan2(a.point.y - centerY, a.point.x - centerX);
            const angleB = Math.atan2(b.point.y - centerY, b.point.x - centerX);
            const distA = Math.sqrt((a.point.x - centerX) ** 2 + (a.point.y - centerY) ** 2);
            const distB = Math.sqrt((b.point.x - centerX) ** 2 + (b.point.y - centerY) ** 2);
            
            // Calculate the number of full rotations
            const rotationsA = Math.floor(distA / (this.grid.cellSize * 2));
            const rotationsB = Math.floor(distB / (this.grid.cellSize * 2));

            if (rotationsA !== rotationsB) {
                return rotationsA - rotationsB;
            }

            return angleA - angleB;
        });
    }

    sortShapesFromCorner() {
        return this.grid.shapes.sort((a, b) => {
            const distA = a.point.x + a.point.y;
            const distB = b.point.x + b.point.y;
            return distA - distB;
        });
    }
}