// ShapeDefinitions.js

export const shapeDefinitions = {
    // "quarterCircle": {
    //     "svg": "<path d='M0,0 A1,1 0 0,1 1,1 L0,1 Z' />",
    //     "rotations": 4
    // },
    // "square": {
    //     "svg": "<rect width='1' height='1' />",
    //     "rotations": 1
    // },
    // "halfSquare": {
    //     "svg": "<path d='M0,0 L1,1 L0,1 Z' />",
    //     "rotations": 4
    // },
    // "circle": {
    //     "svg": "<circle cx='0.5' cy='0.5' r='0.5' />",
    //     "rotations": 1
    // },
    // "halfCircleLeft": {
    //     "svg": "<path d='M0,0 A0.5,0.5 0 0,1 0,1 Z' />",
    //     "rotations": 4
    // },
    // "halfCircleRight": {
    //     "svg": "<path d='M0,0 A0.5,0.5 0 0,1 0,1 Z' transform='translate(0.5, 0)' />",
    //     "rotations": 4
    // },
    // "halfSquareLeft": {
    //     "svg": "<rect x='0' y='0' width='0.5' height='1' />",
    //     "rotations": 1
    // },
    // "halfTriangleLeft": {
    //     "svg": "<path d='M0,0 L0.5,1 L0,1 Z' />",
    //     "rotations": 1
    // },
    // "halfTriangleRight": {
    //     "svg": "<path d='M1,0 L1,1 L0.5,1 Z' />",
    //     "rotations": 1
    // }

    
};

export function addCustomShape(name, shapeData) {
    shapeDefinitions[name] = shapeData;
}