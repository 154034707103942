// main.js
import { IconaApp } from './IconaApp.js';

document.addEventListener('DOMContentLoaded', () => {

    const clickableDiv = document.getElementById('getHelp');
    const helpDiv = document.getElementById('help');

    clickableDiv.addEventListener('click', () => {
        helpDiv.classList.toggle('show');
    });

    // if (typeof gsap === 'undefined') {
    //     console.error('GSAP is not loaded. Please make sure to include the GSAP library.');
    //     return;
    // }

    try {
        // // Check if MotionPathPlugin is available on the window object
        // if (window.MotionPathPlugin) {
        //     console.log('MotionPathPlugin is available');
        //     gsap.registerPlugin(window.MotionPathPlugin);
        // } else {
        //     console.warn('MotionPathPlugin is not available. Some SVG animations might not work as expected.');
        // }

        // // Log available GSAP plugins
        // console.log('Available GSAP plugins:', Object.keys(gsap.plugins));
        
        window.app = new IconaApp();
    } catch (error) {
        console.error('Error initializing IconaApp:', error);
    }
});


//const ids = ['gridContainer', 'header', 'toolbar'];

// ids.forEach(id => {
//     const element = document.getElementById(id);
//     if (element) {
//         element.addEventListener('click', (e) => {
//             if (window.app) {
//                 window.app.deselectAllShapes();
//             }
//         });
//     }
// });

document.addEventListener('keydown', (e) => {
    if (e.key === 'Shift' && window.app) {
        window.app.setIsShiftPressed(true);
    }
});

document.addEventListener('keyup', (e) => {
    if (e.key === 'Shift' && window.app) {
        window.app.setIsShiftPressed(false);
    }
});
