import { shapeDefinitions } from './ShapeDefinitions.js';
import { Animator } from './Animator.js';  // Add this line

export class Grid {
    constructor(container, size, cellSize, complexity = 1, layer) {
        this.container = container;
        this.size = size;
        this.cellSize = cellSize;
        this.complexity = complexity;
        this.layer = layer;
        this.shapes = [];
        this.two = null;
        this.gridGroup = null;
        this.shapeGroup = null;
        this.showGrid = true;
        this.isDragging = false;
        this.draggedShape = null;
        this.selectedShape = null;
        this.highlightShape = null;
        this.dragOffset = { x: 0, y: 0 };
        this.dragStartPosition = { x: 0, y: 0 };
        this.subCellSize = this.cellSize / this.complexity;
        this.boundHandleMouseDown = this.handleMouseDown.bind(this);
        this.boundHandleMouseMove = this.handleMouseMove.bind(this);
        this.boundHandleMouseUp = this.handleMouseUp.bind(this);
        this.animator = null;
        this.offsetX = 0;
        this.offsetY = 0;
        this.detectedFPS = 60; // Default to 60 FPS
    }

    /*create() {
        const scaleFactor = 2; // Increase this value for higher quality
        const pixelRatio = window.devicePixelRatio || 1;
        
        this.two = new Two({
            type: Two.Types.webgl,
            width: this.size * this.cellSize * scaleFactor,
            height: this.size * this.cellSize * scaleFactor,
            ratio: pixelRatio,
            autostart: true,
             //antialias: true
        }).appendTo(this.container);

        // Scale the entire scene to compensate for the higher resolution
        this.two.scene.scale = scaleFactor;

        // Adjust the size of the canvas element
        const canvas = this.two.renderer.domElement;
        canvas.style.width = `${this.size * this.cellSize}px`;
        canvas.style.height = `${this.size * this.cellSize}px`;

        this.gridGroup = this.two.makeGroup();
        this.shapeGroup = this.two.makeGroup();
        this.drawGrid();
        this.setupFPSCounter();
        this.addEventListeners();
        this.two.update();
    }*/

    // // CANVAS
    // create() {
    //     // Create a new canvas element
    //     const canvas = document.createElement('canvas');
    //     canvas.width = this.size * this.cellSize;
    //     canvas.height = this.size * this.cellSize;
    //     canvas.style.position = 'absolute';
    //     canvas.style.top = '0';
    //     canvas.style.left = '0';
    //     canvas.style.display = this.layer.visible ? 'block' : 'none';
        
    //     // Append the canvas to the container
    //     this.container.appendChild(canvas);

    //     // Create the Two.js instance with the new canvas
    //     this.two = new Two({
    //         type: Two.Types.canvas,
    //         width: this.size * this.cellSize,
    //         height: this.size * this.cellSize,
    //         domElement: canvas,
    //         autostart: true,
    //     });

    //     this.gridGroup = this.two.makeGroup();
    //     this.shapeGroup = this.two.makeGroup();
    //     this.drawGrid();
    //     this.setupFPSCounter();
    //     this.addEventListeners();
    //     this.two.update();
    //     this.animator = new Animator(this.two);
    // }

    // // CANVAS
    create() {
        // Create a new canvas element
        const canvas = document.createElement('canvas');
        canvas.width = this.size * this.cellSize;
        canvas.height = this.size * this.cellSize;
        canvas.style.position = 'absolute';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.display = this.layer.visible ? 'block' : 'none';
        
        // Append the canvas to the container
        this.container.appendChild(canvas);

        // Create the Two.js instance with the new canvas
        this.two = new Two({
            type: Two.Types.canvas,
            width: this.size * this.cellSize,
            height: this.size * this.cellSize,
            domElement: canvas,
            autostart: true,
        });
        //this.two.renderer.domElement.style.backgroundColor = '#ffffff';

        this.gridGroup = this.two.makeGroup();
        this.shapeGroup = this.two.makeGroup();
        this.drawGrid();
        this.setupFPSCounter();
        this.addEventListeners();
        this.two.update();
        this.animator = new Animator(this.two);
    }


    //WEBGL
    // create() {
    //     const scaleFactor = 2; // Increase this value for higher quality
    //     const pixelRatio = window.devicePixelRatio || 1;

    //     // Create the Two.js instance
    //     this.two = new Two({
    //         type: Two.Types.webgl,
    //         width: this.size * this.cellSize * scaleFactor,
    //         height: this.size * this.cellSize * scaleFactor,
    //         ratio: window.devicePixelRatio,
    //         autostart: true,
    //     }).appendTo(this.container);

    //     // Adjust the size of the canvas element created by Two.js
    //     const canvas = this.two.renderer.domElement;
    //     canvas.style.width = `${this.size * this.cellSize}px`;
    //     canvas.style.height = `${this.size * this.cellSize}px`;
    //     canvas.style.position = 'absolute';
    //     canvas.style.top = '0';
    //     canvas.style.left = '0';
    //     canvas.style.display = this.layer.visible ? 'block' : 'none';

    //     // Scale the entire scene to compensate for the higher resolution
    //     this.two.scene.scale = scaleFactor;

    //     this.gridGroup = this.two.makeGroup();
    //     this.shapeGroup = this.two.makeGroup();
    //     this.drawGrid();
    //     this.setupFPSCounter();
    //     this.addEventListeners();
    //     this.two.update();
    //     this.animator = new Animator(this.two);
    // }

    drawGrid() {
        if (!this.showGrid) return;

        const gridLines = this.two.makeGroup();
        const step = this.cellSize / this.complexity;

        for (let i = 0; i <= this.size * this.cellSize; i += step) {
            const horizontalLine = this.two.makeLine(0, i, this.size * this.cellSize, i);
            const verticalLine = this.two.makeLine(i, 0, i, this.size * this.cellSize);
            horizontalLine.stroke = 'rgba(0, 0, 0, 0.1)';
            verticalLine.stroke = 'rgba(0, 0, 0, 0.1)';
            gridLines.add(horizontalLine, verticalLine);
        }

        this.gridGroup.add(gridLines);
    }

    addEventListeners() {
        this.two.renderer.domElement.addEventListener('mousedown', this.boundHandleMouseDown);
        window.addEventListener('mousemove', this.boundHandleMouseMove);
        window.addEventListener('mouseup', this.boundHandleMouseUp);

        this.two.renderer.domElement.addEventListener('mousedown', this.handleShiftClick.bind(this));
        window.addEventListener('keydown', this.handleKeyDown.bind(this));
    }

    handleKeyDown(event) {
        if ((event.key === 'Backspace' || event.key === 'Delete') && this.selectedShape) {
            this.deleteSelectedShape();
            event.preventDefault();
        }
    }

    handleShiftClick(event) {
        if (!event.shiftKey) return;
        
        const rect = this.two.renderer.domElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        
        const clickedShape = this.findShapeAtPosition(x, y);
        
        if (clickedShape) {
            this.rotateShapeByDegrees(clickedShape, 90);
            this.highlightSelectedShape(clickedShape); // Add this line to update the highlight
            event.preventDefault();
        }
    }

    handleMouseDown(event) {
        if (event.shiftKey) return; // Add this line to prevent default behavior when Shift is pressed
        
        const rect = this.two.renderer.domElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const clickedShape = this.findShapeAtPosition(x, y);

        if (clickedShape) {
            this.highlightSelectedShape(clickedShape);

            this.isDragging = true;
            this.draggedShape = clickedShape;
            this.dragOffset = {
                x: x - clickedShape.translation.x,
                y: y - clickedShape.translation.y
            };
            this.dragStartPosition = {
                x: clickedShape.translation.x,
                y: clickedShape.translation.y
            };
        } else {
            this.highlightSelectedShape(null);
            this.placeShape(x, y, this.layer.app.selectedShape);
        }
    }

    handleMouseMove(event) {
        if (!this.isDragging || !this.draggedShape) return;

        const rect = this.two.renderer.domElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        this.draggedShape.translation.set(
            x - this.dragOffset.x,
            y - this.dragOffset.y
        );

        if (this.highlightShape) {
            this.highlightShape.translation.set(
                x - this.dragOffset.x,
                y - this.dragOffset.y
            );
        }

        this.two.update();
    }

    handleMouseUp(event) {
        if (this.isDragging && this.draggedShape) {
            const rect = this.two.renderer.domElement.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const dx = x - (this.dragStartPosition.x + this.dragOffset.x);
            const dy = y - (this.dragStartPosition.y + this.dragOffset.y);

            const dxSubCells = Math.round(dx / this.subCellSize);
            const dySubCells = Math.round(dy / this.subCellSize);

            if (dxSubCells === 0 && dySubCells === 0) {
                if (Math.abs(dx) < 1 && Math.abs(dy) < 1) {
                    //this.rotateShape(this.draggedShape);
                } else {
                    this.draggedShape.translation.set(this.dragStartPosition.x, this.dragStartPosition.y);
                }
            } else {
                const newX = this.dragStartPosition.x + (dxSubCells * this.subCellSize);
                const newY = this.dragStartPosition.y + (dySubCells * this.subCellSize);
                this.draggedShape.translation.set(newX, newY);
            }
            
            if (this.highlightShape) {
                this.highlightShape.translation.set(this.draggedShape.translation.x, this.draggedShape.translation.y);
            }
            this.two.update();
        }
        this.isDragging = false;
        this.draggedShape = null;
        this.dragStartPosition = { x: 0, y: 0 };
    }

    rotateShapeByDegrees(shape, degrees) {
        if (!shape) return;
        
        shape.rotation += degrees * (Math.PI / 180);
        
        if (this.highlightShape) {
            this.highlightShape.rotation = shape.rotation;
            this.highlightShape.translation.copy(shape.translation);
        }
        
        this.two.update();
    }

    placeShape(x, y, shapeName) {
        if (!shapeName || !shapeDefinitions[shapeName]) return null;

        const subCellX = Math.floor(x / this.subCellSize);
        const subCellY = Math.floor(y / this.subCellSize);
        const gridX = subCellX * this.subCellSize;
        const gridY = subCellY * this.subCellSize;

        const existingShapeIndex = this.shapes.findIndex(shape => 
            shape.translation.x === gridX + this.subCellSize / 2 && 
            shape.translation.y === gridY + this.subCellSize / 2
        );

        if (existingShapeIndex !== -1) {
            return null;
        }

        const shapeData = shapeDefinitions[shapeName];
        const shape = this.createShape(shapeData, gridX, gridY);
        if (shape) {
            this.shapeGroup.add(shape);
            this.shapes.push(shape);
            this.two.update();
            return shape;
        }

        return null;
    }



    createShape(shapeData, x, y) {
        let shape;
        const scaleFactor = this.subCellSize / Math.max(shapeData.width, shapeData.height);
        
        if (shapeData.svg) {
            const svgElement = new DOMParser().parseFromString(shapeData.svg, 'image/svg+xml').documentElement;
            shape = this.two.interpret(svgElement);
            
            const viewBox = svgElement.getAttribute('viewBox');
            if (viewBox) {
                const [vbX, vbY, vbWidth, vbHeight] = viewBox.split(' ').map(Number);
                const svgScaleFactor = this.subCellSize / Math.max(vbWidth, vbHeight);
                
                // Get the original bounds of the shape
                const originalBounds = shape.getBoundingClientRect(true);
                
                // Calculate relative position within viewBox (-1 to 1)
                const relativeX = ((originalBounds.left + originalBounds.right) / 2 - vbX) / vbWidth * 2 - 1;
                const relativeY = ((originalBounds.top + originalBounds.bottom) / 2 - vbY) / vbHeight * 2 - 1;
                
                // Store these relative positions
                shape.originalRelativeX = relativeX;
                shape.originalRelativeY = relativeY;
                
                shape.scale = svgScaleFactor;
            } else {
                shape.scale = scaleFactor;
                shape.originalRelativeX = 0;
                shape.originalRelativeY = 0;
            }
            shape.center();
            shape.width = this.subCellSize;
            shape.height = this.subCellSize;
            shape.opacity = 1;
        } else if (shapeData.lottie || shapeData.gif) {
            shape = this.two.makeRectangle(0, 0, this.subCellSize, this.subCellSize);
            shape.fill = 'rgba(0, 157, 236, 0.5)';
            shape.stroke = 'rgba(0, 157, 236, 1)';
            shape.width = this.subCellSize;
            shape.height = this.subCellSize;
            shape.opacity = 1;
            shape.originalRelativeX = 0;
            shape.originalRelativeY = 0;
        } else {
            return null;
        }
        
        // Calculate the final position
        const finalX = x + this.subCellSize / 2 + (shape.originalRelativeX * this.subCellSize / 2);
        const finalY = y + this.subCellSize / 2 + (shape.originalRelativeY * this.subCellSize / 2);
        
        shape.translation.set(finalX, finalY);
        
        shape.name = shapeData.name;
        shape.originalShapeData = shapeData;
        shape.scaleX = shape.scale;
        shape.scaleY = shape.scale;
        shape.scale = new Two.Vector(shape.scale, shape.scale);
        shape.strokeWidth = 0;
        
        return shape;
    }

    
    highlightSelectedShape(shape) {
        this.selectedShape = shape;
        return false;
        console.log("Highlighting shape:", shape ? shape.name : "none");

        if (this.highlightShape) {
            this.shapeGroup.remove(this.highlightShape);
            this.highlightShape = null;
        }

        if (shape) {
            this.highlightShape = shape.clone();
            this.highlightShape.noFill();
            this.highlightShape.stroke = 'rgba(255, 0, 0, 1.0)';
            this.highlightShape.linewidth = 2;
            this.highlightShape.dashes = [];
            this.highlightShape.scale = shape.scale;
            this.highlightShape.rotation = shape.rotation;
            this.highlightShape.translation.copy(shape.translation);

            // If the shape is a group (like an SVG), we need to apply the style to all children
            if (this.highlightShape.children) {
                this.highlightShape.children.forEach(child => {
                    child.noFill();
                    child.stroke = 'rgba(255, 0, 0, 1.0)';
                    child.linewidth = 5 / this.highlightShape.scale;
                    child.dashes = [];
                });
            }

            this.shapeGroup.add(this.highlightShape);
            
            // Ensure the highlight is on top
            this.shapeGroup.remove(this.highlightShape);
            this.shapeGroup.add(this.highlightShape);
        }

        this.two.update();
    }

    deleteSelectedShape() {
        if (this.selectedShape) {
            const index = this.shapes.indexOf(this.selectedShape);
            if (index > -1) {
                this.shapes.splice(index, 1);
            }
            this.shapeGroup.remove(this.selectedShape);
            this.highlightSelectedShape(null);
            this.two.update();
        }
    }

    rotateShape(shape) {
        shape.rotation += Math.PI / 2;
        if (this.highlightShape && this.highlightShape.parent === shape.parent) {
            this.highlightShape.rotation = shape.rotation;
        }
        this.two.update();
    }

    toggleGrid() {
        let gridContainer = document.getElementById('gridContainer');
        this.showGrid = !this.showGrid;
        this.gridGroup.visible = this.showGrid;
        this.two.update();
        gridContainer.style.borderWidth = this.showGrid ? '1px' : '0px';
    }

    setComplexity(newComplexity) {
        this.complexity = newComplexity;
        this.subCellSize = this.cellSize / this.complexity;
        this.gridGroup.remove(this.gridGroup.children);
        this.drawGrid();
        this.two.update();
    }

    clearAllShapes() {
        // Stop all animations
        if (this.animator) {
            this.animator.stopAllAnimations();
        }

        // Remove all shapes from the Two.js instance
        while (this.shapeGroup.children.length > 0) {
            this.shapeGroup.remove(this.shapeGroup.children[0]);
        }

        // Clear the shapes array
        this.shapes = [];

        // Force update the Two.js instance
        this.two.update();

        // Log the number of remaining shapes
        //console.log(`Shapes remaining in shapeGroup: ${this.shapeGroup.children.length}`);
        //console.log(`Shapes remaining in shapes array: ${this.shapes.length}`);
    }

    findShapeAtPosition(x, y) {
        for (let i = this.shapes.length - 1; i >= 0; i--) {
            const shape = this.shapes[i];
            if (this.isPointInShape(shape, x, y)) {
                console.log("Found shape:", shape.name);
                return shape;
            }
        }
        console.log("No shape found at", x, y);
        return null;
    }

    isPointInShape(shape, x, y) {
        const localPoint = this.transformPoint(x, y, shape);
        
        if (shape instanceof Two.Path) {
            return this.isPointInPath(shape, localPoint.x, localPoint.y);
        } else if (shape instanceof Two.Group) {
            for (let child of shape.children) {
                if (this.isPointInShape(child, localPoint.x, localPoint.y)) {
                    return true;
                }
            }
        }
        return false;
    }

    isPointInPath(path, x, y) {
        if (path instanceof Two.Rectangle) {
            const halfWidth = path.width / 2;
            const halfHeight = path.height / 2;
            return x >= -halfWidth && x <= halfWidth && y >= -halfHeight && y <= halfHeight;
        } else if (path instanceof Two.Circle) {
            return x * x + y * y <= path.radius * path.radius;
        } else if (path instanceof Two.Path || path instanceof Two.Group) {
            return this.isPointInPolygon(path, x, y);
        }
        return false;
    }

    isPointInPolygon(path, x, y) {
        let vertices = [];
        if (path instanceof Two.Path) {
            vertices = path.vertices;
        } else if (path instanceof Two.Group) {
            path.children.forEach(child => {
                if (child instanceof Two.Path) {
                    vertices = vertices.concat(child.vertices);
                }
            });
        }

        let inside = false;
        for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
            const xi = vertices[i].x, yi = vertices[i].y;
            const xj = vertices[j].x, yj = vertices[j].y;
            
            const intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        
        return inside;
    }

    transformPoint(x, y, shape) {
        const globalToLocal = shape.matrix.inverse();
        const localPoint = globalToLocal.multiply(x, y, 1);
        return { x: localPoint.x, y: localPoint.y };
    }



    // Function to get sorted positions
    getSortedPositions(sortCode, size, complexity) {
        const totalSize = size * complexity;
        let positions = [...Array(totalSize * totalSize).keys()];

        try {
            const customSortFunction = new Function('positions', 'totalSize', sortCode);
            positions = customSortFunction(positions, totalSize);
        } catch (error) {
            console.error('Error in sort code:', error);
            // Fallback to unsorted positions if there's an error
        }

        return positions;
    }

    


    _fillGridRandomly(animationCode, sortCode, sortMethods, fill = 1) {

        let positions = [];
        this.clearAllShapes();
        const totalCells = this.size * this.size * this.complexity * this.complexity;
        const cellsToFill = Math.floor(totalCells * fill); // how much fill ? 0.0 - 1.0
        const allPositions = [];
        for (let x = 0; x < this.size * this.complexity; x++) {
            for (let y = 0; y < this.size * this.complexity; y++) {
                allPositions.push({ x, y });
            }
        }
        
        // const sortedPositions = this.getSortedPositions(sortMethod, this.size, this.complexity, sortMethods);        
       
        let sortedPositions;
        if (typeof sortCode === 'string' && sortMethods.includes(sortCode)) {
            sortedPositions = this.getSortedPositions(sortCode, this.size, this.complexity);
        } else {
            // Custom sorting code
            const positions = [];
            for (let y = 0; y < this.size * this.complexity; y++) {
                for (let x = 0; x < this.size * this.complexity; x++) {
                    positions.push({ x, y });
                }
            }
            try {
                const customSortFunction = new Function('positions', sortCode);
                sortedPositions = customSortFunction(positions);
            } catch (error) {
                console.error('Error in custom sort code:', error);
                sortedPositions = positions; // Fallback to unsorted positions
            }
        }

        for (let i = 0; i < cellsToFill; i++) {
            const pos = sortedPositions[i];
            const x = pos.x * this.subCellSize + this.subCellSize / 2;
            const y = pos.y * this.subCellSize + this.subCellSize / 2;
            this.placeShape(x, y, this.layer.app.getRandomSelectedShape());
        }



       

        /* nice stuff */ 


        // animator.addAnimation(groupX, shape, 'rotation', {
        //     from: 0,
        //     to: Math.PI * 2,
        //     //x: 50,
        //     duration: 1000,
        //     options: { 
        //         sequentialDelay: 200,
        //         loopIndividual: false,
        //         yoyo: true,
        //         easing: easings.quadInOut
        //     }
        // });

        function fibonacci(n) {
            if (n <= 1) return n;
            return fibonacci(n - 1) + fibonacci(n - 2);
        }

        function isPrime(num) {
            for(let i = 2, s = Math.sqrt(num); i <= s; i++)
                if(num % i === 0) return false; 
            return num > 1;
        }

        const easing = {
            linear: t => t,

            quadInOut: t => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2,

            cubicInOut: t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2,

            quartInOut: t => t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2,

            sineInOut: t => -(Math.cos(Math.PI * t) - 1) / 2,

            expoInOut: t => t === 0 
                ? 0 
                : t === 1 
                ? 1 
                : t < 0.5 ? Math.pow(2, 20 * t - 10) / 2 
                : (2 - Math.pow(2, -20 * t + 10)) / 2,

            circInOut: t => t < 0.5 
                ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2 
                : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2,

            backInOut: t => {
                const c1 = 1.70158;
                const c2 = c1 * 1.525;
                return t < 0.5
                    ? (Math.pow(2 * t, 2) * ((c2 + 1) * 2 * t - c2)) / 2
                    : (Math.pow(2 * t - 2, 2) * ((c2 + 1) * (t * 2 - 2) + c2) + 2) / 2;
            },

            elasticOut: t => {
                const c = (2 * Math.PI) / 3;
                return t === 0 
                    ? 0 
                    : t === 1 
                    ? 1 
                    : Math.pow(2, -10 * t) * Math.sin((t * 10 - 0.75) * c) + 1;
            },

            bounceOut: t => {
                const n1 = 7.5625;
                const d1 = 2.75;
                if (t < 1 / d1) {
                    return n1 * t * t;
                } else if (t < 2 / d1) {
                    return n1 * (t -= 1.5 / d1) * t + 0.75;
                } else if (t < 2.5 / d1) {
                    return n1 * (t -= 2.25 / d1) * t + 0.9375;
                } else {
                    return n1 * (t -= 2.625 / d1) * t + 0.984375;
                }
            }
        };

        //import { noise } from '@chriscourses/perlin-noise' if you want to use noise


        // Apply animations after all shapes are placed

        //const delay = 100 + Math.sin(index * 0.5) * 100; // Sine wave variation:
        //const delay = fibonacci(index % 10) * 100; // Modulo 10 to keep delays manageable
        //const delay = 500 + Math.random() * 1000; // Random delay between 500ms and 1500ms
        //const delay = 100 * Math.pow(1.2, index); // Exponential increase
        //const delay = isPrime(index) ? 800 : 200; // Longer delay for prime indices
        //const delay = 500 + noise(index * 0.1) * 1000; // Perlin noise (you'll need to import a Perlin noise library):
        
        
        
        const animator = this.animator; // new Animator(this.two);
        this.shapes.forEach((shape, index) => {
            eval(animationCode);
        });
        this.two.update();
    }
    

    fillGridRandomly(animationCode, sortCode, sortMethods, fill = 1, redrawShapes = false) {
        console.log("fillGridRandomly called with sortCode:", sortCode);
        console.log("Available sort methods:", sortMethods);

        // Ensure animator is initialized
        if (!this.animator) {
            this.animator = new Animator(this.two);
        }

        // Always stop all existing animations
        this.animator.stopAllAnimations();

        let shapesToAnimate = this.shapes;

        if (redrawShapes) {
            // Clear existing shapes and create new ones
            this.clearAllShapes();

            const totalCells = this.size * this.size * this.complexity * this.complexity;
            const cellsToFill = Math.floor(totalCells * fill);
            
            let positions = [];
            for (let y = 0; y < this.size * this.complexity; y++) {
                for (let x = 0; x < this.size * this.complexity; x++) {
                    positions.push({ x, y });
                }
            }

            for (let i = 0; i < cellsToFill; i++) {
                const pos = positions[i];
                const x = pos.x * this.subCellSize + this.subCellSize / 2;
                const y = pos.y * this.subCellSize + this.subCellSize / 2;
                this.placeShape(x, y, this.layer.app.getRandomSelectedShape());
            }

            shapesToAnimate = this.shapes;
        } else {
            // Reset all shapes to their original state
            this.shapes.forEach(shape => {
                if (shape.originalState) {
                    shape.rotation = shape.originalState.rotation;
                    shape.scale.set(shape.originalState.scale.x, shape.originalState.scale.y);
                    shape.translation.set(shape.originalState.translation.x, shape.originalState.translation.y);
                    shape.fill = shape.originalState.fill;
                    shape.stroke = shape.originalState.stroke;
                    shape.opacity = shape.originalState.opacity;
                    // Add any other properties you want to reset
                }
            });
        }

        function fibonacci(n) {
            if (n <= 1) return n;
            return fibonacci(n - 1) + fibonacci(n - 2);
        }

        function isPrime(num) {
            for(let i = 2, s = Math.sqrt(num); i <= s; i++)
                if(num % i === 0) return false; 
            return num > 1;
        }

        const easing = {
            linear: t => t,
            quadInOut: t => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2,
            cubicInOut: t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2,
            quartInOut: t => t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2,
            sineInOut: t => -(Math.cos(Math.PI * t) - 1) / 2,
            expoInOut: t => t === 0 
                ? 0 
                : t === 1 
                ? 1 
                : t < 0.5 ? Math.pow(2, 20 * t - 10) / 2 
                : (2 - Math.pow(2, -20 * t + 10)) / 2,
            circInOut: t => t < 0.5 
                ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2 
                : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2,
            backInOut: t => {
                const c1 = 1.70158;
                const c2 = c1 * 1.525;
                return t < 0.5
                    ? (Math.pow(2 * t, 2) * ((c2 + 1) * 2 * t - c2)) / 2
                    : (Math.pow(2 * t - 2, 2) * ((c2 + 1) * (t * 2 - 2) + c2) + 2) / 2;
            },
            elasticOut: t => {
                const c = (2 * Math.PI) / 3;
                return t === 0 
                    ? 0 
                    : t === 1 
                    ? 1 
                    : Math.pow(2, -10 * t) * Math.sin((t * 10 - 0.75) * c) + 1;
            },
            bounceOut: t => {
                const n1 = 7.5625;
                const d1 = 2.75;
                if (t < 1 / d1) {
                    return n1 * t * t;
                } else if (t < 2 / d1) {
                    return n1 * (t -= 1.5 / d1) * t + 0.75;
                } else if (t < 2.5 / d1) {
                    return n1 * (t -= 2.25 / d1) * t + 0.9375;
                } else {
                    return n1 * (t -= 2.625 / d1) * t + 0.984375;
                }
            }
        };

        // Apply sorting to determine animation order
        let sortedIndices = this.getSortedPositions(sortCode, this.size, this.complexity);
        const executeAnimationCode = new Function('animator', 'shape', 'index', 'sortIndex', 'totalCells', 'totalShapes', 'numOfColumns', 'numOfRows', 'gridIndex', 'easing', animationCode);

        // Apply animations to shapes in the sorted order
        const animator = this.animator; // Make animator available in the eval scope
        sortedIndices.forEach((index, sortedIndex) => {
            const shape = shapesToAnimate[index];
            if (shape) {
                // Store the original state before applying new animations
                shape.originalState = {
                    rotation: shape.rotation,
                    scale: {x: shape.scale.x, y: shape.scale.y},
                    translation: {x: shape.translation.x, y: shape.translation.y},
                    fill: '#000000',
                    stroke: 0,
                    opacity: shape.opacity
                    // Add any other properties you want to preserve
                };

                try {
                    // sent to animationCode
                   
                    const sortIndex = sortedIndex;
                    const totalCells = sortedIndices.length;
                    const totalShapes = this.shapes.length;
                    const numOfColumns = this.size * this.complexity;
                    const numOfRows = this.size * this.complexity;
                    const gridIndex = {
                        x: Math.floor(sortedIndex % (this.size * this.complexity)),
                        y: Math.floor(sortedIndex / (this.size * this.complexity))
                    };

                    executeAnimationCode(
                        animator,
                        shape,
                        index,
                        sortedIndex,
                        sortedIndices.length,
                        this.shapes.length,
                        this.size * this.complexity,
                        this.size * this.complexity,
                        gridIndex,
                        easing
                    );
                        
                    //eval(animationCode);
                } catch (error) {
                    console.error('Error in animation code:', error);
                }
            }
        });

        //console.log("Sorted positions (first 10):", sortedIndices.slice(0, 10));

        this.two.update();
    }

    setupFPSCounter() {
        let lastTime = performance.now();
        let frames = 0;
        const fpsElement = document.querySelector('.fps');
        let refreshRate = 60;
        let fpsThreshold = refreshRate - 1;
        
        fpsElement.style.color = 'black';
        fpsElement.style.padding = '5px';
        
        const estimateRefreshRate = (callback) => {
            let count = 0;
            let lastTimestamp = performance.now();
            
            const measure = (timestamp) => {
                count++;
                if (count < 120) {
                    const interval = timestamp - lastTimestamp;
                    if (interval > 0) {
                        refreshRate = Math.min(refreshRate, 1000 / interval);
                    }
                    lastTimestamp = timestamp;
                    requestAnimationFrame(measure);
                } else {
                    refreshRate = Math.round(refreshRate);
                    fpsThreshold = refreshRate - 1;
                    this.detectedFPS = refreshRate; // Store the detected FPS
                    callback();
                }
            };
            
            requestAnimationFrame(measure);
        };
        
        const updateFPS = () => {
            const now = performance.now();
            frames++;
            if (now - lastTime >= 1000) {
                if (frames <= fpsThreshold) {
                    fpsElement.style.color = 'red';
                    fpsElement.innerHTML = `FPS: ${frames} (${refreshRate}Hz) &#9785;`;
                } else {
                    fpsElement.style.color = 'black';
                    fpsElement.innerHTML = `FPS: ${frames} (${refreshRate}Hz)`;
                }
                
                frames = 0;
                lastTime = now;
            }
            requestAnimationFrame(updateFPS);
        };
        
        estimateRefreshRate(() => {
            requestAnimationFrame(updateFPS);
        });
    }

}


const easing = {
    linear: t => t,
    quadInOut: t => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2,
    cubicInOut: t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2,
    quartInOut: t => t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2,
    sineInOut: t => -(Math.cos(Math.PI * t) - 1) / 2,
    expoInOut: t => t === 0 
        ? 0 
        : t === 1 
        ? 1 
        : t < 0.5 ? Math.pow(2, 20 * t - 10) / 2 
        : (2 - Math.pow(2, -20 * t + 10)) / 2,
    circInOut: t => t < 0.5 
        ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2 
        : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2,
    backInOut: t => {
        const c1 = 1.70158;
        const c2 = c1 * 1.525;
        return t < 0.5
            ? (Math.pow(2 * t, 2) * ((c2 + 1) * 2 * t - c2)) / 2
            : (Math.pow(2 * t - 2, 2) * ((c2 + 1) * (t * 2 - 2) + c2) + 2) / 2;
    },
    elasticOut: t => {
        const c = (2 * Math.PI) / 3;
        return t === 0 
            ? 0 
            : t === 1 
            ? 1 
            : Math.pow(2, -10 * t) * Math.sin((t * 10 - 0.75) * c) + 1;
    },
    bounceOut: t => {
        const n1 = 7.5625;
        const d1 = 2.75;
        if (t < 1 / d1) {
            return n1 * t * t;
        } else if (t < 2 / d1) {
            return n1 * (t -= 1.5 / d1) * t + 0.75;
        } else if (t < 2.5 / d1) {
            return n1 * (t -= 2.25 / d1) * t + 0.9375;
        } else {
            return n1 * (t -= 2.625 / d1) * t + 0.984375;
        }
    }
};

export { easing };


/*
scale: Overall scale of the shape

Usage: 'scale'


rotation: Rotation of the shape (in radians)

Usage: 'rotation'


translation.x: X position of the shape

Usage: 'translation.x'


translation.y: Y position of the shape

Usage: 'translation.y'


opacity: Opacity of the shape (0 to 1)

Usage: 'opacity'


fill: Fill color of the shape

Usage: 'fill'
Note: For color animations, you'll need to implement a custom interpolation function


stroke: Stroke color of the shape

Usage: 'stroke'
Note: For color animations, you'll need to implement a custom interpolation function


linewidth: Width of the stroke

Usage: 'linewidth'


position.x: X position of a vertex (for paths)

Usage: 'position.x'


position.y: Y position of a vertex (for paths)

Usage: 'position.y'


beginning: Start point of path drawing (0 to 1)

Usage: 'beginning'


ending: End point of path drawing (0 to 1)

Usage: 'ending'



For specific shape types:

width: Width of a rectangle

Usage: 'width'


height: Height of a rectangle

Usage: 'height'


radius: Radius of a circle

Usage: 'radius'


innerRadius: Inner radius of a ring

Usage: 'innerRadius'


outerRadius: Outer radius of a ring

Usage: 'outerRadius'
*/