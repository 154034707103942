// IconaApp.js

import { Layer } from './Layers.js';
import { ShapeCreator } from './ShapeCreator.js';
import { animationPresets } from './AnimationPresets.js';
import { shapeDefinitions, addCustomShape } from './ShapeDefinitions.js';
import { PatternGenerator, PatternManipulator, PatternAnimator } from './PatternGenerator.js';

export class IconaApp {
    constructor() {
        this.version = "1.092";
        this.layers = [];
        this.activeLayer = null;
        this.gridSize = 9;
        this.cellSize = 80;
        this.showGrid = true;
        this.showLayers = false;
        this.showShapeLibraryPanel = false;
        this.isErasing = false;
        this.isShiftPressed = false;
        this.selectedShape = '';
        this.isDragging = false;
        this.dragStartX = 0;
        this.dragStartY = 0;
        this.toolbarIconSize = 50;
        this.projectTitle = '';
        this.isInverted = false;
        this.globalComplexity = 1;
        this.multiSelectedShapes = [];
        this.isDrawerOpen = false;
        this.patternGenerator = null;
        this.patternManipulator = null;
        this.isPatternDrawerInitialized = false;
        this.autoScaleNewShapes = false;
        this.redrawShapes = true;
        //this.complexityValues = [1, 2, 4, 8, 16];
        this.complexityValues = [1, 2, 4];
        //this.zoomThresholds = [0.5, 1, 2, 4, 8, 16];
        this.zoomThresholds = [0.75, 1, 2];
        this.shapeLibrary = [];
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.isRecording = false;
        this.recordingStartTime = 0;
        this.recordingDuration = 0;
        this.recordingTimer = null;
        this.progressBar = null;
        this.progressAnimation = null;
        this.sortMethods = [
            'random',
            'rowByRow',
            'columnByColumn',
            'centerToEdges',
            'edgesToCenter'
        ];
        this.setActiveLayer(this.layers[0]);
        this.gridContainer = document.getElementById('gridContainer');
        //this.createInitialLayer();
        this.layersList = document.getElementById('layersList');
        this.toolbar = document.getElementById('toolbar');
        this.initInvertColors();
        this.initFillSlider();
        this.loadShapeLibrary();
        this.setupAnimationControls();
        this.setupSortMethodEditor();
        this.init();
    }

    init() {
        this.createInitialLayer();
        this.createToolbar();
        this.toggleLayers(true);
        this.createGlobalComplexitySlider();
        //this.createAutoScaleToggle();
        this.initShapeCreator();
        this.initProjectTitleInput();
        this.addEventListeners();
        this.setVersionNumber();
        this.initRecordingFeature();

        // Test FFmpeg worker
        // try {
        //     const testWorker = new Worker('/assets/js/ffmpeg-worker-mp4.js');
        //     console.log('FFmpeg worker loaded successfully');
        //     testWorker.terminate();
        // } catch (error) {
        //     console.error('Error loading FFmpeg worker:', error);
        // }

        //this.updateFillSlider();
        //this.initPatternGenerator();
        //this.initPatternManipulator();
        //this.initPatternAnimator();
        //this.addPatternGeneratorEventListeners();
        //this.populateAnimationSelect();
    }

    initShapeCreator() {
        this.shapeCreator = new ShapeCreator('shapeCreatorContainer', this);
    }   

    initProjectTitleInput() {
        const titleInput = document.getElementById('projectTitle');
        titleInput.addEventListener('input', (e) => {
            this.projectTitle = e.target.value;
        });
    }

    initPatternGenerator() {
        this.patternGenerator = new PatternGenerator(this.activeLayer.grid, this.activeLayer.complexity);
    }

    initPatternManipulator() {
        this.patternManipulator = new PatternManipulator(this.activeLayer.grid);
    }

    initPatternAnimator() {
        this.patternAnimator = new PatternAnimator(this.activeLayer.grid);
    }

    initializePatternUI() {
        const patternTypeSelect = document.getElementById('patternType');
        patternTypeSelect.value = 'generator'; // Set default to 'generator'
        this.updatePatternUI();
    }

    initFillSlider() {
        const slider = document.querySelector('.rangeSlider input');
        const currentFill = document.querySelector('.currentFill');
        //const maxFill = document.querySelector('.maxFill');

        this.updateFillSlider();  // Set initial values

        // Set max value based on grid size
        // const totalCells = this.gridSize * this.gridSize * this.globalComplexity * this.globalComplexity;
        // slider.max = totalCells;
        // maxFill.textContent = totalCells;

        slider.addEventListener('input', (e) => {
            const totalCells = this.gridSize * this.gridSize * this.globalComplexity * this.globalComplexity;
            const fillAmount = parseInt(e.target.value) / totalCells;
            currentFill.textContent = e.target.value;
            
            // if (this.activeLayer) {
            //     this.activeLayer.grid.fillGridRandomly(
            //         this.codeEditor.getValue(),
            //         this.sortMethodCodeMirror.getValue(),
            //         this.sortMethods,
            //         fillAmount
            //     );
            // }
        });
    }

    updateFillSlider() {
        const slider = document.querySelector('.rangeSlider input');
        const currentFill = document.querySelector('.currentFill');
        const maxFill = document.querySelector('.maxFill');

        if (slider && currentFill && maxFill) {
            let totalCells;
            if (this.activeLayer && this.activeLayer.complexity) {
                totalCells = this.gridSize * this.gridSize * this.activeLayer.complexity * this.activeLayer.complexity;
            } else {
                totalCells = this.gridSize * this.gridSize * this.globalComplexity * this.globalComplexity;
            }
            
            totalCells = Math.floor(totalCells); // Ensure it's an integer
            
            slider.max = totalCells;
            maxFill.textContent = totalCells;

            // Ensure current fill doesn't exceed new max and is valid
            let currentValue = parseInt(slider.value) || 0;
            currentValue = Math.min(currentValue, totalCells);
            slider.value = currentValue;
            currentFill.textContent = currentValue;
        }
    }

    // async loadShapeLibrary() {
    //     try {
    //       const response = await fetch('/api/shapes');
    //       this.shapeLibrary = await response.json();
    //       console.log(JSON.stringify(this.shapeLibrary));
    //       this.updateShapeLibraryUI();
    //     } catch (error) {
    //       console.error("Error loading shape library:", error);
    //     }
    // }

    async loadShapeLibrary() {
        let r = [
            {
                "_id": "66c2069d235fddb9e2a76b87",
                "name": "quarterCircle",
                "creator": "Default",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<path d='M0,0 A1,1 0 0,1 1,1 L0,1 Z' />",
                "width": 1,
                "height": 1,
                "rotations": 4
            },
            {
                "_id": "66c2069d235fddb9e2a76b88",
                "name": "square",
                "creator": "Default",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<rect width='1' height='1' />",
                "width": 1,
                "height": 1,
                "rotations": 1
            },
            {
                "_id": "66d4686b2745a778ad9988d7",
                "name": "custom_shape_1725196395353",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M0,160c0,-88.36556 71.63444,-160 160,-160c88.36556,0 160,71.63444 160,160c0,88.36556 -71.63444,160 -160,160c-88.36556,0 -160,-71.63444 -160,-160v-160h320v320h-320c0,0 0,-160 0,-160z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66d4687f2745a778ad9988d8",
                "name": "custom_shape_1725196415757",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M0,160c0,-88.36556 71.63444,-160 160,-160c88.36556,0 160,71.63444 160,160c0,88.36556 -71.63444,160 -160,160c-88.36556,0 -160,-71.63444 -160,-160z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66d493222745a778ad9988d9",
                "name": "custom_shape_1725207329999",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"100%\" height=\"100%\"><path d=\"M5 17h-5v-10h5v10zm2-10v10l9 5v-20l-9 5zm17 4h-5v2h5v-2zm-1.584-6.232l-4.332 2.5 1 1.732 4.332-2.5-1-1.732zm1 12.732l-4.332-2.5-1 1.732 4.332 2.5 1-1.732z\"/></svg>",
                "width": 1,
                "height": 1,
                "type": "uploadedSVG"
            },
            {
                "_id": "66d49d4e2745a778ad9988da",
                "name": "custom_shape_1725209934298",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M160,0l160,160l-160,120h-120z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66d54c022745a778ad9988db",
                "name": "custom_shape_1725254658875",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M160,0l160,320h-320z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66d55abd2745a778ad9988dc",
                "name": "custom_shape_1725258429696",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M120,0h80v320h-80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66d5b96a4cf8fb55b179eea9",
                "name": "custom_shape_1725282666680",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M80,160c0,-44.18278 35.81722,-80 80,-80c44.18278,0 80,35.81722 80,80c0,44.18278 -35.81722,80 -80,80c-44.18278,0 -80,-35.81722 -80,-80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66dda2c9cdf43de0377b284d",
                "name": "custom_shape_1725801161034",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M40,40h40l200,240l-240,-200z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66df03e2cdf43de0377b284e",
                "name": "custom_shape_1725891554035",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M40,160c0,-66.27417 53.72583,-120 120,-120c66.27417,0 120,53.72583 120,120c0,15.7686 -3.04145,30.82682 -8.56971,44.62005c-8.67024,-12.46273 -23.09764,-20.62005 -39.43029,-20.62005c-26.50967,0 -48,21.49033 -48,48c0,16.33264 8.15732,30.76004 20.62005,39.43029c-13.79323,5.52827 -28.85145,8.56971 -44.62005,8.56971c-66.27417,0 -120,-53.72583 -120,-120z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e08b1bcdf43de0377b284f",
                "name": "custom_shape_1725991707471",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M120,180c0,-55.22847 44.77153,-100 100,-100c55.22847,0 100,44.77153 100,100c0,55.22847 -44.77153,100 -100,100c-55.22847,0 -100,-44.77153 -100,-100z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e08b3dcdf43de0377b2850",
                "name": "custom_shape_1725991741509",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M160,240c0,-44.18278 35.81722,-80 80,-80c44.18278,0 80,35.81722 80,80c0,44.18278 -35.81722,80 -80,80c-44.18278,0 -80,-35.81722 -80,-80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e2c0db9059b7457fcda094",
                "name": "custom_shape_1726136539535",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M240,180c0,-11.04569 8.95431,-20 20,-20c11.04569,0 20,8.95431 20,20c0,11.04569 -8.95431,20 -20,20c-11.04569,0 -20,-8.95431 -20,-20z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e3ca759059b7457fcda095",
                "name": "custom_shape_1726204533239",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n            <g xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"none\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"><path d=\"M0,320v-320h320v320z\" fill=\"none\" stroke-width=\"1\"/><path d=\"M40,80c0,-22.09139 17.90861,-40 40,-40c22.09139,0 40,17.90861 40,40c0,22.09139 -17.90861,40 -40,40c-22.09139,0 -40,-17.90861 -40,-40z\" fill=\"#000000\" stroke-width=\"0\"/></g>\n        </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e3d03d9059b7457fcda096",
                "name": "custom_shape_1726206013610",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M160,80c0,-44.18278 35.81722,-80 80,-80c44.18278,0 80,35.81722 80,80c0,44.18278 -35.81722,80 -80,80c-44.18278,0 -80,-35.81722 -80,-80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e3d2cd9059b7457fcda097",
                "name": "custom_shape_1726206669836",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M40,160c0,-66.27417 53.72583,-120 120,-120c66.27417,0 120,53.72583 120,120c0,66.27417 -53.72583,120 -120,120c-66.27417,0 -120,-53.72583 -120,-120z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e3d31f9059b7457fcda098",
                "name": "custom_shape_1726206751151",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M40,40h120l-80,40l-40,80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            },
            {
                "_id": "66e3d4279059b7457fcda099",
                "name": "custom_shape_1726207015059",
                "creator": "User",
                "profileImage": "",
                "lottie": "",
                "gif": "",
                "svg": "<svg class=\"paperjs\" viewBox=\"0 0 320 320\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path xmlns=\"http://www.w3.org/2000/svg\" d=\"M200,280l-40,-120l120,40v80z\" fill=\"#000000\" fill-rule=\"nonzero\" stroke=\"none\" stroke-width=\"0\" stroke-linecap=\"butt\" stroke-linejoin=\"miter\" stroke-miterlimit=\"10\" stroke-dasharray=\"\" stroke-dashoffset=\"0\" font-family=\"none\" font-weight=\"none\" font-size=\"none\" text-anchor=\"none\" style=\"mix-blend-mode: normal\"/>\n            </svg>",
                "width": 1,
                "height": 1,
                "type": "paperjs"
            }
        ];
        
        this.shapeLibrary = r;
        this.updateShapeLibraryUI();
    }

    // async loadShapeLibrary() {
    //     let r = [
    //         {
    //             "_id": "66c2069d235fddb9e2a76b87",
    //             "name": "quarterCircle",
    //             "creator": "Default",
    //             "profileImage": "",
    //             "lottie": "",
    //             "gif": "",
    //             "svg": "<path d='M0,0 A1,1 0 0,1 1,1 L0,1 Z' />",
    //             "width": 1,
    //             "height": 1,
    //             "rotations": 4
    //         },
    //         {
    //             "_id": "66c2069d235fddb9e2a76b88",
    //             "name": "square",
    //             "creator": "Default",
    //             "profileImage": "",
    //             "lottie": "",
    //             "gif": "",
    //             "svg": "<rect width='0.1' height='0.1' />",
    //             "width": 1,
    //             "height": 1,
    //             "rotations": 1
    //         },
    //     ];
        
    //     this.shapeLibrary = r;
    //     this.updateShapeLibraryUI();
    // }


    updateShapeLibraryUI() {
        const libraryContainer = document.getElementById('shapeLibrary');
        libraryContainer.innerHTML = '';
        this.shapeLibrary.forEach((shape, index) => {
            const shapeElement = document.createElement('div');
            shapeElement.className = 'library-shape';
            
            if (shape.svg) {
                const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svgElement.setAttribute('viewBox', `0 0 ${shape.width} ${shape.height}`);
                svgElement.style.width = '100%';
                svgElement.style.height = '100%';
                svgElement.innerHTML = shape.svg;
                shapeElement.appendChild(svgElement);
            } else if (shape.lottie) {
                const lottieContainer = document.createElement('div');
                lottieContainer.style.width = '100%';
                lottieContainer.style.height = '100%';
                shapeElement.appendChild(lottieContainer);
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: JSON.parse(shape.lottie)
                });
            } else if (shape.gif) {
                const img = document.createElement('img');
                img.src = shape.gif;  // This is already a base64 data URL
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.objectFit = 'contain';
                shapeElement.appendChild(img);
            }
            
            const typeText = document.createElement('div');
            typeText.textContent = shape.type;
            typeText.style.position = 'absolute';
            typeText.style.bottom = '0';
            typeText.style.left = '0';
            typeText.style.fontSize = '10px';
            typeText.style.backgroundColor = 'rgba(255,255,255,0.7)';
            //shapeElement.appendChild(typeText);



            shapeElement.onclick = () => {
                this.togglePickedShape(shapeElement);
                this.addShapeToToolbar(shape);
            };
            libraryContainer.appendChild(shapeElement);
        });
    }

    togglePickedShape(shapeElement) {
        document.querySelectorAll('.library-shape').forEach(btn => {
            btn.classList.remove('picked');
        });
        shapeElement.classList.toggle('picked');
    }

    addShapeToToolbar(shape) {
       // const shapeName = `library_shape_${Date.now()}`;
        const shapeName = `${shape.name}`;
        
        // Directly add the shape to shapeDefinitions
        if (!shapeDefinitions[shapeName]) {
            shapeDefinitions[shapeName] = {
                name: shape.name,
                svg: shape.svg ? shape.svg : '',
                lottie: shape.lottie ? JSON.parse(shape.lottie) : '',
                gif: shape.gif ? shape.gif : '',
                width: shape.width || 1,
                height: shape.height || 1,
                rotations: shape.rotations || 1,
                type: shape.type
            };
        }

        // Recreate the toolbar to include the new shape
        this.createToolbar();

        // Select the new shape
        this.selectShape(shapeName);
    }

    async saveShapeToLibrary(shapeData) {
        try {
            const response = await fetch('/api/shapes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(shapeData),
            });

            if (response.ok) {
                console.log('Shape saved successfully');
                await this.loadShapeLibrary();
            } else {
                console.error("Error saving shape to library");
            }
        } catch (error) {
            console.error("Error saving shape to library:", error);
        }
    }

    handleZoomChange(newZoom) {
        
        let newComplexity = this.globalComplexity;
        for (let i = 0; i < this.zoomThresholds.length; i++) {
            if (newZoom >= this.zoomThresholds[i]) {
                newComplexity = this.complexityValues[i];
            } else {
                break;
            }
        }
        
        if (newComplexity !== this.globalComplexity) {
            this.setGlobalComplexity(newComplexity);
            const complexitySlider = document.querySelector('.global-complexity-slider input');
            if (complexitySlider) {
                complexitySlider.value = this.complexityValues.indexOf(newComplexity).toString();
            }
            const valueDisplay = document.querySelector('.global-complexity-slider span');
            if (valueDisplay) {
                valueDisplay.textContent = newComplexity;
            }
        }
    }

    // addPatternGeneratorEventListeners() {
    //     document.getElementById('togglePatternGenerator').addEventListener('click', () => this.togglePatternGenerator());
    //     document.getElementById('generatePattern').addEventListener('click', () => this.generatePattern());
    //     document.getElementById('manipulatePattern').addEventListener('click', () => this.manipulatePattern());
    //     document.getElementById('patternType').addEventListener('change', () => this.updatePatternUI());
    //     document.getElementById('patternAlgorithm').addEventListener('change', () => this.updateAlgorithmParams());
    //     document.getElementById('animatePattern').addEventListener('click', () => this.animatePattern());
    // }

    // populateAnimationSelect() {
    //     const select = document.getElementById('patternAlgorithm');
    //     Object.keys(animationPresets).forEach((animationName, index) => {
    //         const option = document.createElement('option');
    //         option.value = index;
    //         option.textContent = this.formatAlgorithmName(animationName);
    //         select.appendChild(option);
    //     });
    // }

    // togglePatternGenerator() {
    //     this.isDrawerOpen = !this.isDrawerOpen;
    //     const drawer = document.getElementById('patternGeneratorDrawer');
    //     drawer.style.transform = this.isDrawerOpen ? 'translateX(0)' : 'translateX(100%)';
        
    //     if (this.isDrawerOpen && !this.isPatternDrawerInitialized) {
    //         this.initializePatternUI();
    //         this.isPatternDrawerInitialized = true;
    //     }
    //     this.updateShapeSelectionUI();
    // }

    // updatePatternUI() {
    //     const patternType = document.getElementById('patternType').value;
    //     const algorithmSelect = document.getElementById('patternAlgorithm');
    //     const generateButton = document.getElementById('generatePattern');
    //     const manipulateButton = document.getElementById('manipulatePattern');
    //     const animateButton = document.getElementById('animatePattern');

    //     algorithmSelect.innerHTML = ''; // Clear existing options

    //     if (patternType === 'generator') {
    //         this.populateGeneratorAlgorithms(algorithmSelect);
    //         generateButton.style.display = 'block';
    //         manipulateButton.style.display = 'none';
    //         animateButton.style.display = 'none';
    //     } else if (patternType === 'animator') {
    //         this.populateAnimatorOptions(algorithmSelect);
    //         generateButton.style.display = 'none';
    //         manipulateButton.style.display = 'none';
    //         animateButton.style.display = 'block';
    //     } else {
    //         this.populateManipulatorAlgorithms(algorithmSelect);
    //         generateButton.style.display = 'none';
    //         manipulateButton.style.display = 'block';
    //         animateButton.style.display = 'none';
    //     }

    //     // Ensure an option is selected
    //     if (algorithmSelect.options.length > 0) {
    //         algorithmSelect.selectedIndex = 0;
    //     }

    //     this.updateAlgorithmParams();
    // }

    // populateGeneratorAlgorithms(select) {
    //     const algorithms = ['randomFill', 'cellularAutomata', 'recursiveDivision', 'concentricShapes', 'diagonalStripes', 'checkerboard', 'spiral', 'radialBurst', 'wave', 'fractalTree'];
    //     algorithms.forEach(algo => {
    //       const option = document.createElement('option');
    //       option.value = algo;
    //       option.textContent = this.formatAlgorithmName(algo);
    //       select.appendChild(option);
    //     });
    // }

    // populateManipulatorAlgorithms(select) {
    //     const algorithms = [
    //         'rotateAll',
    //         'rotateAllRowByRow',
    //         'rotateAllColumnByColumn',
    //         'rotateShuffle',
    //         'rotateDiagonally',
    //         'rotateSequenceFromCorner',
    //         'rotateSequenceToCorner',
    //         'rotateSequenceDiagonally',
    //         'rotateSequenceHorizontal',
    //         'rotateSequenceVertical',
    //         'rotateSequenceSpiralInward',
    //         'rotateSequenceSpiralOutward',
    //         'mirror'
    //     ];
        
    //     algorithms.forEach(algo => {
    //         const option = document.createElement('option');
    //         option.value = algo;
    //         option.textContent = this.formatAlgorithmName(algo);
    //         select.appendChild(option);
    //     });
    // }

    // populateAnimatorOptions(select) {
    //     const animations = Object.keys(animationPresets);
    //     animations.forEach(animation => {
    //       const option = document.createElement('option');
    //       option.value = animation;
    //       option.textContent = this.formatAlgorithmName(animation);
    //       select.appendChild(option);
    //     });
    //   }

    // formatAlgorithmName(name) {
    //     return name.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    // }

    // openPatternGeneratorModal() {
    //   const modal = document.getElementById('patternGeneratorModal');
    //   const algorithmSelect = document.getElementById('patternAlgorithm');
    //   const paramsContainer = document.getElementById('algorithmParams');
    //   const applyButton = document.getElementById('applyPattern');

    //   modal.style.display = 'block';

    //   const updateParams = () => {
    //     paramsContainer.innerHTML = '';
    //     const algorithm = algorithmSelect.value;
    //     switch (algorithm) {
    //       case 'randomFill':
    //         paramsContainer.innerHTML = `
    //           <label>Fill Probability: <input type="number" id="fillProbability" min="0" max="1" step="0.1" value="0.5"></label>
    //         `;
    //         break;
    //       case 'cellularAutomata':
    //         paramsContainer.innerHTML = `
    //           <label>Iterations: <input type="number" id="iterations" min="1" max="10" value="5"></label>
    //           <label>Initial Fill Probability: <input type="number" id="initialFillProbability" min="0" max="1" step="0.1" value="0.4"></label>
    //         `;
    //         break;
    //       case 'recursiveDivision':
    //         paramsContainer.innerHTML = `
    //           <label>Minimum Room Size: <input type="number" id="minRoomSize" min="2" max="10" value="3"></label>
    //         `;
    //         break;
    //     }
    //   };

    //   algorithmSelect.addEventListener('change', updateParams);
    //   updateParams();

    //   applyButton.onclick = () => {
    //     const algorithm = algorithmSelect.value;
    //     const params = {};
    //     paramsContainer.querySelectorAll('input').forEach(input => {
    //       params[input.id] = parseFloat(input.value);
    //     });
    //     params.shape = this.selectedShape || 'square';
    //     this.generatePattern(algorithm, params);
    //     modal.style.display = 'none';
    //   };

    //   const closeBtn = modal.querySelector('.close');
    //   closeBtn.onclick = () => {
    //     modal.style.display = 'none';
    //   };
    // }

    // generatePattern(algorithm, params) {
    //     this.activeLayer.grid.clearAllShapes();
    //     this.patternGenerator.generatePattern(algorithm, params);
    // }

    createInitialLayer() {
        // const initialLayer = new Layer(Date.now(), 'Layer 1', this.gridSize, this.cellSize, 1, this);
        // initialLayer.create();
        // this.layers.push(initialLayer);

        this.addLayer();
        // this.setActiveLayer(initialLayer);
    }

    createGlobalComplexitySlider() {
       
        const headerContainer = document.getElementById('header');
        const sliderContainer = document.createElement('div');
        sliderContainer.className = 'global-complexity-slider';
        
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.min = '0';
        slider.max = (this.complexityValues.length - 1).toString();
        slider.step = '1';
        slider.value = this.complexityValues.indexOf(this.globalComplexity).toString();
        
        // const label = document.createElement('label');
        // label.textContent = 'Guide Density: ';
        
        const valueDisplay = document.createElement('span');
        valueDisplay.textContent = this.globalComplexity;
        
        slider.addEventListener('input', (e) => {
            const complexityValue = this.complexityValues[parseInt(e.target.value)];
            this.setGlobalComplexity(complexityValue);
            valueDisplay.textContent = complexityValue;
        });
        
        //sliderContainer.appendChild(label);
        sliderContainer.appendChild(valueDisplay);
        sliderContainer.appendChild(slider);
        
        //headerContainer.appendChild(sliderContainer);
        headerContainer.insertBefore(sliderContainer, headerContainer.firstChild);

        this.setGlobalComplexity(this.globalComplexity);
    }

    setGlobalComplexity(newComplexity) {
        if (this.globalComplexity !== newComplexity) {
            this.globalComplexity = newComplexity;
            
            if (this.layers.length === 1) {
                this.layers.forEach(layer => {
                    this.adjustLayerComplexity(layer, newComplexity);
                });
            }
            
            // Update the global slider value if it exists
            const complexitySlider = document.querySelector('.global-complexity-slider input');
            if (complexitySlider) {
                complexitySlider.value = this.complexityValues.indexOf(newComplexity).toString();
            }
            
            // Update the global value display if it exists
            const valueDisplay = document.querySelector('.global-complexity-slider span');
            if (valueDisplay) {
                valueDisplay.textContent = newComplexity;
            }

            // Update the fill slider
            this.updateFillSlider();
        }
    }

    createToolbar() {
        this.toolbar.innerHTML = '';
        
        // Add eraser button
        const eraserButton = document.createElement('button');
        eraserButton.className = 'eraser-button';
        eraserButton.innerHTML = '🧽';
        eraserButton.onclick = () => this.selectEraser(eraserButton);
        this.toolbar.appendChild(eraserButton);

        // Add shape buttons
        for (const [shapeName, shapeData] of Object.entries(shapeDefinitions)) {
            this.addShapeButton(shapeName, shapeData);
        }
    }

    createAutoScaleToggle() {
        const headerContainer = document.getElementById('header');
        const toggleContainer = document.createElement('div');
        toggleContainer.className = 'auto-scale-toggle';
        
        const toggle = document.createElement('input');
        toggle.type = 'checkbox';
        toggle.id = 'autoScaleToggle';
        
        const label = document.createElement('label');
        label.htmlFor = 'autoScaleToggle';
        label.textContent = 'Scale shapes';
        
        toggle.addEventListener('change', (e) => {
            this.autoScaleNewShapes = e.target.checked;
        });
        
        toggleContainer.appendChild(toggle);
        //toggleContainer.appendChild(label);
        
        headerContainer.appendChild(toggleContainer);
    }

    addShapeButton(shapeName, shapeData) {
        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'toolbar-button-container';
        
        const button = document.createElement('button');
        button.className = 'toolbar-button';
        button.dataset.shape = shapeName;
        
        const baseSize = this.toolbarIconSize;
        const width = shapeData.width < 1 ? baseSize : baseSize * shapeData.width;
        const height = shapeData.height < 1 ? baseSize : baseSize * shapeData.height;
        
        button.style.width = `${width}px`;
        button.style.height = `${height}px`;

        if (shapeName.startsWith('custom_shape_') || shapeData.name.startsWith('custom_shape_')) {
            if (shapeData.lottie) {
                const container = document.createElement('div');
                container.style.width = 'calc(100% - 16px)';
                container.style.height = 'calc(100% - 16px)';
                container.dataset.lottieContainer = '';
                button.appendChild(container);
                
                lottie.loadAnimation({
                    container: container,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: shapeData.lottie
                });
            } else if (shapeData.gif) {
                const img = document.createElement('img');
                img.src = shapeData.gif;
                img.style.width = 'calc(100% - 16px)';
                img.style.height = 'calc(100% - 16px)';
                img.style.objectFit = 'cover';
                button.appendChild(img);
            } else {
                button.innerHTML = shapeData.svg;
                const svgElement = button.querySelector('svg');
                if (svgElement) {
                    svgElement.setAttribute('width', (width-16) * shapeData.width);
                    svgElement.setAttribute('height', (height-16) * shapeData.height);
                }
            }
        } else {
            button.innerHTML = `<svg style="width: ${width-16}; height: ${height-16};" viewBox="0 0 ${shapeData.width} ${shapeData.height}" xmlns="http://www.w3.org/2000/svg">${shapeData.svg}</svg>`;
        }
        
        button.onclick = () => this.selectShape(shapeName, button);
        
        const removeButton = document.createElement('button');
        removeButton.className = 'remove-shape-button';
        removeButton.innerHTML = '×'; // You can use an icon here instead
        removeButton.onclick = (e) => {
            e.stopPropagation(); // Prevent triggering the main button's click event
            this.removeShapeFromToolbar(shapeName);
        };
        
        buttonContainer.appendChild(button);
        buttonContainer.appendChild(removeButton);
        this.toolbar.appendChild(buttonContainer);
    }

    removeShapeFromToolbar(shapeName) {
        // Remove the shape from shapeDefinitions if it's a custom shape
        if (shapeName.startsWith('custom_shape_')) {
            delete shapeDefinitions[shapeName];
        }
        
        // Remove the button from the toolbar
        const buttonContainer = this.toolbar.querySelector(`[data-shape="${shapeName}"]`).parentNode;
        if (buttonContainer) {
            buttonContainer.remove();
        }
        
        // If the removed shape was selected, deselect it
        if (this.selectedShape === shapeName) {
            this.selectedShape = null;
        }
    }

    initInvertColors() {
        const invertButton = document.getElementById('invertColors');
        invertButton.addEventListener('click', () => this.toggleInvertColors());
    }

    toggleInvertColors() {
         console.log("toggleInvertColors")
        this.isInverted = !this.isInverted;
        document.body.classList.toggle('inverted', this.isInverted);
        
        // Invert SVG colors
        const svgs = document.querySelectorAll('svg');
        svgs.forEach(svg => {
            const paths = svg.querySelectorAll('path, rect, circle, ellipse, line, polyline, polygon');
            paths.forEach(path => {
                if (path.getAttribute('fill') && path.getAttribute('fill') !== 'none') {
                    const currentFill = path.getAttribute('fill');
                    path.setAttribute('data-original-fill', currentFill);
                    path.setAttribute('fill', this.invertColor(currentFill));
                }
                if (path.getAttribute('stroke') && path.getAttribute('stroke') !== 'none') {
                    const currentStroke = path.getAttribute('stroke');
                    path.setAttribute('data-original-stroke', currentStroke);
                    path.setAttribute('stroke', this.invertColor(currentStroke));
                }
            });
        });
    }

    swapColors(isInverted) {
        const swapColor = (color) => {
            if (color === '#ffffff' || color === 'white') return isInverted ? '#000000' : '#ffffff';
            if (color === '#000000' || color === 'black') return isInverted ? '#ffffff' : '#000000';
            return color;
        };

        this.activeLayer.grid.shapes.forEach(shape => {
            if (shape.fill) {
                shape.fill = swapColor(shape.fill);
            }
            if (shape.stroke) {
                shape.stroke = swapColor(shape.stroke);
            }
        });

        // Update the background color of the Two.js instance
        this.activeLayer.grid.two.renderer.domElement.style.backgroundColor = isInverted ? 'black' : 'white';

        // Force an update of the Two.js scene
        this.activeLayer.grid.two.update();
    }

    invertColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            return hex;
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }

    padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }


    addEventListeners() {
        document.getElementById('addLayerBtn').addEventListener('click', () => this.addLayer());
        document.getElementById('saveBtn').addEventListener('click', () => this.saveProject());
        document.getElementById('loadBtn').addEventListener('click', () => document.getElementById('fileInput').click());
        document.getElementById('fileInput').addEventListener('change', (event) => this.loadProject(event));
        document.getElementById('clearAllCells').addEventListener('click', () => this.clearAllCells());
        document.getElementById('toggleGrid').addEventListener('click', () => this.toggleGrid());
        document.getElementById('toggleLayers').addEventListener('click', () => this.toggleLayers());
        document.getElementById('openShapeCreator').addEventListener('click', () => this.openShapeCreator());
        //document.getElementById('toggleShapeLibrary').addEventListener('click', () => this.toggleShapeLibrary());

        const closeBtn = document.querySelector('#shapeCreatorModal .close');
        if (closeBtn) {
            closeBtn.addEventListener('click', () => {
                const modal = document.getElementById('shapeCreatorModal');
                if (modal) {
                    modal.style.display = 'none';
                }
            });
        }

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {
                this.isShiftPressed = true;
                this.applyShiftHoverClass();
            }
        });

        window.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                this.isShiftPressed = false;
                this.removeShiftHoverClass();
            }
        });

        window.addEventListener('shapeAdded', (event) => {
            this.createToolbar();
            this.selectShape(event.detail);
        });

        window.addEventListener('blur', () => {
            this.isShiftPressed = false;
            this.removeShiftHoverClass();
        });

        // Add a new event listener for custom shape addition
        window.addEventListener('shapeAdded', (event) => {
            this.createToolbar();
            this.selectShape(event.detail, document.querySelector(`[data-shape="${event.detail}"]`));
        });
    }

    setActiveLayer(layer) {
        // Deselect shapes in all layers
        this.layers.forEach(layer => {
            layer.deselectAllShapes();
        });

        // Update z-index and pointer events for all layers
        this.layers.forEach(l => {
            if (l.grid.element) {
                l.grid.element.style.zIndex = l === layer ? '1' : '0';
                l.grid.element.style.pointerEvents = l === layer ? 'auto' : 'none';
                // Remove this line: l.grid.element.style.display = l === layer ? 'block' : 'none';
            }
        });
        this.activeLayer = layer;
        //this.patternGenerator = new PatternGenerator(this.activeLayer.grid, this.activeLayer.complexity);
        //this.patternAnimator = new PatternAnimator(this.activeLayer.grid, this.activeLayer);

        // Update the fill slider based on the new active layer
        this.updateFillSlider();

        // Update layer item styling
        document.querySelectorAll('.layer-item').forEach(item => {
            
            if (item.dataset.layerId == layer.id) {
                item.classList.add('layer-selected');
            } else {
                item.classList.remove('layer-selected');
            }
  
            //item.style.backgroundColor = item.dataset.layerId == layer.id ? '#b3d4fc' : '#f0f0f0';
        });
    }

    addLayer() {
        const layer = new Layer(Date.now(), `Layer ${this.layers.length + 1}`, this.gridSize, this.cellSize, this.globalComplexity, this);
        layer.order = this.layers.length;
        layer.create();
        
        // Insert the new layer at the beginning of the array
        this.layers.unshift(layer);
        
        // Update z-index for all layers
        this.updateGridZIndex();
        
        this.createLayerItem(layer);
        this.setActiveLayer(layer);
    }

    createLayerItem(layer) {
        const item = document.createElement('div');
        item.className = 'layer-item';
        item.innerHTML = `
            <span>${layer.name}</span>
            <div class="layer-controls">
                <button class="move-layer-up">↑</button>
                <button class="move-layer-down">↓</button>
                <button class="remove-layer" title="Remove Layer">Delete</button>
                <div class="offset-controls">
                    <button class="offset-button" data-direction="up">↑</button>
                    <button class="offset-button" data-direction="left">←</button>
                    <button class="offset-button" data-direction="right">→</button>
                    <button class="offset-button" data-direction="down">↓</button>
                </div>
            </div>
        `;
        item.dataset.layerId = layer.id;

        const visibilityButton = document.createElement('button');
        visibilityButton.className = 'toggle-visibility';
        visibilityButton.title = "Toggle Visibility";
        visibilityButton.textContent = layer.visible ? 'Hide' : 'Show';
        visibilityButton.addEventListener('click', (e) => {
            e.stopPropagation();
            this.toggleLayerVisibility(layer);
        });
        item.querySelector('.layer-controls').prepend(visibilityButton);

        if (!layer.visible) {
            item.classList.add('hidden-layer');
        }

        item.querySelector('.move-layer-up').addEventListener('click', (e) => {
            e.stopPropagation();
            this.moveLayer(layer, 'up');
        });

        item.querySelector('.move-layer-down').addEventListener('click', (e) => {
            e.stopPropagation();
            this.moveLayer(layer, 'down');
        });

        item.querySelector('.remove-layer').addEventListener('click', () => this.removeLayer(layer));

        item.querySelectorAll('.offset-button').forEach(button => {
            button.addEventListener('click', (e) => {
                e.stopPropagation();
                this.adjustLayerOffset(layer, e.target.dataset.direction);
            });
        });

        const complexitySlider = document.createElement('input');
        complexitySlider.type = 'range';
        complexitySlider.min = '0';
        complexitySlider.max = (this.complexityValues.length - 1).toString();
        complexitySlider.step = '1';
        complexitySlider.value = this.complexityValues.indexOf(layer.complexity).toString();
        
        const complexityDisplay = document.createElement('span');
        complexityDisplay.textContent = layer.complexity;
        
        complexitySlider.addEventListener('input', (e) => {
            const newComplexityIndex = parseInt(e.target.value);
            const newComplexity = this.complexityValues[newComplexityIndex];
            this.adjustLayerComplexity(layer, newComplexity);
            complexityDisplay.textContent = newComplexity;
            
            if (layer === this.activeLayer) {
                this.updateFillSlider();
            }
        });

        const complexityContainer = document.createElement('div');
        complexityContainer.className = 'layer-complexity-control rangeSlider';
        complexityContainer.appendChild(complexityDisplay);
        complexityContainer.appendChild(complexitySlider);

        item.querySelector('.layer-controls').appendChild(complexityContainer);

        item.addEventListener('click', (e) => {
            if (!e.target.classList.contains('remove-layer') &&
                !e.target.classList.contains('toggle-visibility') &&
                !e.target.classList.contains('offset-button') &&
                !e.target.classList.contains('move-layer-up') &&
                !e.target.classList.contains('move-layer-down')) {
                this.setActiveLayer(layer);
                this.highlightSelectedLayer(layer.id);
            }
        });

        this.layersList.insertBefore(item, this.layersList.firstChild);
    }

    moveLayer(layer, direction) {
        const currentIndex = this.layers.indexOf(layer);
        let newIndex;

        if (direction === 'up' && currentIndex > 0) {
            newIndex = currentIndex - 1;
        } else if (direction === 'down' && currentIndex < this.layers.length - 1) {
            newIndex = currentIndex + 1;
        } else {
            return; // Can't move further in this direction
        }

        // Swap layers in the array
        [this.layers[currentIndex], this.layers[newIndex]] = [this.layers[newIndex], this.layers[currentIndex]];

        // Update the DOM with animation
        const layerItems = Array.from(this.layersList.children);
        const currentItem = layerItems[currentIndex];
        const targetItem = layerItems[newIndex];

        // Add movement and highlight classes
        currentItem.classList.add(direction === 'up' ? 'layer-moving-up' : 'layer-moving-down');
        currentItem.classList.add('layer-highlight');

        // Wait for the transition to start
        requestAnimationFrame(() => {
            // Remove the movement class to trigger the animation
            currentItem.classList.remove('layer-moving-up', 'layer-moving-down');

            // Reorder the DOM elements
            if (direction === 'up') {
                this.layersList.insertBefore(currentItem, targetItem);
            } else {
                this.layersList.insertBefore(currentItem, targetItem.nextSibling);
            }

            // Remove highlight class after the animation
            setTimeout(() => {
                currentItem.classList.remove('layer-highlight');
            }, 300); // This should match the transition duration in CSS
        });

        this.updateGridZIndex();
    }

    removeLayer(layer) {
        // Call the cleanup method to remove shapes, animations, and the canvas
        layer.cleanup();

        // Remove the layer from the layers array
        this.layers = this.layers.filter(l => l.id !== layer.id);

        // Remove the layer item from the UI
        const layerItem = this.layersList.querySelector(`[data-layer-id="${layer.id}"]`);
        if (layerItem) {
            layerItem.remove();
        }

        // If the removed layer was the active layer, set a new active layer
        if (this.activeLayer === layer) {
            this.activeLayer = this.layers[0] || null;
            if (this.activeLayer) {
                this.setActiveLayer(this.activeLayer);
            }
        }

        // Update the z-index of remaining layers
        this.updateGridZIndex();
    }

    adjustLayerComplexity(layer, newComplexity) {
        layer.setComplexity(newComplexity);
        const layerItem = this.layersList.querySelector(`[data-layer-id="${layer.id}"]`);
        const complexitySlider = layerItem.querySelector('.layer-complexity-control input');
        const complexityDisplay = layerItem.querySelector('.layer-complexity-control span');
        if (complexitySlider && complexityDisplay) {
            complexitySlider.value = this.complexityValues.indexOf(newComplexity).toString();
            complexityDisplay.textContent = newComplexity;
        }
        
        // Update the fill slider if this is the active layer
        if (layer === this.activeLayer) {
            this.updateFillSlider();
        }
    }

    redrawGrid() {
        this.layers.forEach(layer => {
            layer.grid.generateSnapPoints();
            layer.grid.clearGridPoints();
            layer.grid.createGridPoints();
        });
    }

    handleCellClick(layer, cellIndex) {
        if (this.isShiftPressed || this.isErasing) {
            layer.eraseShape(cellIndex);
        } else if (this.selectedShape) {
            layer.placeShape(cellIndex, this.selectedShape);
        }
    }

    // Add these methods to handle global state
    setIsShiftPressed(value) {
        this.isShiftPressed = value;
    }

    setIsErasing(value) {
        this.isErasing = value;
    }

    setSelectedShape(shape) {
        this.selectedShape = shape;
    }

    toggleLayerVisibility(layer) {
        layer.visible = !layer.visible;
        
        // Toggle visibility of the canvas element
        if (layer.grid.two && layer.grid.two.renderer.domElement) {
            layer.grid.two.renderer.domElement.style.display = layer.visible ? 'block' : 'none';
        }
        
        // Update the layer item in the UI
        const layerItem = this.layersList.querySelector(`[data-layer-id="${layer.id}"]`);
        if (layerItem) {
            layerItem.classList.toggle('hidden-layer', !layer.visible);
            
            const visibilityButton = layerItem.querySelector('.toggle-visibility');
            if (visibilityButton) {
                visibilityButton.textContent = layer.visible ? 'Hide' : 'Show';
            }
        }
        
        // If the layer is now visible, ensure it's at the correct z-index
        if (layer.visible) {
            this.updateGridZIndex();
        }
    }

    adjustLayerOffset(layer, direction) {
        const offsetAmount = 0.25; // 25% of grid unit
        switch (direction) {
            case 'up':
                layer.updatePosition(layer.grid.offsetX, layer.grid.offsetY - offsetAmount);
                break;
            case 'down':
                layer.updatePosition(layer.grid.offsetX, layer.grid.offsetY + offsetAmount);
                break;
            case 'left':
                layer.updatePosition(layer.grid.offsetX - offsetAmount, layer.grid.offsetY);
                break;
            case 'right':
                layer.updatePosition(layer.grid.offsetX + offsetAmount, layer.grid.offsetY);
                break;
        }
    }

    selectShape(shapeName, button) {
        if (this.isDrawerOpen) {
            this.toggleMultiSelectShape(shapeName, button);
        } else {
            this.setSingleSelectedShape(shapeName, button);
        }
        this.updateShapeSelectionUI();
    }

    toggleMultiSelectShape(shapeName, button) {
        const index = this.multiSelectedShapes.indexOf(shapeName);
        if (index > -1) {
            this.multiSelectedShapes.splice(index, 1);
            button.style.backgroundColor = '';
        } else {
            this.multiSelectedShapes.push(shapeName);
            button.style.backgroundColor = 'blue';
        }
    }

    setSingleSelectedShape(shapeName, button) {
        this.selectedShape = shapeName;
        document.querySelectorAll('.toolbar-button').forEach(btn => btn.style.backgroundColor = '');
        if (button) {
            button.style.backgroundColor = 'red';
        }
    }

    updateShapeSelectionUI() {
        document.querySelectorAll('.toolbar-button').forEach(button => {
            const shapeName = button.dataset.shape;
            if (this.isDrawerOpen) {
                button.style.backgroundColor = this.multiSelectedShapes.includes(shapeName) ? 'blue' : '';
            } else {
                button.style.backgroundColor = this.selectedShape === shapeName ? 'red' : '';
            }
        });
    }

    getRandomSelectedShape() {
        if (this.isDrawerOpen && this.multiSelectedShapes.length > 0) {
            return this.multiSelectedShapes[Math.floor(Math.random() * this.multiSelectedShapes.length)];
        }
        return this.selectedShape;
    }

    updateAlgorithmParams() {
        const algorithm = document.getElementById('patternAlgorithm').value;
        const patternType = document.getElementById('patternType').value;
        const paramsContainer = document.getElementById('algorithmParams');
        paramsContainer.innerHTML = '';

        if (patternType === 'generator') {
            this.updateGeneratorParams(algorithm, paramsContainer);
        } else if (patternType === 'animator') {
            this.updateAnimatorParams(algorithm, paramsContainer);
        } else {
            this.updateManipulatorParams(algorithm, paramsContainer);
        } 
    }

    updateGeneratorParams() {
        const algorithm = document.getElementById('patternAlgorithm').value;
        const paramsContainer = document.getElementById('algorithmParams');
        paramsContainer.innerHTML = '';

        switch (algorithm) {
          case 'randomFill':
            paramsContainer.innerHTML = `
              <label>Fill Probability: <input type="number" id="fillProbability" min="0" max="1" step="0.1" value="0.5"></label>
            `;
            break;
          case 'cellularAutomata':
            paramsContainer.innerHTML = `
              <label>Iterations: <input type="number" id="iterations" min="1" max="10" value="5"></label>
              <label>Initial Fill Probability: <input type="number" id="initialFillProbability" min="0" max="1" step="0.1" value="0.4"></label>
            `;
            break;
          case 'recursiveDivision':
            paramsContainer.innerHTML = `
              <label>Minimum Room Size: <input type="number" id="minRoomSize" min="2" max="10" value="3"></label>
            `;
            break;
          case 'concentricShapes':
            paramsContainer.innerHTML = `
              <label>Rings: <input type="number" id="rings" min="1" max="20" value="5"></label>
            `;
            break;
          case 'diagonalStripes':
            paramsContainer.innerHTML = `
              <label>Stripe Width: <input type="number" id="stripeWidth" min="1" max="10" value="2"></label>
              <label>Spacing: <input type="number" id="spacing" min="1" max="10" value="4"></label>
            `;
            break;
          case 'checkerboard':
            paramsContainer.innerHTML = `
              <label>Cell Size: <input type="number" id="cellSize" min="1" max="10" value="2"></label>
            `;
            break;
          case 'spiral':
            paramsContainer.innerHTML = `
              <label>Turns: <input type="number" id="turns" min="1" max="20" value="5"></label>
            `;
            break;
          case 'radialBurst':
            paramsContainer.innerHTML = `
              <label>Rays: <input type="number" id="rays" min="4" max="36" value="12"></label>
            `;
            break;
          case 'wave':
            paramsContainer.innerHTML = `
              <label>Amplitude: <input type="number" id="amplitude" min="1" max="50" value="10"></label>
              <label>Frequency: <input type="number" id="frequency" min="0.01" max="1" step="0.01" value="0.1"></label>
            `;
            break;
          case 'fractalTree':
            paramsContainer.innerHTML = `
              <label>Depth: <input type="number" id="depth" min="1" max="10" value="7"></label>
              <label>Angle (radians): <input type="number" id="angle" min="0" max="3.14" step="0.01" value="${Math.PI / 4}"></label>
            `;
            break;
        }
    }

    updateManipulatorParams(algorithm, container) {
        switch (algorithm) {
            case 'rotateAll':
            case 'rotateAllRowByRow':
            case 'rotateAllColumnByColumn':
            case 'rotateDiagonally':
                container.innerHTML = `
                    <label>Angle: <input type="number" id="angle" min="0" max="360" value="90"></label>
                    <label>Step: <input type="number" id="step" min="1" max="90" value="1"></label>
                `;
                break;
            case 'rotateShuffle':
                container.innerHTML = `
                    <label>Min Angle: <input type="number" id="minAngle" min="0" max="360" value="0"></label>
                    <label>Max Angle: <input type="number" id="maxAngle" min="0" max="360" value="360"></label>
                    <label>Step: <input type="number" id="step" min="1" max="90" value="1"></label>
                `;
                break;
            case 'rotateSequenceFromCorner':
            case 'rotateSequenceToCorner':
            case 'rotateSequenceDiagonally':
            case 'rotateSequenceHorizontal':
            case 'rotateSequenceVertical':
            case 'rotateSequenceSpiralInward':
            case 'rotateSequenceSpiralOutward':
                container.innerHTML = `
                    <label>Start Angle: <input type="number" id="startAngle" min="0" max="360" value="0"></label>
                    <label>End Angle: <input type="number" id="endAngle" min="0" max="360" value="360"></label>
                    <label>Step: <input type="number" id="step" min="1" max="90" value="1"></label>
                    <label>Angle Step: <input type="number" id="angleStep" min="1" max="90" value="10"></label>
                `;
                break;
            /*case 'swirl':
                container.innerHTML = `
                    <label>Intensity: <input type="number" id="intensity" min="0.01" max="1" step="0.01" value="0.1"></label>
                `;
                break;
            case 'wave':
                container.innerHTML = `
                    <label>Amplitude: <input type="number" id="amplitude" min="1" max="100" value="20"></label>
                    <label>Frequency: <input type="number" id="frequency" min="0.01" max="1" step="0.01" value="0.1"></label>
                `;
                break;
            case 'explode':
            case 'implode':
                container.innerHTML = `
                    <label>Intensity: <input type="number" id="intensity" min="0.1" max="5" step="0.1" value="2"></label>
                `;
                break;
            case 'shuffle':
                container.innerHTML = `
                    <label>Iterations: <input type="number" id="iterations" min="1" max="10" value="1"></label>
                `;
                break;*/
            case 'mirror':
                container.innerHTML = `
                    <label>Axis: 
                        <select id="axis">
                            <option value="x">X</option>
                            <option value="y">Y</option>
                        </select>
                    </label>
                `;
                break;
        }
    }

    updateAnimatorParams(animation, container) {
        container.innerHTML = `
            <label>Code: <textarea id="animationEditor">${animationPresets[animation]}</textarea></label>
            <label>Trigger Type:
                <select id="triggerType">
                    <option value="simultaneous">Simultaneous</option>
                    <option value="sequential">Sequential</option>
                </select>
            </label>
            <label>Interval (ms): <input type="number" id="interval" min="0" max="5000" value="100"></label>
            <label>Order:
                <select id="order">
                    <option value="default">Default</option>
                    <option value="random">Random</option>
                    <option value="spiral">Spiral</option>
                    <option value="fromCorner">From Corner</option>
                </select>
            </label>
            <label>Loop: <input type="checkbox" id="loop" checked></label>
        `;       
    }

    setupSortMethodEditor() {
        const sortMethodSelect = document.getElementById('sortMethodSelect');
        const sortMethodEditor = document.getElementById('sortMethodEditor');
        
        this.sortMethodCodeMirror = CodeMirror(sortMethodEditor, {
            mode: "javascript",
            theme: "neo2",
            lineNumbers: false,
            autofocus: false,
            viewportMargin: Infinity,
            tabSize: 2,
            lineWrapping: true,
            value: this.getSortMethodCode('random') // Default to 'random' sort
        });

        sortMethodEditor.style.display = 'block';

        sortMethodSelect.addEventListener('change', (e) => {
            this.sortMethodCodeMirror.setValue(this.getSortMethodCode(e.target.value));
        });
    }

    getSortMethodCode(method) {
        // This method should return the code snippet from getSortedPositions in Grid class
        // You'll need to extract these code snippets from the Grid class
        switch (method) {
            case 'random':
                return "return positions.sort((a, b) => Math.random() - 0.5);";
            case 'rowByRow':
                return `return positions.sort((a, b) => {
    const aY = Math.floor(a / totalSize);
    const bY = Math.floor(b / totalSize);
    const aX = a % totalSize;
    const bX = b % totalSize;
    return aY - bY || aX - bX;
});`;
            case 'columnByColumn':
                return `return positions.sort((a, b) => {
    const aX = a % totalSize;
    const aY = Math.floor(a / totalSize);
    const bX = b % totalSize;
    const bY = Math.floor(b / totalSize);
    return aX - bX || aY - bY;
});`;
            case 'centerToEdges':
                return `const isEven = totalSize % 2 === 0;
const centerX = Math.floor(totalSize / 2);
const centerY = Math.floor(totalSize / 2);

let squarePositions = [];

const addSquare = (startX, startY, size) => {
    if (size <= 0) return;
    for (let i = 0; i < size; i++) {
        squarePositions.push(startX + i + startY * totalSize);
        squarePositions.push(startX + i + (startY + size - 1) * totalSize);
        squarePositions.push(startX + (startY + i) * totalSize);
        squarePositions.push((startX + size - 1) + (startY + i) * totalSize);
    }
};

if (isEven) {
    squarePositions.push(
        (centerX - 1) + (centerY - 1) * totalSize,
        centerX + (centerY - 1) * totalSize,
        (centerX - 1) + centerY * totalSize,
        centerX + centerY * totalSize
    );
    
    for (let i = 2; i <= centerX; i++) {
        addSquare(centerX - i, centerY - i, i * 2);
    }
} else {
    squarePositions.push(centerX + centerY * totalSize);
    
    for (let i = 1; i <= centerX; i++) {
        addSquare(centerX - i, centerY - i, i * 2 + 1);
    }
}

return [...new Set(squarePositions)].filter(p => 
    p >= 0 && p < totalSize * totalSize
);`;
            case 'edgesToCenter':
                return `const isEven = totalSize % 2 === 0;
const centerX = Math.floor(totalSize / 2);
const centerY = Math.floor(totalSize / 2);

let squarePositions = [];

const addSquare = (startX, startY, size) => {
    if (size <= 0) return;
    for (let i = 0; i < size; i++) {
        squarePositions.push(startX + i + startY * totalSize);
        squarePositions.push(startX + i + (startY + size - 1) * totalSize);
        squarePositions.push(startX + (startY + i) * totalSize);
        squarePositions.push((startX + size - 1) + (startY + i) * totalSize);
    }
};

if (isEven) {
    squarePositions.push(
        (centerX - 1) + (centerY - 1) * totalSize,
        centerX + (centerY - 1) * totalSize,
        (centerX - 1) + centerY * totalSize,
        centerX + centerY * totalSize
    );
    
    for (let i = 2; i <= centerX; i++) {
        addSquare(centerX - i, centerY - i, i * 2);
    }
} else {
    squarePositions.push(centerX + centerY * totalSize);
    
    for (let i = 1; i <= centerX; i++) {
        addSquare(centerX - i, centerY - i, i * 2 + 1);
    }
}

return [...new Set(squarePositions)].filter(p => 
    p >= 0 && p < totalSize * totalSize
).reverse();`;
            default:
                return "// Custom sorting code\nreturn positions;";
        }
    }

    setupAnimationControls() {

        // Add this new code for the Reset button
        const reloadFillButton = document.querySelector('.reloadFill');
        const rangeSliderInnerWrapper = document.querySelector('.rangeSliderInnerWrapper');
        
        reloadFillButton.addEventListener('click', () => {
            this.redrawShapes = !this.redrawShapes;
            if (this.redrawShapes) {
                reloadFillButton.classList.add('checked');
                rangeSliderInnerWrapper.classList.remove('hidden');
                reloadFillButton.textContent = 'Reset';
            } else {
                reloadFillButton.classList.remove('checked');
                rangeSliderInnerWrapper.classList.add('hidden');
                reloadFillButton.textContent = 'Keep';
            }
        });
        const editorElement = document.getElementById('animator');
            this.codeEditor = CodeMirror(editorElement, {
                mode: "javascript",
                theme: "neo2",
                lineNumbers: false,
                autofocus: true,
                viewportMargin: Infinity,
                tabSize: 2,
                lineWrapping: true,
                extraKeys: {"Ctrl-Space": "autocomplete"},
                hintOptions: {
                    hint: this.createAutocompleteHint()
                },
                value: `// index (int)
// sortIndex (int)
// totalCells (int)
// totalShapes (int)
// numOfColumns (int)
// numOfRows (int)
// gridIndex{x, y} (int)

// Animation 1
animator.addAnimation('groupX', shape, 'fill', {
    from: '#000000',
    to: '#FFFF00',
    duration: 1000,
    options: {
        sequentialDelay: 2000,
        loopIndividual: false,
        yoyo: true,
        easing: easing.quadInOut
    }
});

// Animation 2
const scaler = 5;
animator.addAnimation('groupY', shape, 'scaleX', {
    x: ((numOfRows / 2) * scaler) - (gridIndex.x * scaler),
    y: ((numOfRows / 2) * scaler) - (gridIndex.y * scaler),
    from: 1,
    to: 1,
    duration: 1000,
    options: {
        sequentialDelay: 20,
        loopIndividual: false,
        yoyo: true,
        easing: easing.quadInOut
    }
});`
        });

         this.codeEditor.on("inputRead", (cm, change) => {
            if (change.text[0] === '.' || change.text[0] === "'" || change.text[0] === 'y') {
                cm.showHint({ completeSingle: false });
            }
        });

        const runButton = document.querySelector('.runAnimation');
        runButton.addEventListener('click', () => this.runAnimationCode());
    }

    createAutocompleteHint() {
        const easingOptions = [
            'linear', 'quadInOut', 'cubicInOut', 'quartInOut', 'sineInOut', 'expoInOut', 'circInOut', 'backInOut', 'elasticOut', 'bounceOut'
        ];

        const animationProperties = [
            'scale', 'scaleX', 'scaleY', 'rotation', 'opacity', 'fill'
        ];

        const delayOptions = [
            {
                name: 'sineWaveDelay',
                code: '100 + Math.sin(index * 0.5) * 100',
                description: 'Sine wave variation'
            },
            {
                name: 'fibonacciDelay',
                code: 'fibonacci(index % 10) * 100',
                description: 'Fibonacci sequence (modulo 10)'
            },
            {
                name: 'randomDelay',
                code: '500 + Math.random() * 1000',
                description: 'Random delay between 500ms and 1500ms'
            },
            {
                name: 'exponentialDelay',
                code: '100 * Math.pow(1.2, index)',
                description: 'Exponential increase'
            },
            {
                name: 'primeDelay',
                code: 'isPrime(index) ? 800 : 200',
                description: 'Longer delay for prime indices'
            }
        ];


        return (cm) => {
            const cursor = cm.getCursor();
            const line = cm.getLine(cursor.line);
            const tokenType = cm.getTokenTypeAt(cursor);

            let result = [];
            let start = cursor.ch;
            let end = cursor.ch;

            // Easing autocomplete
            if (line.slice(0, cursor.ch).match(/easing\.$/)) {
                result = easingOptions.map(option => ({
                    text: option,
                    displayText: option
                }));
                start = cursor.ch;
            }
            // Animation property autocomplete
            else if (line.match(/animator\.addAnimation\(.+,\s*shape,\s*['"](\w*)$/)) {
                result = animationProperties.map(prop => ({
                    text: prop,
                    displayText: prop
                }));
                start = line.lastIndexOf("'") + 1 || line.lastIndexOf('"') + 1;
            }

            // Delay option autocomplete
            const delayMatch = line.slice(0, cursor.ch).match(/\bdelay\b$/);
            if (delayMatch) {
                result = delayOptions.map(option => ({
                    text: option.name,
                    displayText: `${option.name}`,
                    className: 'delay-option-hint',
                    hint: (cm, data, completion) => {
                        const from = {line: cursor.line, ch: delayMatch.index};
                        const to = {line: cursor.line, ch: cursor.ch};
                        cm.replaceRange(option.code, from, to);
                    }
                }));
                start = delayMatch.index;
                end = cursor.ch;
            }

            return {
                list: result,
                from: CodeMirror.Pos(cursor.line, start),
                to: CodeMirror.Pos(cursor.line, end)
            };
        };
    }

    runAnimationCode() {
        const animationCode = this.codeEditor.getValue();
        const sortCode = this.sortMethodCodeMirror.getValue();

        const fillSlider = document.querySelector('.rangeSlider input');
        const fillAmount = parseInt(fillSlider.value) / parseInt(fillSlider.max);
        const runButton = document.querySelector('.runAnimation');
        const recordButton = document.getElementById('recordButton');
        runButton.classList.add('running');
        recordButton.style.display = 'inline-block';

        if (this.activeLayer) {
            this.activeLayer.grid.fillGridRandomly(animationCode, sortCode, this.sortMethods, fillAmount, this.redrawShapes);
        }
    }

    generatePattern() {
        const algorithm = document.getElementById('patternAlgorithm').value;
        const params = this.getAlgorithmParams();
        const getShapeFunc = () => this.getRandomSelectedShape();
        this.patternGenerator.generatePattern(algorithm, params, getShapeFunc);
    }

    manipulatePattern() {
        const algorithm = document.getElementById('patternAlgorithm').value;
        const params = this.getAlgorithmParams();
        this.patternManipulator.manipulatePattern(algorithm, params);
    }

    animatePattern() {
        const animationSelect = document.getElementById('patternAlgorithm');
        const animationName = animationSelect.value;
        
        if (!animationName) {
            console.error('No animation selected');
            return;
        }

        if (!animationPresets[animationName]) {
            console.error('Invalid animation selected:', animationName);
            return;
        }

        let code = document.getElementById('animationEditor').value;
    
        const params = this.getAlgorithmParams();
        this.patternAnimator.animatePattern(animationName, params, code);
    }

    getAlgorithmParams() {
        const params = {};
        document.querySelectorAll('#algorithmParams input, #algorithmParams select').forEach(input => {
            if (input.type === 'checkbox') {
                params[input.id] = input.checked;
            } else {
                params[input.id] = input.type === 'number' ? parseFloat(input.value) : input.value;
            }
        });
        return params;
    }

    selectEraser(button) {
        this.selectedShape = '';
        this.isErasing = true;
        document.querySelectorAll('.toolbar-button, .eraser-button').forEach(btn => btn.classList.remove('selected'));
        button.classList.add('selected');
    }

    toggleGrid() {
        this.showGrid = !this.showGrid;
        this.layers.forEach(layer => {
            layer.grid.toggleGrid();
        });
        this.toggleCanvasBorders();
    }

    toggleCanvasBorders() {
        const canvases = document.querySelectorAll('canvas');
        canvases.forEach(canvas => {
            if (this.showGrid) {
                canvas.style.border = '1px solid rgba(0,0,0,0.1)';
            } else {
                canvas.style.border = 'none';
            }
        });
    }

    toggleLayers(init=false) {
        if (!init) {
            this.showLayers = !this.showLayers;
        }
        const layersPanel = document.getElementById('layersPanel');
        //console.log(layersPanel)
        layersPanel.style.display = this.showLayers ? 'block' : 'block';
        this.layers.forEach(layer => {
            if (layer !== this.activeLayer) {
                //layer.grid.element.style.opacity = this.showLayers ? '1' : '0';
            }
        });
    }

    toggleShapeLibrary(init=false) {
        if (!init) {
            this.showLayers = !this.showLayers;
        }
        const shapeLibraryPanel = document.getElementById('shapeLibraryContainer');

        shapeLibraryPanel.classList.toggle('shapesLibraryVisible');

        // shapeLibraryPanel.style.togg = this.showShapeLibraryPanel ? 'block' : 'none';
    }

    deselectAllShapes() {
        this.activeLayer.deselectAllShapes();
    }

    applyShiftHoverClass() {
        document.querySelectorAll('.cell-content').forEach(cell => {
            cell.classList.add('shift-hover');
        });
    }

    removeShiftHoverClass() {
        document.querySelectorAll('.cell-content').forEach(cell => {
            cell.classList.remove('shift-hover');
        });
    }

    updateGridZIndex() {
        this.layers.forEach((layer, index) => {
            if (layer.grid.two && layer.grid.two.renderer.domElement) {
                layer.grid.two.renderer.domElement.style.zIndex = this.layers.length - index;
                layer.order = index;
            }
        });
    }

    dragStart(e) {
        e.dataTransfer.setData('text/plain', e.target.dataset.layerId);
        e.target.classList.add('dragging');
    }

    dragOver(e) {
        e.preventDefault();
    }

    drop(e) {
        e.preventDefault();
        const draggedId = e.dataTransfer.getData('text');
        const draggedEl = document.querySelector(`[data-layer-id="${draggedId}"]`);
        const dropZone = e.target.closest('.layer-item');
        
        if (dropZone && draggedEl !== dropZone) {
            const draggedIndex = Array.from(this.layersList.children).indexOf(draggedEl);
            const dropIndex = Array.from(this.layersList.children).indexOf(dropZone);
            
            if (draggedIndex < dropIndex) {
                this.layersList.insertBefore(draggedEl, dropZone.nextSibling);
            } else {
                this.layersList.insertBefore(draggedEl, dropZone);
            }

            // Reorder the layers array to match the DOM order
            const newOrder = Array.from(this.layersList.children).map(item => item.dataset.layerId);
            this.layers.sort((a, b) => newOrder.indexOf(a.id.toString()) - newOrder.indexOf(b.id.toString()));

            this.updateGridZIndex();
        }
    }

    dragEnd(e) {
        e.target.classList.remove('dragging');
    }

    openShapeCreator() {
        const modal = document.getElementById('shapeCreatorModal');
        if (modal) {
            modal.style.display = 'block';
            this.shapeCreator.init();  // Add this line
        }
    }

    

    clearProject() {
        if (this.isRecording) {
            this.stopRecording();
        }
        this.projectTitle = '';
        document.getElementById('projectTitle').value = '';
        // Clear layers and other project data...
    }

    saveProject() {
        if (this.isRecording) {
            this.stopRecording();
        }
        const projectData = {
            appVersion: this.version,
            title: this.projectTitle,
            layers: this.layers.map((layer, index) => ({
                ...layer.toJSON(),
                order: this.layers.length - index - 1
            })),
            gridSize: this.gridSize,
            cellSize: this.cellSize,
            showGrid: this.showGrid,
            showLayers: this.showLayers,
            customShapes: {},
            isInverted: this.isInverted,
            autoScaleNewShapes: this.autoScaleNewShapes,
            globalComplexity: this.globalComplexity,
            sortSelector: document.getElementById('sortMethodSelect').value,
            sortCode: this.sortMethodCodeMirror.getValue(), // Save the custom sort code
            animationCode: this.codeEditor.getValue(),
            fillAmount: document.querySelector('.rangeSlider input').value,
            redrawShapes: this.redrawShapes
        };

        // Save custom shapes
        Object.entries(shapeDefinitions).forEach(([name, data]) => {
            projectData.customShapes[name] = data;
        });

        const dataStr = JSON.stringify(projectData);
        const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        
        const exportFileDefaultName = this.projectTitle 
            ? `${this.projectTitle.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.icona`
            : 'icona_project.icona';
        
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    loadProject(event) {
        if (this.isRecording) {
            this.stopRecording();
        }
        const file = event.target.files[0];
        if (file && file.name.endsWith('.icona')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const projectData = JSON.parse(e.target.result);

                // Load project title
                this.projectTitle = projectData.title || '';
                document.getElementById('projectTitle').value = this.projectTitle;

                // Clear existing layers
                this.layers.forEach(layer => layer.cleanup());
                this.layers = [];
                this.layersList.innerHTML = '';

                // Set grid properties
                this.gridSize = projectData.gridSize;
                this.cellSize = projectData.cellSize || this.cellSize;
                this.showGrid = projectData.showGrid;
                this.showLayers = projectData.showLayers;

                // Set global complexity
                if (projectData.globalComplexity !== undefined) {
                    this.setGlobalComplexity(projectData.globalComplexity);
                    const complexitySlider = document.querySelector('.global-complexity-slider input');
                    if (complexitySlider) {
                        complexitySlider.value = this.complexityValues.indexOf(this.globalComplexity).toString();
                    }
                    const valueDisplay = document.querySelector('.global-complexity-slider span');
                    if (valueDisplay) {
                        valueDisplay.textContent = this.globalComplexity;
                    }
                }

                // Set auto-scaling option
                this.autoScaleNewShapes = projectData.autoScaleNewShapes || false;
                const autoScaleToggle = document.getElementById('autoScaleToggle');
                if (autoScaleToggle) {
                    autoScaleToggle.checked = this.autoScaleNewShapes;
                }

                // Load custom shapes
                if (projectData.customShapes) {
                    Object.entries(projectData.customShapes).forEach(([name, data]) => {
                        shapeDefinitions[name] = data;
                    });
                }

                // Sort layers by their order (descending)
                const orderedLayers = projectData.layers.sort((a, b) => b.order - a.order);

                // Recreate layers
                orderedLayers.forEach((layerData, index) => {
                    const layer = Layer.fromJSON(layerData, this.gridSize, this.cellSize, this);
                    layer.order = this.layers.length;
                    this.layers.push(layer);
                    this.createLayerItem(layer);
                    
                    // Set visibility of the layer's canvas
                    if (layer.grid && layer.grid.two && layer.grid.two.renderer.domElement) {
                        layer.grid.two.renderer.domElement.style.display = layer.visible ? 'block' : 'none';
                    }
                });

                if (this.layers.length > 0) {
                    this.setActiveLayer(this.layers[0]);
                }

                // Recreate toolbar with custom shapes
                this.createToolbar();

                // Update grid visibility
                this.toggleGrid();
                this.toggleLayers(true);

                // Set inverted state
                if (projectData.isInverted !== undefined) {
                    this.isInverted = projectData.isInverted;
                    document.body.classList.toggle('inverted', this.isInverted);
                    this.updateInvertedShapes();
                }

                // Load sort method and selector
                if (projectData.sortSelector) {
                    const sortMethodSelect = document.getElementById('sortMethodSelect');
                    if (sortMethodSelect) {
                        sortMethodSelect.value = projectData.sortSelector;
                        // Trigger the change event to update the UI if necessary
                        sortMethodSelect.dispatchEvent(new Event('change'));
                    }
                }

                // Load custom sort code
                if (projectData.sortCode) {
                    this.sortMethodCodeMirror.setValue(projectData.sortCode);
                } else if (projectData.sortSelector) {
                    // If there's no custom code but there is a selector, load the default code for that selector
                    const defaultCode = this.getSortMethodCode(projectData.sortSelector);
                    this.sortMethodCodeMirror.setValue(defaultCode);
                }

                // Load animation code
                if (projectData.animationCode) {
                    this.codeEditor.setValue(projectData.animationCode);
                }

                // Set fill amount
                if (projectData.fillAmount) {
                    const fillSlider = document.querySelector('.rangeSlider input');
                    fillSlider.value = projectData.fillAmount;
                    document.querySelector('.currentFill').textContent = projectData.fillAmount;
                }

                // Set redraw shapes option
                this.redrawShapes = projectData.redrawShapes !== undefined ? projectData.redrawShapes : true;
                const reloadFillButton = document.querySelector('.reloadFill');
                if (reloadFillButton) {
                    reloadFillButton.classList.toggle('checked', this.redrawShapes);
                    reloadFillButton.textContent = this.redrawShapes ? 'Reset' : 'Keep';
                }

                // Reset the file input
                event.target.value = '';

                // Update the z-index of all layers
                this.updateGridZIndex();

                // Update the fill slider
                this.updateFillSlider();
            };
            reader.readAsText(file);
        } else {
            alert('Please select a valid .icona file.');
        }
    }

    setVersionNumber() {
        const versionElement = document.getElementById('versionNumber');
        if (versionElement) {
            versionElement.textContent = `v${this.version}`;
        }
    }

    updateInvertedShapes() {
        const svgs = document.querySelectorAll('svg');
        svgs.forEach(svg => {
            const paths = svg.querySelectorAll('path, rect, circle, ellipse, line, polyline, polygon');
            paths.forEach(path => {
                if (path.getAttribute('fill') && path.getAttribute('fill') !== 'none') {
                    const currentFill = path.getAttribute('fill');
                    path.setAttribute('fill', this.isInverted ? this.invertColor(currentFill) : currentFill);
                }
                if (path.getAttribute('stroke') && path.getAttribute('stroke') !== 'none') {
                    const currentStroke = path.getAttribute('stroke');
                    path.setAttribute('stroke', this.isInverted ? this.invertColor(currentStroke) : currentStroke);
                }
            });
        });
    }

    highlightSelectedLayer(selectedLayerId) {
        this.layers.forEach(layer => {
            const isSelected = layer.id === selectedLayerId;
            layer.grid.shapes.forEach(shape => {
                if (shape && shape.container) {
                    if (isSelected) {
                        shape.container.classList.remove('fade-pulse');
                        void shape.container.offsetWidth;
                        shape.container.classList.add('fade-pulse');
                    } else {
                        shape.container.classList.remove('fade-pulse');
                    }
                }
            });
        });
    }

    // recording

    initRecordingFeature() {
        const recordButton = document.getElementById('recordButton');
        recordButton.addEventListener('click', () => this.toggleRecording());
    }

    toggleRecording() {
        console.log('Toggle recording called');
        if (this.isRecording) {
            this.showGrid = false;
            this.stopRecording();
            this.toggleGrid();
        } else {
            this.toggleGrid();
            this.startRecording();
        }
    }

    startRecording() {
        this.runAnimationCode();

        this.recordedChunks = [];
        const compositeCanvas = this.compositeAllLayers();
        
        // Reset the progress bar
        this.resetProgressBar();
        
        // Create a new canvas with the desired background color
        const recordCanvas = document.createElement('canvas');
        recordCanvas.width = compositeCanvas.width;
        recordCanvas.height = compositeCanvas.height;
        const ctx = recordCanvas.getContext('2d');

        // Create an inverted canvas
        const invertedCanvas = document.createElement('canvas');
        invertedCanvas.width = compositeCanvas.width;
        invertedCanvas.height = compositeCanvas.height;
        const invertedCtx = invertedCanvas.getContext('2d');

        const stream = recordCanvas.captureStream(60); // 60 FPS
        this.mediaRecorder = new MediaRecorder(stream, { 
            mimeType: 'video/webm',
            videoBitsPerSecond: 8000000 // 8 Mbps, adjust as needed
        });
        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
            }
        };
        this.mediaRecorder.start();
        this.isRecording = true;
        this.recordingStartTime = Date.now();
        this.updateRecordingTimer();

        document.getElementById('recordButton').classList.add('recording');
        document.getElementById('recordButton').textContent = '·';
        document.body.classList.add('recording-mode', document.getElementById('recordButton').classList.contains('recording'));

        // Apply inversion filter to the inverted canvas
        invertedCtx.filter = 'invert(1)';

        // Start the drawing loop
        const drawFrame = () => {
            // Composite all layers
            const updatedCompositeCanvas = this.compositeAllLayers();

            // Draw white background
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, recordCanvas.width, recordCanvas.height);
            
            // Draw the composite canvas onto our recording canvas
            ctx.drawImage(updatedCompositeCanvas, 0, 0);

            if (this.isInverted) {
                // Draw to inverted canvas
                invertedCtx.drawImage(recordCanvas, 0, 0);
                
                // Draw inverted canvas back to record canvas
                ctx.drawImage(invertedCanvas, 0, 0);
            }
            
            if (this.isRecording) {
                requestAnimationFrame(drawFrame);
            }
        };
        drawFrame();
    }

    stopRecording() {
        console.log('Stop recording called');
        if (this.mediaRecorder && this.isRecording) {
            this.mediaRecorder.stop();
            this.isRecording = false;
            clearTimeout(this.recordingTimer);
            document.getElementById('recordButton').classList.remove('recording');
            document.getElementById('recordButton').textContent = 'Rec';
            document.body.classList.remove('recording-mode');
            
            // Show loader
            document.getElementById('loaderOverlay').style.display = 'flex';
            document.querySelector('#loaderOverlay p').textContent = 'Generating video... Please wait.';
            
            // Convert to MP4 after a short delay to ensure all data is collected
            setTimeout(() => this.convertToMP4(), 100);
        }
    }

    updateRecordingTimer() {
        if (this.isRecording) {
            const currentTime = Date.now();
            this.recordingDuration = (currentTime - this.recordingStartTime) / 1000; // in seconds
            document.getElementById('recordButton').textContent = this.formatTime(this.recordingDuration);
            this.recordingTimer = setTimeout(() => this.updateRecordingTimer(), 1000);
        }
    }

    formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    createBackground() {
        const background = this.two.makeRectangle(
            this.two.width / 2, 
            this.two.height / 2,
            this.two.width,
            this.two.height
        );
        background.fill = 'pink';
        background.noStroke();
        this.two.add(background);
        this.two.update();
    }

    async convertToMP4() {
        console.log('Converting to MP4');
        const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
        console.log('WebM blob created:', blob.size, 'bytes');

        const bgColor = window.getComputedStyle(document.body).backgroundColor;

        try {
            const formData = new FormData();
            formData.append('video', blob, 'input.webm');
            formData.append('bgColor', bgColor);
            formData.append('isInverted', this.isInverted.toString());

            // Show loader
            const loaderOverlay = document.getElementById('loaderOverlay');
            const loaderText = document.getElementById('loaderText');
            const progressBar = document.getElementById('conversionProgress');
            
            loaderOverlay.style.display = 'flex';
            loaderText.textContent = 'Starting conversion...';
            progressBar.style.width = '0%';

            // Make the POST request
            const response = await fetch('/convert-to-mp4', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Set up event source for progress updates
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
           
            let videoDuration = this.recordingDuration;

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                const events = decoder.decode(value).split('\n\n');

                for (const event of events) {
                    if (event.startsWith('data: ')) {
                        const data = JSON.parse(event.slice(6));
                        if (data.type === 'progress') {
                            const timemark = this.parseTimemark(data.value.timemark);
                            if (videoDuration > 0 && timemark !== null) {
                                const percent = (timemark / videoDuration) * 100;
                                progressBar.style.width = `${Math.min(100, Math.max(0, percent))}%`;
                                loaderText.textContent = `Converting: ${Math.round(percent)}%`;
                            } else {
                                loaderText.textContent = 'Converting...';
                            }
                        } else if (data.type === 'complete') {
                            progressBar.style.width = '100%';
                            loaderText.textContent = 'Complete...';
                            this.setProgress(100);
                            await this.downloadMP4(data.outputPath, data.uniqueId, data.inputPath);
                            break;
                        } else if (data.type === 'error') {
                            throw new Error(data.message);
                        }
                    }
                }
            }

        } catch (error) {
            console.error('Error during conversion:', error);
            alert('An error occurred during video conversion. Please try again.');
        } finally {
            // Hide loader
            document.getElementById('loaderOverlay').style.display = 'none';
        }
    }

    setProgress(percent) {
        if (this.progressBar) {
            this.progressBar.style.width = `${percent}%`;
        }
    }

    resetProgressBar() {
        const progressBar = document.getElementById('conversionProgress');
        if (progressBar) {
            progressBar.style.width = '0%';
        }
        const loaderText = document.getElementById('loaderText');
        if (loaderText) {
            loaderText.textContent = 'Preparing to record...';
        }
    }

    compositeAllLayers() {
        const firstLayer = this.layers[0];
        const originalCanvas = firstLayer.grid.two.renderer.domElement;
        
        // Use the original canvas size
        const width = originalCanvas.width;
        const height = originalCanvas.height;

        // Create a canvas with the original size
        const compositeCanvas = document.createElement('canvas');
        compositeCanvas.width = width;
        compositeCanvas.height = height;
        const ctx = compositeCanvas.getContext('2d');

        // Sort layers by their order (bottom to top)
        const sortedLayers = this.layers.sort((a, b) => a.order - b.order);

        sortedLayers.forEach(layer => {
            if (layer.visible) {
                const layerCanvas = layer.grid.two.renderer.domElement;
                
                // Draw the layer (no scaling needed as all canvases should be the same size)
                ctx.drawImage(layerCanvas, 0, 0);
            }
        });

        return compositeCanvas;
    }

    // Helper method to parse timemark
    parseTimemark(timemark) {
        if (typeof timemark === 'number') {
            return timemark;
        }
        if (typeof timemark === 'string') {
            const parts = timemark.split(':');
            if (parts.length === 3) {
                const [hours, minutes, seconds] = parts.map(parseFloat);
                return (hours * 3600) + (minutes * 60) + seconds;
            }
        }
        return null;
    }

    async downloadMP4(outputPath, uniqueId, inputPath) {
        console.log('Downloading MP4', uniqueId);
        try {
            // Extract filename from the full path
            const inputFileName = inputPath.split('/').pop().split('\\').pop();
            const response = await fetch(`/download-and-cleanup/${uniqueId}/${inputFileName}`);
            if (!response.ok) throw new Error('Network response was not ok');
            
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'icona_animation.mp4';
            
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading MP4:', error);
            alert('An error occurred while downloading the video. Please try again.');
        }
    }

}