export class Animator {
    constructor(two) {
        this.two = two;
        this.animationGroups = new Map();
        this.activeAnimations = new Map();
        this.frameId = null;
        this.lastFrameTime = 0;
        this.targetFPS = 60; // Default target FPS
        this.frameInterval = 1000 / this.targetFPS;
        this.setOptimalFPS();
    }

    setOptimalFPS() {
        if (typeof window !== 'undefined' && 'requestAnimationFrame' in window) {
            if ('screen' in window && 'refreshRate' in window.screen) {
                this.targetFPS = window.screen.refreshRate || 60;
            } else {
                // Fallback method to detect refresh rate
                let lastTime = performance.now();
                let frames = 0;
                const detectRefreshRate = (time) => {
                    frames++;
                    const elapsed = time - lastTime;
                    if (elapsed >= 1000) {
                        this.targetFPS = Math.round(frames * 1000 / elapsed);
                        this.frameInterval = 1000 / this.targetFPS;
                        console.log(`Detected refresh rate: ${this.targetFPS} Hz`);
                    } else {
                        requestAnimationFrame(detectRefreshRate);
                    }
                };
                requestAnimationFrame(detectRefreshRate);
            }
        }
        this.frameInterval = 1000 / this.targetFPS;
        console.log(`Target FPS: ${this.targetFPS}, Frame Interval: ${this.frameInterval.toFixed(2)}ms`);
    }

    createGroup(groupId) {
        if (!this.animationGroups.has(groupId)) {
            this.animationGroups.set(groupId, []);
        }
        return groupId;
    }

    addAnimation(groupId, shape, property, { from, to, duration, x = 0, y = 0, options = {} }) {
        if (!this.animationGroups.has(groupId)) {
            this.createGroup(groupId);
        }

        const group = this.animationGroups.get(groupId);

        const defaultOptions = {
            easing: t => t,
            sequentialDelay: 0,
            yoyo: false,
            loopIndividual: false
        };

        if (property === 'fill' || property === 'stroke') {
            from = from || '#000000';
            to = to || '#000000';
        }

        const animOptions = { ...defaultOptions, ...options };

        group.push({
            shape,
            property: property ? property.split('.') : null,
            from,
            to,
            duration,
            relativeX: x,
            relativeY: y,
            ...animOptions,
            initialPosition: { x: shape.translation.x, y: shape.translation.y },
            initialScale: { x: shape.scale.x, y: shape.scale.y },
            startTime: performance.now()
        });

        if (!this.frameId) {
            this.startAnimationLoop();
        }

        if (!this.activeAnimations.has(groupId)) {
            this.activeAnimations.set(groupId, performance.now());
        }
    }

    startAnimationLoop() {
        let lastTime = performance.now();
        const fixedTimeStep = 1000 / 60; // 60 updates per second
        let accumulator = 0;

        const animate = (currentTime) => {
            const deltaTime = currentTime - lastTime;
            lastTime = currentTime;
            accumulator += deltaTime;

            while (accumulator >= fixedTimeStep) {
                this.updateAnimations(fixedTimeStep);
                accumulator -= fixedTimeStep;
            }

            this.two.update();
            this.frameId = requestAnimationFrame(animate);
        };

        this.frameId = requestAnimationFrame(animate);
    }

    updateAnimations(deltaTime) {
        let needsUpdate = false;

        this.animationGroups.forEach((group, groupId) => {
            if (this.activeAnimations.has(groupId)) {
                const groupStartTime = this.activeAnimations.get(groupId);
                group.forEach((anim, index) => {
                    const elapsedTime = performance.now() - groupStartTime;
                    needsUpdate = this.updateAnimationProgress(anim, index, elapsedTime, group, deltaTime) || needsUpdate;
                });
            }
        });

        return needsUpdate;
    }

    updateAnimationProgress(anim, index, elapsedTime, group, deltaTime) {
        const animStartTime = anim.startTime + (index * anim.sequentialDelay);
        if (elapsedTime < animStartTime - anim.startTime) return false;

        let localElapsedTime = elapsedTime - (animStartTime - anim.startTime);
        
        if (anim.loopIndividual) {
            localElapsedTime %= anim.duration;
        } else {
            const totalCycleTime = Math.max(group.length * anim.sequentialDelay, anim.duration);
            localElapsedTime %= totalCycleTime;
        }

        let progress = Math.min(localElapsedTime / anim.duration, 1);

        if (anim.yoyo) {
            progress = progress <= 0.5 ? progress * 2 : (1 - progress) * 2;
        }

        return this.updateAnimation(anim, progress);
    }

    updateAnimation(anim, progress) {
        const easedProgress = anim.easing(progress);
        return this.updateShapeProperty(anim, easedProgress);
    }

    updateShapeProperty(anim, progress) {
        if (!anim.property || anim.property.length === 0) return false;

        let target = anim.shape;
        for (let i = 0; i < anim.property.length - 1; i++) {
            target = target[anim.property[i]];
        }
        const prop = anim.property[anim.property.length - 1];

        let value;
        if (prop === 'fill' || prop === 'stroke') {
            value = this.interpolateColor(anim.from, anim.to, progress);
        } else if (prop === 'scale' || prop === 'scaleX' || prop === 'scaleY') {
            const start = anim.from;
            const end = typeof anim.to === 'function' ? anim.to(start) : anim.to;
            value = start + (end - start) * progress;
        } else {
            value = anim.from + (anim.to - anim.from) * progress;
        }

        if (prop === 'scale') {
            anim.shape.scale.set(anim.initialScale.x * value, anim.initialScale.y * value);
        } else if (prop === 'scaleX') {
            anim.shape.scale.x = anim.initialScale.x * value;
        } else if (prop === 'scaleY') {
            anim.shape.scale.y = anim.initialScale.y * value;
        } else if (typeof target[prop] === 'object' && target[prop].set) {
            target[prop].set(value);
        } else {
            target[prop] = value;
        }

        if (anim.relativeX !== 0) {
            anim.shape.translation.x = anim.initialPosition.x + anim.relativeX * progress;
        }
        if (anim.relativeY !== 0) {
            anim.shape.translation.y = anim.initialPosition.y + anim.relativeY * progress;
        }

        return true;
    }

    interpolateColor(color1, color2, factor) {
        if (typeof color1 === 'string' && typeof color2 === 'string') {
            const r1 = parseInt(color1.slice(1, 3), 16);
            const g1 = parseInt(color1.slice(3, 5), 16);
            const b1 = parseInt(color1.slice(5, 7), 16);
            const r2 = parseInt(color2.slice(1, 3), 16);
            const g2 = parseInt(color2.slice(3, 5), 16);
            const b2 = parseInt(color2.slice(5, 7), 16);
            
            const r = Math.round(r1 + factor * (r2 - r1));
            const g = Math.round(g1 + factor * (g2 - g1));
            const b = Math.round(b1 + factor * (b2 - b1));
            
            return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
        }
        return color1;
    }

    stop(groupId) {
        this.activeAnimations.delete(groupId);
        if (this.activeAnimations.size === 0) {
            this.stopAllAnimations();
        }
    }

    stopAllAnimations() {
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
            this.frameId = null;
        }
        this.activeAnimations.clear();
        this.animationGroups.clear();
    }
}