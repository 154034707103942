// ShapeCreator.js
import { shapeDefinitions, addCustomShape } from './ShapeDefinitions.js';
export class ShapeCreator {
    constructor(containerId, iconaApp) {
        this.container = document.getElementById(containerId);
        this.canvas = null;
        this.project = null;
        this.gridSize = 40;
        this.gridCells = 8;
        this.gridTotalSize = this.gridCells * this.gridSize;
        this.gridPoints = [];
        this.shapes = [];
        this.selectedShape = null;
        this.isDragging = false;
        this.lastMousePosition = null;
        this.currentShapeType = 'circle';
        this.currentAnchorShape = null;
        this.draggingVertex = null;
        this.selectedShapes = [];
        this.isShiftPressed = false;
        this.latestClickedShape = null;
        this.uploadedSVG = null;
        this.uploadedLottie = null;
        this.uploadedGif = null;
        this.anchors = [];
        this.iconaApp = iconaApp;
        this.createCanvas();
        this.setupPaper();
        this.createGrid();
        this.addEventListeners();
        this.createAddToToolbarButton();
        this.createUploadButton();
    }

    init() {
        this.clearCanvas();
    }

    createCanvas() {
        this.canvas = document.createElement('canvas');
        this.canvas.id = 'paperCanvas';
        this.canvas.width = this.gridTotalSize-2;
        this.canvas.height = this.gridTotalSize-2;
        this.container.appendChild(this.canvas);
    }

    setupPaper() {
        paper.setup(this.canvas);
        this.project = paper.project;
    }

    createGrid() {
        const extraCells = 10; // Number of extra cells on each side
        const totalGridSize = this.gridTotalSize + (extraCells * 2 * this.gridSize);

        for (let x = -extraCells * this.gridSize; x <= this.gridTotalSize + (extraCells * this.gridSize); x += this.gridSize) {
            for (let y = -extraCells * this.gridSize; y <= this.gridTotalSize + (extraCells * this.gridSize); y += this.gridSize) {
                this.gridPoints.push(new paper.Point(x, y));
                
                // Only create visible dots within the canvas
                if (x >= 0 && x <= this.gridTotalSize && y >= 0 && y <= this.gridTotalSize) {
                    const dot = new paper.Path.Circle({
                        center: [x, y],
                        radius: 1,
                        fillColor: 'rgba(0, 0, 0, 0.5)'
                    });
                }
            }
        }
    }

    addEventListeners() {
        this.project.view.onMouseDown = (event) => this.onMouseDown(event);
        this.project.view.onMouseDrag = (event) => this.onMouseDrag(event);
        this.project.view.onMouseUp = (event) => this.onMouseUp(event);
        this.project.view.onDoubleClick = (event) => this.onDoubleClick(event);
        window.addEventListener('keydown', (event) => this.onKeyDown(event));
        window.addEventListener('keyup', (event) => this.onKeyUp(event));
    }

    createShape(type, point) {
        let shape;
        switch (type) {
            case 'circle':
                shape = new SnapCircle(this, point, this.gridSize / 2);
                break;
            case 'rect':
                shape = new SnapRect(this, point, new paper.Size(this.gridSize, this.gridSize));
                break;
            case 'polygon':
                shape = new CustomPolygon(this, point.x, point.y);
                break;
            case 'anchor':
                shape = new AnchorShape(this, point);
                this.currentAnchorShape = shape;
                break;
            default:
                throw new Error('Invalid shape type');
        }
        this.shapes.push(shape);
        return shape;
    }

    createAddToToolbarButton() {
        const button = document.createElement('button');
        button.textContent = 'Save';
        button.className = 'header-icon text';
        button.onclick = () => this.addShapeToToolbar();
        this.container.appendChild(button);
    }

    createUploadButton() {
        const button = document.createElement('button');
        button.textContent = 'Upload';
         button.className = 'header-icon text';
        button.onclick = () => this.uploadFile();
        this.container.appendChild(button);
    }

    uploadFile() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.svg,.json,.gif';  // Include .gif
        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                this.processFileUpload(file);
            }
        };
        input.click();
    }

    processFileUpload(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = e.target.result;
            if (file.name.endsWith('.svg')) {
                this.processSVGContent(fileContent);
            } else if (file.name.endsWith('.json')) {
                this.processLottieContent(fileContent);
            } else if (file.name.endsWith('.gif')) {
                this.processGifContent(fileContent);
            }
        };
        if (file.name.endsWith('.gif')) {
            reader.readAsDataURL(file);  // Read GIF as data URL
        } else {
            reader.readAsText(file);
        }
    }

    processSVGContent(svgContent) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");
        const svgElement = svgDoc.documentElement;

        // Get the viewBox
        let viewBox = svgElement.getAttribute('viewBox');
        if (!viewBox) {
            // If no viewBox, use the width and height
            const width = svgElement.getAttribute('width') || '100';
            const height = svgElement.getAttribute('height') || '100';
            viewBox = `0 0 ${width} ${height}`;
        }

        // Remove width and height attributes
        svgElement.removeAttribute('width');
        svgElement.removeAttribute('height');

        // Set a new viewBox that's a square based on the larger dimension
        const [, , vbWidth, vbHeight] = viewBox.split(' ').map(Number);
        const maxDimension = Math.max(vbWidth, vbHeight);
        svgElement.setAttribute('viewBox', `0 0 ${maxDimension} ${maxDimension}`);

        // Set width and height to 100%
        svgElement.setAttribute('width', '100%');
        svgElement.setAttribute('height', '100%');

        this.clearCanvas();

        this.uploadedSVG = {
            content: svgElement.outerHTML,
            originalViewBox: viewBox,
            squareViewBox: `0 0 ${maxDimension} ${maxDimension}`
        };
        this.drawUploadedSVG();
    }

    processLottieContent(lottieContent) {
        try {
            const lottieData = JSON.parse(lottieContent);
            this.clearCanvas();
            this.uploadedLottie = {
                data: lottieData
            };
            this.drawUploadedLottie();
        } catch (error) {
            console.error('Error processing Lottie file:', error);
            alert('Invalid Lottie file');
        }
    }

    processGifContent(gifDataUrl) {
        this.clearCanvas();
        
        const img = new Image();
        img.onload = () => {
            this.uploadedGif = {
                dataUrl: gifDataUrl,
                width: img.width,
                height: img.height
            };
            this.drawUploadedGif();
        };
        img.src = gifDataUrl;
    }

    drawUploadedGif() {
        if (this.uploadedGif) {
            const raster = new paper.Raster(this.uploadedGif.dataUrl);
            raster.position = this.project.view.center;
            raster.fitBounds(this.project.view.bounds);
        }
    }

    drawUploadedSVG() {
        if (this.uploadedSVG) {
            // Clear existing content
            this.project.clear();

            // Create a container for the SVG
            const container = document.createElement('div');
            container.style.width = '320px';
            container.style.height = '320px';
            container.style.position = 'absolute';
            container.style.top = '40px';
            container.style.left = '40px';
            container.style.zIndex = '10';
            container.style.overflow = 'hidden';
            this.container.appendChild(container);

            // Create an SVG element
            const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svgElement.setAttribute('width', '100%');
            svgElement.setAttribute('height', '100%');
            svgElement.setAttribute('viewBox', this.uploadedSVG.squareViewBox);
            svgElement.innerHTML = this.uploadedSVG.content;
            container.appendChild(svgElement);

            // Store reference to remove later
            this.svgContainer = container;
        }
    }

    drawUploadedLottie() {
        if (this.uploadedLottie) {
            // Clear existing content
            this.project.clear();
            
            // Create a container for the Lottie animation
            const container = document.createElement('div');
            container.style.width = '320px';
            container.style.height = '320px';
            container.style.position = 'absolute';
            container.style.top = '40px';
            container.style.left = '40px';
            container.style.zIndex = '10';
            this.container.appendChild(container);

            // Load the Lottie animation
            this.lottieAnimation = lottie.loadAnimation({
                container: container,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: this.uploadedLottie.data
            });
        }
    }

    // addShapeToToolbar() {
    //     const shapeData = this.generateSVG();
    //     if (shapeData) {
    //         const shapeName = `custom_shape_${Object.keys(shapeDefinitions).length}`;
            
    //         if (this.uploadedSVG) {
    //             shapeDefinitions[shapeName] = {
    //                 svg: this.uploadedSVG.content,
    //                 width: 1,
    //                 height: 1,
    //                 rotations: 4,
    //                 type: 'uploadedSVG'
    //             };
    //         } else if (typeof shapeData === 'string') {
    //             shapeDefinitions[shapeName] = {
    //                 svg: shapeData,
    //                 width: 1,
    //                 height: 1,
    //                 rotations: 4,
    //                 type: 'paperjs'
    //             };
    //         } else if (shapeData.type === 'lottie') {
    //             shapeDefinitions[shapeName] = {
    //                 lottie: shapeData.data,
    //                 width: 1,
    //                 height: 1,
    //                 rotations: 1
    //             };
    //         } else if (shapeData.type === 'gif') {
    //             shapeDefinitions[shapeName] = {
    //                 gif: shapeData.dataUrl,
    //                 width: 1,
    //                 height: 1,
    //                 rotations: 1
    //             };
    //         }
    //         window.dispatchEvent(new CustomEvent('shapeAdded', { detail: shapeName }));
    //         const modal = document.getElementById('shapeCreatorModal');
    //         if (modal) {
    //             modal.style.display = 'none';
    //         }
            
    //         this.clearCanvas();
    //     } else {
    //         alert("Please draw a closed shape with at least 3 points or upload a valid SVG, Lottie animation, or GIF.");
    //     }
    // }

    // generateSVG() {
    //     this.deselectAll();

    //     if (this.uploadedLottie) {
    //         return {
    //             type: 'lottie',
    //             data: this.uploadedLottie.data
    //         };
    //     } else if (this.uploadedGif) {
    //         return {
    //             type: 'gif',
    //             dataUrl: this.uploadedGif.dataUrl
    //         };
    //     } else if (this.uploadedSVG) {
    //         return {
    //             type: 'svg',
    //             dataUrl: this.uploadedSVG.dataUrl
    //         };
    //     } else {
    //         if (this.shapes.length === 0) return null;

    //         // Use the full grid size for the SVG viewBox
    //         const svgSize = this.gridCells;

    //         // Start with the first shape
    //         let combinedPath;
    //         if (this.shapes[0] instanceof CompoundShape) {
    //             combinedPath = this.shapes[0].resultShape.clone();
    //         } else {
    //             combinedPath = this.shapes[0].shape.clone();
    //         }

    //         // Combine all shapes into a single path
    //         for (let i = 1; i < this.shapes.length; i++) {
    //             const shape = this.shapes[i];
    //             let shapePath;
    //             if (shape instanceof CompoundShape) {
    //                 shapePath = shape.resultShape;
    //             } else {
    //                 shapePath = shape.shape;
    //             }
    //             combinedPath = combinedPath.unite(shapePath);
    //         }

    //         // Ensure the path is closed (if it's not already)
    //         if (combinedPath.closed === false) {
    //             combinedPath.closePath();
    //         }

    //         // Remove fill and stroke colors
    //         combinedPath.fillColor = "black";
    //         combinedPath.strokeColor = null;
          
            
    //         // // Export SVG with specific options
    //         const svgPath = combinedPath.exportSVG({
    //             asString: true,
    //             precision: 2,
    //             matchShapes: true
    //         });

    //         // Convert the path to an SVG string
    //         //const svgPath = combinedPath.exportSVG({asString: true});

    //         // Create the full SVG string
    //         const svgString = `<svg class="paperjs" viewBox="0 0 ${svgSize*this.gridSize} ${svgSize*this.gridSize}" xmlns="http://www.w3.org/2000/svg">
    //             ${svgPath}
    //         </svg>`;

    //         return svgString;
    //     }
    // }

    saveShapeToLibrary() {
        const visibleArea = new paper.Rectangle(0, 0, this.gridTotalSize, this.gridTotalSize);
        const shapeData = this.generateSVG(visibleArea);

        if (shapeData) {
            let newShapeData = {
                name: `custom_shape_${Date.now()}`,
                creator: 'User',
                profileImage: '',
                lottie: '',
                gif: '',
                svg: '',
                width: 1,
                height: 1,
                type: ''
            };

            const MAX_SIZE = 40 * 1024 * 1024; // 40MB max size

            if (this.uploadedSVG) {
                newShapeData.svg = this.uploadedSVG.content;
                newShapeData.type = 'uploadedSVG';
            } else if (typeof shapeData === 'string') {
                newShapeData.svg = shapeData;
                newShapeData.type = 'paperjs';
            } else if (shapeData.type === 'lottie') {
                const lottieString = JSON.stringify(shapeData.data);
                if (lottieString.length > MAX_SIZE) {
                    alert('Lottie animation is too large. Please use a smaller file.');
                    return;
                }
                newShapeData.lottie = lottieString;
                newShapeData.type = 'lottie';
            } else if (shapeData.type === 'gif') {
                if (shapeData.dataUrl.length > MAX_SIZE) {
                    alert('GIF is too large. Please use a smaller file.');
                    return;
                }
                newShapeData.gif = shapeData.dataUrl;
                newShapeData.type = 'gif';
            }

            if (this.iconaApp && typeof this.iconaApp.saveShapeToLibrary === 'function') {
                this.iconaApp.saveShapeToLibrary(newShapeData);
            } else {
                console.error('IconaApp instance or saveShapeToLibrary method not available');
            }
        }
    }

    addShapeToToolbar() {
        const visibleArea = new paper.Rectangle(0, 0, this.gridTotalSize, this.gridTotalSize);
        const shapeData = this.generateSVG(visibleArea);

        if (shapeData) {
            const shapeName = `custom_shape_${Object.keys(shapeDefinitions).length}`;
            
            if (this.uploadedSVG) {
                shapeDefinitions[shapeName] = {
                    svg: this.uploadedSVG.content,
                    width: 1,
                    height: 1,
                    rotations: 4,
                    type: 'uploadedSVG'
                };
            } else if (typeof shapeData === 'string') {
                shapeDefinitions[shapeName] = {
                    svg: shapeData,
                    width: 1,
                    height: 1,
                    rotations: 4,
                    type: 'paperjs'
                };
            } else if (shapeData.type === 'lottie') {
                shapeDefinitions[shapeName] = {
                    lottie: shapeData.data,
                    width: 1,
                    height: 1,
                    rotations: 1
                };
            } else if (shapeData.type === 'gif') {
                shapeDefinitions[shapeName] = {
                    gif: shapeData.dataUrl,
                    width: 1,
                    height: 1,
                    rotations: 1
                };
            }

           

            window.dispatchEvent(new CustomEvent('shapeAdded', { detail: shapeName }));
            const modal = document.getElementById('shapeCreatorModal');
            if (modal) {
                modal.style.display = 'none';
            }
            
             // Call the new method to save to library
            this.saveShapeToLibrary();

            this.clearCanvas();

            
        } else {
            alert("Please draw a closed shape with at least 3 points or upload a valid SVG, Lottie animation, or GIF.");
        }
    }

    generateSVG(visibleArea) {
        this.deselectAll();

        if (this.uploadedLottie) {
                return {
                    type: 'lottie',
                    data: this.uploadedLottie.data
                };
        } else if (this.uploadedGif) {
            return {
                type: 'gif',
                dataUrl: this.uploadedGif.dataUrl
            };
        } else if (this.uploadedSVG) {
            return {
                type: 'svg',
                dataUrl: this.uploadedSVG.dataUrl
            };
        } else {
            if (this.shapes.length === 0) return null;

            // Clip all shapes to the visible area
            const clippedShapes = this.shapes.map(shape => {
                if (shape instanceof CompoundShape) {
                    return shape.resultShape.intersect(new paper.Path.Rectangle(visibleArea));
                } else {
                    return shape.shape.intersect(new paper.Path.Rectangle(visibleArea));
                }
            }).filter(shape => !shape.isEmpty());

            if (clippedShapes.length === 0) {
                return null;
            }

            // Combine all clipped shapes
            let combinedPath = clippedShapes[0];
            for (let i = 1; i < clippedShapes.length; i++) {
                combinedPath = combinedPath.unite(clippedShapes[i]);
            }

            // Ensure the path is closed
            if (!combinedPath.closed) {
                combinedPath.closePath();
            }

            // Remove fill and stroke colors
            combinedPath.fillColor = "black";
            combinedPath.strokeColor = null;

            // Generate SVG
            const svgPath = combinedPath.exportSVG({asString: true});

            // Create the full SVG string
            const svgString = `<svg class="paperjs" viewBox="0 0 ${this.gridTotalSize} ${this.gridTotalSize}" xmlns="http://www.w3.org/2000/svg">
                ${svgPath}
            </svg>`;

            return svgString;
        }
    }

    findClosestGridPoint(point) {
        return this.gridPoints.reduce((closest, gridPoint) => {
            return point.getDistance(gridPoint) < point.getDistance(closest) ? gridPoint : closest;
        });
    }

    onMouseDown(event) {
        this.lastMousePosition = event.point;

        // First, check for anchor or vertex selection
        if (this.selectedShape) {
            let clickedAnchorOrVertex = this.checkForAnchorOrVertex(this.selectedShape, event.point);
            if (clickedAnchorOrVertex) {
                this.draggingVertex = clickedAnchorOrVertex;
                this.isDragging = true;
                return;
            }
        }

        if (this.currentShapeType === 'anchor') {
            this.handleAnchorMode(event);
            return;
        }

        // If no anchor/vertex selected, check for shape selection
        const clickedShape = this.shapes.find(shape => {
            if (shape instanceof CompoundShape) {
                return shape.containsPoint(event.point);
            } else {
                return shape.shape.contains(event.point) && !shape.shape.data.isBackground;
            }
        });
        
        if (clickedShape) {
            this.handleShapeSelection(clickedShape);
        } else {
            this.deselectAll();
        }
    }

    checkForAnchorOrVertex(shape, point) {
        if (shape instanceof CustomPolygon || shape instanceof AnchorShape) {
            const clickedVertexIndex = shape.containsVertex(point);
            if (clickedVertexIndex !== -1) {
                shape.selectVertex(clickedVertexIndex);
                return { shape: shape, index: clickedVertexIndex };
            } else {
                shape.deselectVertex();
            }
        } else if (shape instanceof SnapRect || shape instanceof SnapCircle || shape instanceof CompoundShape) {
            const clickedAnchorIndex = shape.containsAnchor(point);
            if (clickedAnchorIndex !== -1) {
                return { shape: shape, index: clickedAnchorIndex };
            }
        }
        return null;
    }

    handleAnchorMode(event) {
        if (!this.currentAnchorShape || this.currentAnchorShape.isCompleted) {
            // If there's a selected shape, add a new anchor to it
            if (this.selectedShape && this.selectedShape instanceof AnchorShape) {
                this.selectedShape.addPoint(event.point);
            } else if (!this.selectedShapes.length) {
                // If no shapes are selected, start a new anchor shape
                this.currentAnchorShape = this.createShape('anchor', event.point);
            } else {
                // If a non-anchor shape is selected or we're clicking outside, just deselect
                const clickedShape = this.shapes.find(shape => shape.containsPoint(event.point));
                if (clickedShape) {
                    this.handleShapeSelection(clickedShape);
                } else {
                    this.deselectAll();
                }
            }
        } else if (this.currentAnchorShape.isDrawing) {
            // If we're in the middle of drawing an anchor
            this.currentAnchorShape.addPoint(event.point);
            if (this.currentAnchorShape.isCompleted) {
                // If the shape is now complete
                this.deselectAll();
                this.selectedShapes = [this.currentAnchorShape];
                this.selectedShape = this.currentAnchorShape;
                this.currentAnchorShape.select();
                this.currentAnchorShape = null;
            }
        }
    }

    handleShapeSelection(clickedShape) {
        if (this.isShiftPressed) {
            const index = this.selectedShapes.indexOf(clickedShape);
            if (index === -1) {
                // Add the clicked shape to selection
                this.selectedShapes.push(clickedShape);
                clickedShape.select();
            } else {
                // Remove the clicked shape from selection
                this.selectedShapes.splice(index, 1);
                clickedShape.deselect();
            }

            // Always keep the latest clicked shape on top when SHIFT is pressed
            if (this.latestClickedShape) {
                this.latestClickedShape.shape.bringToFront();
                if (this.latestClickedShape.anchorPoints) {
                    this.latestClickedShape.anchorPoints.bringToFront();
                }
            }
        } else {
            // When SHIFT is not pressed, deselect all and select only the clicked shape
            this.deselectAll();
            this.selectedShapes = [clickedShape];
            clickedShape.select();
            clickedShape.shape.bringToFront();
            if (clickedShape.anchorPoints) {
                clickedShape.anchorPoints.bringToFront();
            }
        }

        // Update the latest clicked shape
        this.latestClickedShape = clickedShape;
        this.isDragging = true;
        this.selectedShape = clickedShape;

        // Ensure the anchor points of the top shape are always on top
        this.bringTopShapeAnchorsToFront();
    }

    bringTopShapeAnchorsToFront() {
        if (this.latestClickedShape && this.latestClickedShape.anchorPoints) {
            this.latestClickedShape.anchorPoints.bringToFront();
        }
    }

    onMouseDrag(event) {
        if (this.isDragging) {
            const delta = event.point.subtract(this.lastMousePosition);
            if (this.draggingVertex) {
                if (this.selectedShape instanceof CustomPolygon || this.selectedShape instanceof AnchorShape) {
                    this.selectedShape.dragVertex(this.draggingVertex.index, delta);
                } else if (this.selectedShape instanceof SnapRect || this.selectedShape instanceof SnapCircle || this.selectedShape instanceof CompoundShape) {
                    this.selectedShape.dragAnchor(this.draggingVertex.index, delta, this.isShiftPressed);
                }
            } else {
                // Move all selected shapes
                this.selectedShapes.forEach(shape => shape.drag(delta));
            }
            this.lastMousePosition = event.point;
        }
    }

    onMouseUp(event) {
        this.isDragging = false;
        if (this.draggingVertex) {
            if (this.selectedShape instanceof CustomPolygon || this.selectedShape instanceof AnchorShape) {
                this.selectedShape.snapVerticesToGrid();
            } else if (this.selectedShape instanceof CompoundShape) {
                this.selectedShape.snapToGrid();
                //this.selectedShape.updateTransform();
            } else {
                this.selectedShape.snapToGrid();
            }
        } else {
            // Snap all selected shapes to the grid
            this.selectedShapes.forEach(shape => {
                shape.snapToGrid();  
            });
        }
        this.draggingVertex = null;
        this.project.view.update();
    }

    onDoubleClick(event) {
        if (this.selectedShape && this.selectedShape instanceof CustomPolygon) {
            this.selectedShape.addCorner(event.point);
        } else {
            const newShape = this.createShape(this.currentShapeType, event.point);
            if (this.selectedShape) {
                this.selectedShape.deselect();
            }
            this.selectedShape = newShape;
            //this.selectedShape.select();
            this.selectedShape.snapToGrid();
        }
    }

    onKeyUp(event) {
        switch (event.key) {
            case 'Shift':
                this.isShiftPressed = false;
                // When Shift is released, bring the last selected shape to front
                if (this.selectedShapes.length > 0) {
                   // this.selectedShapes[this.selectedShapes.length - 1].shape.bringToFront();
                }
                break;
        }
    }

    onKeyDown(event) {
        if (this.selectedShapes.length > 0) {
            switch (event.key) {
                case 'Backspace':
                    if (this.selectedShape instanceof CustomPolygon && this.selectedShape.selectedVertex !== -1) {
                        this.selectedShape.deleteSelectedVertex();
                    } else {
                        this.deleteSelectedShapes();
                    }
                    break;
                case 'ArrowUp':
                    this.selectedShapes.forEach(shape => shape.resize(1.1));
                    break;
                case 'ArrowDown':
                    this.selectedShapes.forEach(shape => shape.resize(0.9));
                    break;
                case 'ArrowLeft':
                    this.selectedShapes.forEach(shape => shape.rotate(-Math.PI / 12));
                    break;
                case 'ArrowRight':
                    this.selectedShapes.forEach(shape => shape.rotate(Math.PI / 12));
                    break;
                case 's':
                    this.subtractSelectedShapes();
                    break;
                case 'u':
                    this.uniteSelectedShapes();
                    break;
            }
        }
        
        switch (event.key) {
            case 'Shift':
                this.isShiftPressed = true;
                // Bring the latest clicked shape to front when Shift is pressed
                if (this.latestClickedShape) {
                    this.latestClickedShape.shape.bringToFront();
                    this.bringTopShapeAnchorsToFront();
                }
                break;
            case 'c':
                this.currentShapeType = 'circle';
                break;
            case 'r':
                this.currentShapeType = 'rect';
                break;
            case 'p':
                this.currentShapeType = 'polygon';
                break;
            case 'a':
                this.currentShapeType = 'anchor';
                this.currentAnchorShape = null; // Reset current anchor shape when switching to anchor mode
                break;
        }
        
        this.project.view.update();
    }

    deleteSelectedShapes() {
        this.selectedShapes.forEach(shape => {
            const index = this.shapes.indexOf(shape);
            if (index > -1) {
                this.shapes.splice(index, 1);
                shape.remove();
            }
        });
        this.selectedShapes = [];
        this.selectedShape = null;
    }

    deselectAll() {
        this.selectedShapes.forEach(shape => shape.deselect());
        this.selectedShapes = [];
        this.selectedShape = null;
    }

    subtractSelectedShapes() {
        if (this.selectedShapes.length < 2) {
            console.error("At least two shapes must be selected for subtraction");
            return;
        }

        try {

            this.selectedShapes.reverse();
            const compoundShape = new CompoundShape(this, this.selectedShapes, 'subtract');
            
            // Remove original shapes and their anchors
            this.selectedShapes.forEach(shape => {
                const index = this.shapes.indexOf(shape);
                if (index > -1) {
                    this.shapes.splice(index, 1);
                }
                shape.remove();  // This will also remove the shape's anchors
            });

            // Add new compound shape
            this.shapes.push(compoundShape);
            
            // Snap the new shape to the grid immediately
            compoundShape.snapToGrid();
            
            // Update selection
            this.deselectAll();
            this.selectedShapes = [compoundShape];

             // After creating the new compound shape
            compoundShape.shape.bringToFront();  // Ensure the new shape is on top
            compoundShape.select();

            // Ensure the new shape is on top
            compoundShape.shape.bringToFront();

            this.redrawCanvas();
        } catch (error) {
            console.error("Failed to create compound shape:", error);
        }
    }

    uniteSelectedShapes() {
        if (this.selectedShapes.length < 2) {
            console.error("At least two shapes must be selected for union");
            return;
        }

        try {

            this.selectedShapes.reverse();
            const compoundShape = new CompoundShape(this, this.selectedShapes, 'unite');
            
            // Remove original shapes and their anchors
            this.selectedShapes.forEach(shape => {
                const index = this.shapes.indexOf(shape);
                if (index > -1) {
                    this.shapes.splice(index, 1);
                }
                shape.remove();  // This will also remove the shape's anchors
            });

            // Add new compound shape
            this.shapes.push(compoundShape);
            
            // Snap the new shape to the grid immediately
            compoundShape.snapToGrid();
            
            // Update selection
            this.deselectAll();
            this.selectedShapes = [compoundShape];

            // After creating the new compound shape
            compoundShape.shape.bringToFront();  // Ensure the new shape is on top
            compoundShape.select();

            // Ensure the new shape is on top
            compoundShape.shape.bringToFront();

            this.redrawCanvas();
        } catch (error) {
            console.error("Failed to create compound shape:", error);
        }
    }

    clearCanvas() {
        if (this.svgContainer) {
            this.svgContainer.remove();
            this.svgContainer = null;
        }

        if (this.lottieAnimation) {
            this.lottieAnimation.destroy();
            this.lottieAnimation = null;
        }
        
        // Remove any Lottie containers
        const containers = this.container.querySelectorAll('div[style*="position: absolute"]');
        containers.forEach(container => container.remove());

        this.uploadedSVG = null;
        this.uploadedLottie = null;
        this.uploadedGif = null;

        // Clear all shapes
        this.shapes.forEach(shape => {
            shape.remove();
        });
        this.shapes = [];

        // Clear all anchors
        if (this.anchors) {
            this.anchors.forEach(anchor => {
                anchor.remove();
            });
        }
        this.anchors = [];

        // Clear the entire paper.js project
        paper.project.clear();

        // Reset selection-related properties
        this.selectedShape = null;
        this.selectedShapes = [];
        this.currentAnchorShape = null;

        // Recreate the grid
        this.createGrid();

        // Update the view
        paper.view.update();
    }

    redrawCanvas() {
        // Clear the canvas
        this.project.activeLayer.removeChildren();
        
        // Redraw the grid
        this.createGrid();
        
        // Redraw all shapes
        this.shapes.forEach(shape => {
            shape.draw();
        });

        // // Redraw all anchors
        // this.anchors.forEach(anchor => {
        //     this.project.activeLayer.addChild(anchor);
        // });
        
        this.project.view.update();
    }

    // New method to add anchors
    addAnchors(newAnchors) {
        this.anchors = this.anchors.concat(newAnchors);
    }

    // New method to remove anchors
    removeAnchors(anchorsToRemove) {
        this.anchors = this.anchors.filter(anchor => !anchorsToRemove.includes(anchor));
    }


}

class SnapShape {
    constructor(creator, paperShape) {
        this.creator = creator;
        this.shape = paperShape;
        this.isSelected = false;
    }

    snapToGrid() {
        const closestPoint = this.creator.findClosestGridPoint(this.shape.position);
        this.shape.position = closestPoint;
    }

    select() {
        this.isSelected = true;
        this.shape.strokeColor = '#009dec';
        this.shape.strokeWidth = 1;
    }

    deselect() {
        this.isSelected = false;
        this.shape.strokeColor = 'black';
        this.shape.strokeWidth = 0;
    }

    drag(delta) {
        this.shape.position = this.shape.position.add(delta);
    }

    rotate(angle) {
        this.shape.rotate(angle * (180 / Math.PI));
    }

    resize(factor) {
        this.shape.scale(factor);
    }

    containsPoint(point) {
        return this.shape.contains(point);
    }

    remove() {
        if (this.shape) {
            this.shape.remove();
        }
        if (this.anchorPoints) {
            this.anchorPoints.remove();
        }
        if (this.vertexIndicators) {
            this.vertexIndicators.remove();
        }
    }
    draw() {
        // Base implementation, to be overridden by subclasses
        console.warn('Draw method not implemented for this shape type');
    }
}


// CIRCLE


class SnapCircle extends SnapShape {
    constructor(creator, center, radius) {
        const circle = new paper.Path.Circle({
            center: center,
            radius: radius,
            fillColor: 'black'
        });
        super(creator, circle);
        this.radius = radius;
        this.anchorPoints = new paper.Group();
        this.selectedAnchor = null;
        this.updateAnchorPoints();
    }

    updateAnchorPoints() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }

        this.anchorPoints = new paper.Group();

        if (this.isSelected) {
            const corners = [
                this.shape.bounds.topLeft,
                this.shape.bounds.topRight,
                this.shape.bounds.bottomRight,
                this.shape.bounds.bottomLeft
            ];

            corners.forEach((corner, index) => {
                const anchor = new paper.Path.Circle({
                    center: corner,
                    radius: 3 / paper.view.zoom,
                    fillColor: this.selectedAnchor === index ? 'red' : '#009dec',
                    strokeColor: null
                });
                this.anchorPoints.addChild(anchor);
            });

            this.creator.addAnchors(this.anchorPoints.children);
        }
    }

    draw() {
        if (this.shape) this.shape.remove();
        this.shape = new paper.Path.Circle({
            center: this.shape.position,
            radius: this.radius,
            fillColor: this.shape.fillColor,
            strokeColor: this.isSelected ? 'blue' : null,
            strokeWidth: this.isSelected ? 2 : 0
        });
        this.updateAnchorPoints();
    }

    select() {
        super.select();
        this.updateAnchorPoints();
    }

    deselect() {
        super.deselect();
        this.selectedAnchor = null;
        this.updateAnchorPoints();
    }

    drag(delta) {
        super.drag(delta);
        this.updateAnchorPoints();
    }

    resize(factor) {
        const newScale = factor > 0 ? this.currentScale * 1.1 : this.currentScale * 0.9;
        const newRadius = this.initialRadius * newScale;
        
        // Enforce minimum size
        if (newRadius < this.creator.gridSize / 2) {
            return;
        }
        
        this.currentScale = newScale;
        this.radius = newRadius;
        this.shape.scale(factor > 0 ? 1.1 : 0.9);
        this.updateAnchorPoints();
    }

    containsAnchor(point, tolerance = 10) {
        return this.anchorPoints.children.findIndex(anchor => 
            anchor.position.getDistance(point) < tolerance
        );
    }

    dragAnchor(index, delta, isShiftPressed) {
        let newBounds;
        const currentBounds = this.shape.bounds;
        const minSize = this.creator.gridSize;
        const aspectRatio = currentBounds.width / currentBounds.height;

        if (isShiftPressed) {
            // Calculate scale factor based on the diagonal movement
            let scaleFactor;
            switch(index) {
                case 0: // Top-left
                case 1: // Top-right
                    scaleFactor = 1 - delta.y / currentBounds.height;
                    break;
                case 2: // Bottom-right
                case 3: // Bottom-left
                    scaleFactor = 1 + delta.y / currentBounds.height;
                    break;
            }

            // Ensure the scale factor doesn't make the shape too small
            scaleFactor = Math.max(scaleFactor, minSize / Math.min(currentBounds.width, currentBounds.height));

            let newWidth = currentBounds.width * scaleFactor;
            let newHeight = newWidth / aspectRatio;

            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.bottomRight.subtract(new paper.Point(newWidth, newHeight)),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.bottom - newHeight),
                        new paper.Point(currentBounds.left + newWidth, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.topLeft.add(new paper.Point(newWidth, newHeight))
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.right - newWidth, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.top + newHeight)
                    );
                    break;
            }
        } else {
            // Original behavior when SHIFT is not pressed
            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft.add(delta),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.top + delta.y),
                        new paper.Point(currentBounds.right + delta.x, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.bottomRight.add(delta)
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left + delta.x, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.bottom + delta.y)
                    );
                    break;
            }
        }

        // Ensure minimum size
        if (newBounds.width < minSize || newBounds.height < minSize) {
            return;
        }

        this.shape.bounds = newBounds;
        this.size = this.shape.bounds.size;
        this.updateAnchorPoints();
    }

    snapToGrid() {
        const topLeft = this.creator.findClosestGridPoint(this.shape.bounds.topLeft);
        const bottomRight = this.creator.findClosestGridPoint(this.shape.bounds.bottomRight);

        // Ensure minimum size
        const newSize = new paper.Size(
            Math.max(bottomRight.x - topLeft.x, this.creator.gridSize),
            Math.max(bottomRight.y - topLeft.y, this.creator.gridSize)
        );

        this.shape.bounds = new paper.Rectangle(topLeft, newSize);
        this.size = newSize;
        this.updateAnchorPoints();
    }

    snapAnchorsToGrid() {
        const center = this.shape.position;
        const topAnchor = this.creator.findClosestGridPoint(center.add(new paper.Point(0, -this.radius)));
        const rightAnchor = this.creator.findClosestGridPoint(center.add(new paper.Point(this.radius, 0)));
        const bottomAnchor = this.creator.findClosestGridPoint(center.add(new paper.Point(0, this.radius)));
        const leftAnchor = this.creator.findClosestGridPoint(center.add(new paper.Point(-this.radius, 0)));

        const newRadiusVertical = Math.max(
            center.getDistance(topAnchor),
            center.getDistance(bottomAnchor)
        );
        const newRadiusHorizontal = Math.max(
            center.getDistance(leftAnchor),
            center.getDistance(rightAnchor)
        );

        this.radius = Math.max(newRadiusVertical, newRadiusHorizontal);
        this.shape.scale(this.radius / this.shape.bounds.width * 2);
    }

    remove() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }
        super.remove();
    }
}


// RECT

class SnapRect extends SnapShape {
    constructor(creator, clickPoint, size) {
        // Calculate the top-left corner of the cell that was clicked
        const cellX = Math.floor(clickPoint.x / creator.gridSize) * creator.gridSize;
        const cellY = Math.floor(clickPoint.y / creator.gridSize) * creator.gridSize;
        
        const topLeft = new paper.Point(cellX, cellY);
        
        const rect = new paper.Path.Rectangle({
            point: topLeft,
            size: size,
            fillColor: 'black'
        });
        super(creator, rect);

        this.size = size;
        this.anchorPoints = null;
        this.selectedAnchor = null;
        this.updateAnchorPoints();
    }

    updateAnchorPoints() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }

        this.anchorPoints = new paper.Group();

        if (this.isSelected) {
            const corners = [
                this.shape.bounds.topLeft,
                this.shape.bounds.topRight,
                this.shape.bounds.bottomRight,
                this.shape.bounds.bottomLeft
            ];

            corners.forEach((corner, index) => {
                const anchor = new paper.Path.Circle({
                    center: corner,
                    radius: 3 / paper.view.zoom,
                    fillColor: this.selectedAnchor === index ? 'red' : '#009dec',
                    strokeColor: null
                });
                this.anchorPoints.addChild(anchor);
            });

            this.creator.addAnchors(this.anchorPoints.children);
        }
    }

    draw() {
        if (this.shape) this.shape.remove();
        this.shape = new paper.Path.Rectangle({
            point: this.shape.bounds.topLeft,
            size: this.size,
            fillColor: this.shape.fillColor,
            strokeColor: this.isSelected ? 'blue' : null,
            strokeWidth: this.isSelected ? 2 : 0
        });
        this.updateAnchorPoints();
    }

    select() {
        super.select();
        this.updateAnchorPoints();
    }

    deselect() {
        super.deselect();
        this.selectedAnchor = null;
        this.updateAnchorPoints();
    }

    drag(delta) {
        this.shape.position = this.shape.position.add(delta);
        this.updateAnchorPoints();
    }

    containsAnchor(point, tolerance = 10) {
        return this.anchorPoints.children.findIndex(anchor => 
            anchor.position.getDistance(point) < tolerance
        );
    }

    dragAnchor(index, delta, isShiftPressed) {
        let newBounds;
        const currentBounds = this.shape.bounds;
        const minSize = this.creator.gridSize;
        const aspectRatio = currentBounds.width / currentBounds.height;

        if (isShiftPressed) {
            // Calculate scale factor based on the diagonal movement
            let scaleFactor;
            switch(index) {
                case 0: // Top-left
                case 1: // Top-right
                    scaleFactor = 1 - delta.y / currentBounds.height;
                    break;
                case 2: // Bottom-right
                case 3: // Bottom-left
                    scaleFactor = 1 + delta.y / currentBounds.height;
                    break;
            }

            // Ensure the scale factor doesn't make the shape too small
            scaleFactor = Math.max(scaleFactor, minSize / Math.min(currentBounds.width, currentBounds.height));

            let newWidth = currentBounds.width * scaleFactor;
            let newHeight = newWidth / aspectRatio;

            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.bottomRight.subtract(new paper.Point(newWidth, newHeight)),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.bottom - newHeight),
                        new paper.Point(currentBounds.left + newWidth, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.topLeft.add(new paper.Point(newWidth, newHeight))
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.right - newWidth, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.top + newHeight)
                    );
                    break;
            }
        } else {
            // Original behavior when SHIFT is not pressed
            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft.add(delta),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.top + delta.y),
                        new paper.Point(currentBounds.right + delta.x, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.bottomRight.add(delta)
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left + delta.x, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.bottom + delta.y)
                    );
                    break;
            }
        }

        // Ensure minimum size
        if (newBounds.width < minSize || newBounds.height < minSize) {
            return;
        }

        this.shape.bounds = newBounds;
        this.size = this.shape.bounds.size;
        this.updateAnchorPoints();
    }

    snapToGrid() {
        const topLeft = this.creator.findClosestGridPoint(this.shape.bounds.topLeft);
        const bottomRight = this.creator.findClosestGridPoint(this.shape.bounds.bottomRight);

        // Ensure minimum size
        const newSize = new paper.Size(
            Math.max(bottomRight.x - topLeft.x, this.creator.gridSize),
            Math.max(bottomRight.y - topLeft.y, this.creator.gridSize)
        );

        this.shape.bounds = new paper.Rectangle(topLeft, newSize);
        this.size = newSize;
        this.updateAnchorPoints();
    }

    remove() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }
        super.remove();
    }
}


// POLYGON


class CustomPolygon extends SnapShape {
    constructor(creator, x, y, size = 40) {
        const path = new paper.Path({
            segments: [
                new paper.Point(x - size/2, y - size/2),
                new paper.Point(x + size/2, y - size/2),
                new paper.Point(x + size/2, y + size/2),
                new paper.Point(x - size/2, y + size/2)
            ],
            closed: true,
            fillColor: 'rgba(0, 0, 0, 1)',
            strokeColor: '#000000',
            strokeWidth: 0
        });
        super(creator, path);

        this.vertexIndicators = null;
        this.selectedVertex = -1;
        this.initialSize = size;
        this.currentScale = 1;
        this.initialStrokeWidth = this.shape.strokeWidth;

        this.updateVertexIndicators();
    }

    addCorner(point) {
        // Snap the new point to the grid immediately
        const snappedPoint = this.creator.findClosestGridPoint(point);
        
        let insertIndex = 0;
        let minDistance = Infinity;
        for (let i = 0; i < this.shape.segments.length; i++) {
            const nextIndex = (i + 1) % this.shape.segments.length;
            const edgeStart = this.shape.segments[i].point;
            const edgeEnd = this.shape.segments[nextIndex].point;
            
            const distanceToEdge = this.distanceToLineSegment(snappedPoint, edgeStart, edgeEnd);
            if (distanceToEdge < minDistance) {
                minDistance = distanceToEdge;
                insertIndex = nextIndex;
            }
        }
        
        this.shape.insert(insertIndex, new paper.Segment(snappedPoint));
        this.updateShapePosition();
        this.updateVertexIndicators();
    }

    draw() {
        if (this.shape) this.shape.remove();
        this.shape = new paper.Path({
            segments: this.shape.segments,
            closed: true,
            fillColor: this.shape.fillColor,
            strokeColor: this.isSelected ? 'blue' : null,
            strokeWidth: this.isSelected ? 2 : 0
        });
        this.updateVertexIndicators();
        this.vertexIndicators.bringToFront(); // Ensure indicators are on top after redrawing
    }

    distanceToLineSegment(point, lineStart, lineEnd) {
        const line = lineEnd.subtract(lineStart);
        const t = point.subtract(lineStart).dot(line) / line.dot(line);
        if (t < 0) return point.getDistance(lineStart);
        if (t > 1) return point.getDistance(lineEnd);
        const projection = lineStart.add(line.multiply(t));
        return point.getDistance(projection);
    }

    updateShapePosition() {
        const bounds = this.shape.bounds;
        this.shape.position = new paper.Point(
            bounds.left + bounds.width / 2,
            bounds.top + bounds.height / 2
        );
    }

    snapVerticesToGrid() {
        this.shape.segments.forEach(segment => {
            segment.point = this.creator.findClosestGridPoint(segment.point);
        });
        this.updateShapePosition();
        this.updateVertexIndicators();
    }

    drag(delta) {
        this.shape.position = this.shape.position.add(delta);
        this.updateVertexIndicators();
    }

    dragVertex(index, delta) {
        if (index >= 0 && index < this.shape.segments.length) {
            this.shape.segments[index].point = this.shape.segments[index].point.add(delta);
            this.updateShapePosition();
            this.updateVertexIndicators();
        }
    }

    containsVertex(point, tolerance = 10) {
        return this.shape.segments.findIndex(segment => 
            segment.point.getDistance(point) < tolerance
        );
    }

    updateVertexIndicators() {
        if (this.vertexIndicators) {
            this.creator.removeAnchors(this.vertexIndicators.children);
            this.vertexIndicators.remove();
        }

        this.vertexIndicators = new paper.Group();
        
        if (this.isSelected) {
            this.shape.segments.forEach((segment, index) => {
                const indicator = new paper.Path.Circle({
                    center: segment.point,
                    radius: 3 / paper.view.zoom,
                    fillColor: index === this.selectedVertex ? 'red' : '#009dec',
                    strokeColor: null
                });
                this.vertexIndicators.addChild(indicator);
            });

            this.creator.addAnchors(this.vertexIndicators.children);
        }
    }

    select() {
        super.select();
        this.shape.strokeColor = '#0000FF';
        this.shape.strokeWidth = 3 / paper.view.zoom;
        this.updateVertexIndicators();
        this.vertexIndicators.bringToFront(); // Ensure indicators are on top after selection
    }

    deselect() {
        super.deselect();
        this.shape.strokeColor = '#000000';
        this.shape.strokeWidth = 0;
        this.selectedVertex = -1;
        this.updateVertexIndicators();
    }

    containsPoint(point) {
        return this.shape.contains(point);
    }

    snapToGrid() {
        this.snapVerticesToGrid();
    }

    resize(factor) {
        const scaleFactor = factor > 0 ? 1.1 : 0.9;
        this.currentScale *= scaleFactor;

        if (this.currentScale * this.initialSize < this.creator.gridSize) {
            this.currentScale = this.creator.gridSize / this.initialSize;
        }

        const center = this.shape.position;
        this.shape.scale(scaleFactor, center);
        this.shape.strokeWidth = this.initialStrokeWidth / this.currentScale;

        this.updateVertexIndicators();
    }

    selectVertex(index) {
        this.selectedVertex = index;
        this.updateVertexIndicators();
    }

    deselectVertex() {
        this.selectedVertex = -1;
        this.updateVertexIndicators();
    }

    deleteSelectedVertex() {
        if (this.selectedVertex !== -1 && this.shape.segments.length > 3) {
            this.shape.removeSegment(this.selectedVertex);
            this.selectedVertex = -1;
            this.updateShapePosition();
            this.updateVertexIndicators();
        }
    }

    remove() {
        if (this.vertexIndicators) {
            this.creator.removeAnchors(this.vertexIndicators.children);
            this.vertexIndicators.remove();
        }
        super.remove();
    }
}


// COMPOUND 

class CompoundShape extends SnapShape {

    constructor(creator, originalShapes, operation) {
        super(creator, new paper.Group());
        
        this.creator = creator;
        this.operation = operation;
        this.originalShapes = originalShapes.map(shape => ({
            shape: shape instanceof CompoundShape ? shape.resultShape.clone() : shape.shape.clone(),
            position: shape.shape.position.clone()
        }));
        
        // Perform the operations
        this.resultShape = CompoundShape.performOperation(this.originalShapes.map(s => s.shape), operation);
        this.resultShape.fillColor = originalShapes[0].shape.fillColor;
        
        // Create the background shape
        this.createBackgroundShape();
        
        // Add both shapes to the group
        this.shape.addChild(this.backgroundShape);
        this.shape.addChild(this.resultShape);
        
        this.anchorPoints = new paper.Group();
        this.selectedAnchor = null;
        
        this.snapToGrid(); // Snap to grid on creation
        this.updateAnchorPoints();
    }

    createBackgroundShape() {
        const resultBounds = this.resultShape.bounds;
        
        this.backgroundShape = new paper.Path.Rectangle({
            rectangle: resultBounds.expand(this.creator.gridSize / 2),
            fillColor: this.resultShape.fillColor,
            opacity: 0.1
        });
        this.backgroundShape.data.isBackground = true;
    }

    

    static performOperation(shapes, operation) {
        switch (operation) {
            case 'subtract':
                return PathfinderOperations.subtract(shapes);
            case 'unite':
                return PathfinderOperations.unite(shapes);
            default:
                throw new Error("Unknown pathfinder operation");
        }
    }

    draw() {
        if (this.shape) this.shape.remove();
        this.shape = new paper.Group();

        this.shape.addChild(this.backgroundShape);
        this.shape.addChild(this.resultShape);

        // Apply selection styling
        if (this.isSelected) {
            this.resultShape.strokeColor = 'blue';
            this.resultShape.strokeWidth = 2;
        } else {
            this.resultShape.strokeColor = null;
            this.resultShape.strokeWidth = 0;
        }

        this.updateTransform();
    }

    updateTransform() {
        this.resultShape.bringToFront();
        this.updateAnchorPoints();
    }


    updateAnchorPoints() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }

        this.anchorPoints = new paper.Group();

        if (this.isSelected) {
            const bounds = this.shape.bounds;
            const corners = [
                bounds.topLeft,
                bounds.topRight,
                bounds.bottomRight,
                bounds.bottomLeft
            ];

            corners.forEach((corner, index) => {
                const anchor = new paper.Path.Circle({
                    center: corner,
                    radius: 3 / paper.view.zoom,
                    fillColor: this.selectedAnchor === index ? 'red' : '#009dec',
                    strokeColor: null
                });
                this.anchorPoints.addChild(anchor);
            });
            
            this.creator.addAnchors(this.anchorPoints.children);
        }
    }

    select() {
        this.isSelected = true;
        this.resultShape.strokeColor = 'blue';
        this.resultShape.strokeWidth = 2;
        this.backgroundShape.opacity = 0.1;
        this.shape.bringToFront();  // Bring the entire group to front
        this.resultShape.bringToFront();  // Ensure resultShape is on top within the group
        this.updateAnchorPoints();
    }

    deselect() {
        this.isSelected = false;
        this.resultShape.strokeColor = null;
        this.resultShape.strokeWidth = 0;
        this.backgroundShape.opacity = 0.0;
        this.selectedAnchor = null;
        this.updateAnchorPoints();
    }

    drag(delta) {
        this.backgroundShape.position = this.backgroundShape.position.add(delta);
        this.resultShape.position = this.resultShape.position.add(delta);
        this.updateTransform();
    }

    resize(factor) {
        this.shape.scale(factor, this.shape.bounds.center);
        this.initialPosition = this.shape.position;
        this.updateAnchorPoints();
    }

    rotate(angle) {
        this.shape.rotate(angle);
        this.initialPosition = this.shape.position;
        this.updateTransform();
    }

    snapToGrid() {
        const oldBackgroundBounds = this.backgroundShape.bounds;
        const oldResultPosition = this.resultShape.position;

        // Calculate relative position of result shape within background
        const relativeX = (oldResultPosition.x - oldBackgroundBounds.left) / oldBackgroundBounds.width;
        const relativeY = (oldResultPosition.y - oldBackgroundBounds.top) / oldBackgroundBounds.height;

        // Snap background shape to grid
        const topLeft = this.creator.findClosestGridPoint(oldBackgroundBounds.topLeft);
        const bottomRight = this.creator.findClosestGridPoint(oldBackgroundBounds.bottomRight);
        const newBackgroundBounds = new paper.Rectangle(topLeft, bottomRight);

        // Update background shape
        this.backgroundShape.bounds = newBackgroundBounds;

        // Calculate new position for result shape
        const newResultX = newBackgroundBounds.left + relativeX * newBackgroundBounds.width;
        const newResultY = newBackgroundBounds.top + relativeY * newBackgroundBounds.height;

        // Update result shape position
        this.resultShape.position = new paper.Point(newResultX, newResultY);
        this.resultShape.bounds = newBackgroundBounds;
        this.backgroundShape.bounds = newBackgroundBounds;
        this.updateTransform();
    }

    containsPoint(point) {
        return this.resultShape.contains(point);
        //return this.backgroundShape.contains(point) || this.resultShape.contains(point);
    }

    containsAnchor(point, tolerance = 10) {
        return this.anchorPoints.children.findIndex(anchor => 
            anchor.position.getDistance(point) < tolerance
        );
    }

    dragAnchor(index, delta, isShiftPressed) {
        let newBounds;
        const currentBounds = this.shape.bounds;
        const minSize = this.creator.gridSize;
        const aspectRatio = currentBounds.width / currentBounds.height;

        if (isShiftPressed) {
            // Calculate scale factor based on the diagonal movement
            let scaleFactor;
            switch(index) {
                case 0: // Top-left
                case 1: // Top-right
                    scaleFactor = 1 - delta.y / currentBounds.height;
                    break;
                case 2: // Bottom-right
                case 3: // Bottom-left
                    scaleFactor = 1 + delta.y / currentBounds.height;
                    break;
            }

            // Ensure the scale factor doesn't make the shape too small
            scaleFactor = Math.max(scaleFactor, minSize / Math.min(currentBounds.width, currentBounds.height));

            let newWidth = currentBounds.width * scaleFactor;
            let newHeight = newWidth / aspectRatio;

            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.bottomRight.subtract(new paper.Point(newWidth, newHeight)),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.bottom - newHeight),
                        new paper.Point(currentBounds.left + newWidth, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.topLeft.add(new paper.Point(newWidth, newHeight))
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.right - newWidth, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.top + newHeight)
                    );
                    break;
            }
        } else {
            // Original behavior when SHIFT is not pressed
            switch(index) {
                case 0: // Top-left
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft.add(delta),
                        currentBounds.bottomRight
                    );
                    break;
                case 1: // Top-right
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left, currentBounds.top + delta.y),
                        new paper.Point(currentBounds.right + delta.x, currentBounds.bottom)
                    );
                    break;
                case 2: // Bottom-right
                    newBounds = new paper.Rectangle(
                        currentBounds.topLeft,
                        currentBounds.bottomRight.add(delta)
                    );
                    break;
                case 3: // Bottom-left
                    newBounds = new paper.Rectangle(
                        new paper.Point(currentBounds.left + delta.x, currentBounds.top),
                        new paper.Point(currentBounds.right, currentBounds.bottom + delta.y)
                    );
                    break;
            }
        }

        // Ensure minimum size
        if (newBounds.width < minSize || newBounds.height < minSize) {
            return;
        }

        this.shape.bounds = newBounds;
        this.size = this.shape.bounds.size;
        this.resultShape.bounds = newBounds;
        this.backgroundShape.bounds = newBounds;
        this.updateAnchorPoints();
    }

    remove() {
        if (this.anchorPoints) {
            this.creator.removeAnchors(this.anchorPoints.children);
            this.anchorPoints.remove();
        }
        super.remove();
    }

}


class AnchorShape extends SnapShape {
    constructor(creator, initialPoint) {
        const snappedInitialPoint = creator.findClosestGridPoint(initialPoint);
        const path = new paper.Path({
            segments: [snappedInitialPoint],
            closed: false,
            fillColor: 'rgba(0, 0, 0, 0)', // Start with transparent fill
            strokeColor: '#000000',
            strokeWidth: 2
        });
        super(creator, path);
        this.creator = creator;
        this.isCompleted = false;
        this.anchorPoints = new paper.Group();
        this.isDrawing = true;
        this.updateAnchorPoints();
        this.updateVertexIndicators();
    }

    addPointToExisting(point) {
        const snappedPoint = this.creator.findClosestGridPoint(point);
        
        let minDistance = Infinity;
        let insertIndex = 0;
        
        for (let i = 0; i < this.shape.segments.length; i++) {
            const nextIndex = (i + 1) % this.shape.segments.length;
            const edgeStart = this.shape.segments[i].point;
            const edgeEnd = this.shape.segments[nextIndex].point;
            
            const distanceToEdge = this.distanceToLineSegment(snappedPoint, edgeStart, edgeEnd);
            if (distanceToEdge < minDistance) {
                minDistance = distanceToEdge;
                insertIndex = nextIndex;
            }
        }
        
        this.shape.insert(insertIndex, new paper.Segment(snappedPoint));
        this.updateShapePosition();
        this.updateAnchorPoints();
        this.updateVertexIndicators();
    }

    addPoint(point) {
        const snappedPoint = this.creator.findClosestGridPoint(point);
        
        if (this.isCompleted) {
            this.addPointToExisting(snappedPoint);
        } else {
            // Check if the new point is close to the first point
            if (this.shape.segments.length > 2 && 
                snappedPoint.getDistance(this.shape.segments[0].point) < this.creator.gridSize / 2) {
                this.completeShape();
            } else {
                this.shape.add(snappedPoint);
                this.updateAnchorPoints();
            }
        }
    }

    completeShape() {
        this.shape.closed = true;
        this.isDrawing = false;
        this.isCompleted = true;
        this.shape.fillColor = 'rgba(0, 0, 0, 1)';
        this.shape.strokeWidth = 0;
        this.updateAnchorPoints();
    }

    distanceToLineSegment(point, lineStart, lineEnd) {
        const line = lineEnd.subtract(lineStart);
        const t = point.subtract(lineStart).dot(line) / line.dot(line);
        if (t < 0) return point.getDistance(lineStart);
        if (t > 1) return point.getDistance(lineEnd);
        const projection = lineStart.add(line.multiply(t));
        return point.getDistance(projection);
    }

    snapVerticesToGrid() {
        this.shape.segments.forEach(segment => {
            segment.point = this.creator.findClosestGridPoint(segment.point);
        });
        this.updateShapePosition();
        this.updateAnchorPoints();
        this.updateVertexIndicators();
    }

    drag(delta) {
        this.shape.position = this.shape.position.add(delta);
        this.updateVertexIndicators();
    }

    dragVertex(index, delta) {
        if (index >= 0 && index < this.shape.segments.length) {
            this.shape.segments[index].point = this.shape.segments[index].point.add(delta);
            this.updateAnchorPoints();
            this.updateShapePosition();
            this.updateVertexIndicators();
        }
    }

    containsVertex(point, tolerance = 10) {
        return this.shape.segments.findIndex(segment => 
            segment.point.getDistance(point) < tolerance
        );
    }

    updateVertexIndicators() {
        if (this.vertexIndicators) {
            this.creator.removeAnchors(this.vertexIndicators.children);
            this.vertexIndicators.remove();
        }

        this.vertexIndicators = new paper.Group();
        
        if (this.isSelected) {
            this.shape.segments.forEach((segment, index) => {
                const indicator = new paper.Path.Circle({
                    center: segment.point,
                    radius: 3 / paper.view.zoom,
                    fillColor: index === this.selectedVertex ? 'red' : '#009dec',
                    strokeColor: null
                });
                this.vertexIndicators.addChild(indicator);
            });

            this.creator.addAnchors(this.vertexIndicators.children);
        }
    }

    updateShapePosition() {
        const bounds = this.shape.bounds;
        this.shape.position = new paper.Point(
            bounds.left + bounds.width / 2,
            bounds.top + bounds.height / 2
        );
    }

    select() {
        super.select();
        this.shape.strokeColor = '#0000FF';
        this.shape.strokeWidth = 3 / paper.view.zoom;
        this.updateAnchorPoints();
        this.updateVertexIndicators();
        this.vertexIndicators.bringToFront(); // Ensure indicators are on top after selection
    }

    deselect() {
        super.deselect();
        this.shape.strokeColor = '#000000';
        this.shape.strokeWidth = 0;
        this.selectedVertex = -1;
        this.anchorPoints.removeChildren();
        this.updateVertexIndicators();
    }

    containsPoint(point) {
        return this.shape.contains(point);
    }

    updateAnchorPoints() {
        this.anchorPoints.removeChildren();

        this.shape.segments.forEach((segment, index) => {
            const anchor = new paper.Path.Circle({
                center: segment.point,
                radius: 3 / paper.view.zoom,
                fillColor: '#009dec',
                strokeColor: null
            });
            this.anchorPoints.addChild(anchor);
        });

        this.creator.addAnchors(this.anchorPoints.children);
    }

    drag(delta) {
        this.shape.position = this.shape.position.add(delta);
        this.updateVertexIndicators();
        this.updateAnchorPoints();
    }

    snapToGrid() {
        this.updateAnchorPoints();
        this.snapVerticesToGrid();
    }

    resize(factor) {
        const scaleFactor = factor > 0 ? 1.1 : 0.9;
        this.currentScale *= scaleFactor;

        if (this.currentScale * this.initialSize < this.creator.gridSize) {
            this.currentScale = this.creator.gridSize / this.initialSize;
        }

        const center = this.shape.position;
        this.shape.scale(scaleFactor, center);
        this.shape.strokeWidth = this.initialStrokeWidth / this.currentScale;

        this.updateVertexIndicators();
    }

    selectVertex(index) {
        this.selectedVertex = index;
        this.updateVertexIndicators();
    }

    deselectVertex() {
        this.selectedVertex = -1;
        this.updateVertexIndicators();
    }

    deleteSelectedVertex() {
        if (this.selectedVertex !== -1 && this.shape.segments.length > 3) {
            this.shape.removeSegment(this.selectedVertex);
            this.selectedVertex = -1;
            this.updateShapePosition();
            this.updateVertexIndicators();
        }
    }

    remove() {
        this.creator.removeAnchors(this.anchorPoints.children);
        this.anchorPoints.remove();
        super.remove();
    }
}


// PATHFINDER

class PathfinderOperations {
    static subtract(shapes) {
        if (shapes.length < 2) {
            console.error("At least two shapes are required for subtraction");
            return null;
        }

        let result = shapes[0].clone();
        for (let i = 1; i < shapes.length; i++) {
            if (shapes[i]) {
                result = result.subtract(shapes[i]);
            } else {
                console.error(`Invalid shape at index ${i}`);
            }
        }

        if (!result || result.isEmpty()) {
            console.error("Subtraction resulted in an empty or invalid path");
            return null;
        }

        return result;
    }
    static unite(shapes) {
        if (shapes.length < 2) {
            console.error("At least two shapes are required for union");
            return null;
        }

        let result = shapes[0].clone();
        for (let i = 1; i < shapes.length; i++) {
            if (shapes[i]) {
                result = result.unite(shapes[i]);
            } else {
                console.error(`Invalid shape at index ${i}`);
            }
        }

        if (!result || result.isEmpty()) {
            console.error("Union resulted in an empty or invalid path");
            return null;
        }

        return result;
    }
}